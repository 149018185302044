import React from 'react';
import Thankyoupage from '../../../Images/Thank you.jpg';

function Thankyou(){
  return(
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        maxWidth: '100vw'
      }}>
        <img src={Thankyoupage} alt="Thank You Page" style={{maxWidth: '80vw'}} />
      </div>
  )
}

export default Thankyou;