import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Tooltip, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField,Avatar } from '@mui/material';
import { ArrowDropDown, DeleteOutlined, FilterAltRounded, PauseCircleOutlined, SearchOutlined, Verified } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Input } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar, SnackbarProvider } from 'notistack'


export default function DataTable() {
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    let navigate = useNavigate()
    const [newdata, setNewdata] = React.useState([]);
    const [deleteId, setDeleteId] = React.useState("");
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        GetCategoryList()
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const GetCategoryList = async() => {
        await axios.get(process.env.REACT_APP_BASE_URL + `admincategory/getsubcategorydetails?page=${page}&size=${rowsPerPage}`)
            .then((res) => {
                console.log(res.data,"subcategory get");
                setNewdata(res.data.subcatdetails);
                setTotal(res.data.totalcount)
            })
            .catch((err) => {
                console.log(err, "error in get service");
            })

    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(newdata);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;
        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }
        setNewdata(fromvar);
        var body = {
            index_position: currElIndexNumber
        }

        axios.put(process.env.REACT_APP_BASE_URL + `admincategory/updateposition/${final._id}`, body)
            .then((res) => {
                GetCategoryList()
            })
            .catch((err) => {
                console.log(err, "error in get users");
            })

    }


    const EditData = (row) => {
        navigate("/editsubcategory", { state: row })
    }
    const DeletData = (id) => {
        setDeleteId(id._id);
        setOpen(true)
    }


    const handleDelete = async() => {
        await axios.put(process.env.REACT_APP_BASE_URL + `admincategory/deletesubcat/${deleteId}`)
            .then((res) => {
                GetCategoryList()
                setOpen(false);
                enqueueSnackbar("Sub Category Deleted Successfully", { variant: 'success' })
            })
            .catch((err) => {
                setOpen(true);
                if (err.response.data.status == 4) {
                    enqueueSnackbar(err.response.data.message, { variant: 'error' })
                }


                console.log(err, "error in get users");
            });
    };

    return (

        <Box>
            <TableContainer component={Paper} id='table'>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
        <TableHead className='DT-head'>
          <TableRow className=''>
          <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Titel</TableCell>
            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Category</TableCell>
            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Description </TableCell>
            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px' }}>Action </TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <TableBody className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {newdata && newdata.length > 0 ? newdata.map((row, i) => (
                  <Draggable key={row._id} draggableId={row._id} index={i}>
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className=''
                      >
                      <TableCell className='DT-row' align="left" style={{ minWidth: 100 }}>{row.title}</TableCell>
                      <TableCell className='DT-row' align="left" style={{ minWidth: 30 }}> {row && row.category?.length ? row.category.map((data) => data.category_name).join(', ') : "-"}</TableCell >
                        <TableCell className='DT-row' align="left" style={{ minWidth: 100 }}>{row.description}</TableCell>
                        <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }}>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Edit">
                              <Box className='a_tag' onClick={() => EditData(row)}>
                                <ModeEditIcon className='icons text-grey' />
                              </Box>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <Box onClick={() => DeletData(row)}>
                                <DeleteIcon className='icons text-grey' />
                              </Box>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                )) : (
                  <TableRow className=''>
                    <TableCell className='DT-row' align="center" colSpan={5}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='DT-pagination'
            />
            <Dialog open={open} keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'>
                <DialogTitle>Would you like to delete Sub Category?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn' style={{ color: '#B4522D' }}>No</Button>
                    <Button onClick={handleDelete} className='theme-btn' style={{ color: '#FFFFFF', backgroundColor: '#B4522D' }}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
