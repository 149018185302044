import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { useSnackbar } from 'notistack';
import {
    Box,
    Button,
    Typography,
    Grid,
    Avatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    TextField,
    InputAdornment,
    FormControl,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControlLabel,
    Switch,
    Link,
    Badge,
} from '@mui/material';


import moment from "moment";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Rating from '@mui/material/Rating';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import MiniDrawer from '../../../components/drawer';
import CancelIcon from '@mui/icons-material/Cancel';
import { Margin } from '@mui/icons-material';


const localizer = momentLocalizer(moment);

const Users = () => {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [calenderData, setCalenderData] = React.useState([]);
    console.log(calenderData, "calenderdata");
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    console.log(selectedEvent, "selected events");
    const [users, setUsers] = React.useState([]);
    const [newdata, setNewdata] = React.useState([])
    const [total, setTotal] = React.useState(0);


    React.useEffect(() => {
        getBookings()
    }, [])

    const getBookings = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `paymentsdata/getthebookings`)
            .then((res) => {
                console.log(res.data, "response from booking");
                setCalenderData(res.data.data);
                setUsers(res.data.data);
                setTotal(res.data.totalcount);
            })
            .catch((error) => {
                console.log(error, "error in booking");
            })
    }
    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
    };
    const events = calenderData.map((caldata) => ({
        id: caldata._id,
        title: caldata.Bookingname,
        userName: caldata.serviceID ? caldata.serviceID.name : "-",
        start: new Date(caldata.start.dateTime),
        end: new Date(caldata.end.dateTime),
        email: caldata.email,
        Description: caldata.description,
        summary: caldata.summary,
        location: caldata.location,
    }));

const  Back =()=>{
    navigate('/booking')
}

    return (
        <Box className="companies">
            <MiniDrawer type={5} />
            <Box component="section" className="contentWraper">
            <Button variant="contained" className='addbtn'onClick={(e) => Back()}  style={{ marginBottom: '20px', backgroundColor:'black' }}> Back</Button>             
            
               <Grid container spacing={2}>
                    <Grid item xs={12} md={selectedEvent !== null ? 7 : 12}>
                        <div style={{ width: '100%' }}>
                            <Calendar
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                onSelectEvent={handleSelectEvent}
                                style={{ height: 500 }}
                                // style={{ height: 500, backgroundColor: '#fff', color: 'white' }}

                            />
                        </div>
                    </Grid>
                    {selectedEvent && (
                        <Grid item xs={12} md={5}>

                            <Card>
                                <IconButton onClick={() => setSelectedEvent(null)}>
                                    <CancelIcon />
                                </IconButton>
                                <CardContent>
                                    <Typography component='h4' variant='h4' className='page-heading' style={{ marginLeft: "130px" }}>
                                        Event Details
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Title:</strong> {selectedEvent.title}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Name:</strong> {selectedEvent.userName}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Start:</strong>{moment(selectedEvent.start).format('MMM D YYYY, h:mm a')}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>End:</strong>{moment(selectedEvent.end).format('MMM D YYYY,h:mm a')}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Description:</strong> {selectedEvent.Description}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Email:</strong> {selectedEvent.email}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Location:</strong> {selectedEvent.location}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>


            </Box>
        </Box>
    );
};

export default Users;
