import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'
import { useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
export default function AddCategory() {
    const { enqueueSnackbar } = useSnackbar()
    const headerformdata = {
		'Content-Type': 'multipart/form-data',
		
	}
    let location = useLocation()
    console.log(location.state,"location1111111111111",location);

	const [existingName , setExistingName]  = React.useState('')
    const [buttonview, setButtonview] = React.useState(false) 

    const navigate = useNavigate()
    const [ name ,setName]  = React.useState(location.state.name?location.state.name:'')
    const [lastname, setLastname] = React.useState(location.state.lastname?location.state.lastname:'')
    console.log(lastname,"lastname data in details")
    const [error, setError] = React.useState({ name: "", email: "", phone: "" })
    const [ email, setEmail] = React.useState(location.state.email?location.state.email:'')
    const [ phone, setPhone] = React.useState(location.state.phone?location.state.phone:"")
    const [profImg1, setProfImg1] = React.useState([...[], location.state.profile ? location.state.profile : []])
    const [ role, setRole] = React.useState( )
    const [country, setCountry] = React.useState('');
    console.log(role,"roleeeeee");
    const [profImg2, setProfImg2] = React.useState([...[], location.state.profile ? location.state.profile : []])

    const [ roleId, setRoleId] = React.useState(location.state.role_name._id?location.state.role_name._id:'')
    console.log(roleId,'roleIdroleId');
    let admindetails = JSON.parse(localStorage.getItem("userData"))
    
   

    // const handleChange = (value, country) => {
    //     setPhone(value);
    //     setCountry(country);
    //   };

    const [isValid, setIsValid] = React.useState(false);
    const [profImg3, setProfImg3] = React.useState([])
    const [profImg4, setProfImg4] = React.useState([])
    const [imageproError, setImageproError] = React.useState('')

  const handleChange = (value, country) => {
    if (value.length >= 10 && country.dialCode) {
      setIsValid(true);
      setPhone(`+${country.dialCode} ${value}`);
      setCountry(country);
    } else {
      setIsValid(false);
      setPhone('');
      setCountry('');
    }
  };
    
 // validation
 const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const Validation = () => {
        if (!name && !email &&!phone) {
            setError({ name: "This field is required.", email: "This field is required.", phone: "This field is required." })
            return false
        }
        if (!name.trim()) {
            setError({ name: "This field is required." })
            return false
        }
        if (!email) {
            setError({ email: "This field is required." })
            return false
        }
        if (email) {
            if (!emailRegex.test(String(email).toLowerCase())) {
                setError({ email: "Please enter valid email address" })
                return false
            }
        }
        if (!phone) {
            setError({ phone: "This field is required." })
            return false
        }
        if (profImg2?.length == 0) {
            setImageproError("This field is required.")
            return false
        }
        return true
    } 

    React.useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `roledata/getdropdownroles`)
            .then((res) => {
                console.log(res.data, "res.datares.data");
                setRole(res.data.dropdowndata)
                setTotal(res.data.total)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }

    const handleBack = () =>{
        navigate('/user')
    }

    const profileImageChange = (e, index) => {
        setImageproError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg1(prevValue => prevValue.concat(fileArray))
        setProfImg2(prevValue => prevValue.concat(filepath))
    }

    const DeleteProf = (e, index) => {
        var filteredpreview = profImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg2.filter((val, i) => {
            return i !== index;
        });
        setProfImg1(filteredpreview);
        setProfImg2(filteredraw);
    }
    const handleSubmit = async() => {
        let validate = Validation()  
        const selectedRole = role.find(data => data._id === roleId);      
        if(validate){
         setButtonview(true)
        const formData = new FormData();
        formData.append("name", name)
        formData.append('lastname',lastname)
        formData.append("isAdmin", true)
        formData.append("isStatus", true)
        formData.append("isService", false)
        formData.append("admin_id", admindetails._id)
        formData.append("role", 'User')
        formData.append("phone",phone)
        formData.append("role_name", roleId)
        formData.append("roleusers", true)
        { profImg4?.length > 0 ? profImg4.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
        await axios.put(process.env.REACT_APP_BASE_URL + `talents/updatetalentsandusers/${location.state._id}`, formData, { headers: headerformdata }).then((res) => {
			setButtonview(false)
			enqueueSnackbar("User updated Successfully", { variant: 'success', autoHideDuration: 2000 })
			navigate('/user');
        }).catch((error) => {
			setButtonview(false)
			 let errors =error.response.data.message
			 if(errors == "Service name already exist!") {
				setExistingName("Category name already exist!")
				enqueueSnackbar("Category name already exist!", { variant: 'error' })
			 }
			 else{
				console.log(error, "error");
				enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
			 }
        })
    }
}
    return (
        <Box className='Addcompany'>
            <MiniDrawer type={11}/>
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> First Name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='First Name'
                                                value={name}
                                                onChange={(e) => {setName(e.target.value);setExistingName('');setError('')}}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {error.name && <p className="error-message"  style={{color:"red"}}>{error.name}</p>} 
                                          {existingName && <p className="error-message"  style={{color:"red"}}>{existingName}</p>} 

										  
                                        </FormControl>
                                    </Grid>
                               
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> Last Name </Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Last Name'
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> Email <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Email'
                                                disabled
                                                value={email}
                                                onChange={(e) => {setEmail(e.target.value);setError('')}}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {error.email && <p className="error-message"  style={{color:"red"}}>{error.email}</p>} 

										  
                                        </FormControl>
                                    </Grid>
                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Phone{' '}
                                                <Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <PhoneInput
                                                international
                                                defaultCountry="RU"
                                                value={phone}
                                                onChange={handleChange}
                                                inputExtraProps={{
                                                    name: 'phone',
                                                }}
                                                inputStyle={{ width: '300px' }}
                                                country={'ae'}
                                                enableAreaCodes={true}
                                                enableSearch={true}
                                            />
                                            {error.phone && <p className="error-message" style={{ color: "red" }}>{error.phone}</p>}
                                        </FormControl>
                                    </Grid>
                                   
									<Typography component='div' className='label'>Select Role <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={roleId ? roleId : 'sel'}
											onChange={(e) => { setRoleId(e.target.value); }}>
											<MenuItem disabled value={'sel'} >Select Role</MenuItem>
											{role && role?.length > 0 ? role.map((data) => (
												<MenuItem value={data._id}>{data.role_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
										</Select>
									</FormControl>
								

                                    <Grid item xs={12} sm={12} md={12}  >

                                                <Typography component='div' className='label'>Profile Image<Typography component='span' className='star'> *</Typography></Typography>

                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {profImg1.length < 1 &&
                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChange} hidden type="file" />
                                                        </Button>
                                                    }
                                                </Box>
                                            </Grid>

                                            {imageproError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageproError}</p>}
                                            <Grid item xs={12} sm={12} md={12}  >
                                                {profImg1.length > 0 &&
                                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                        {profImg1.length > 0 ? profImg1.map((x, i) => (
                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                <Box className='uploaded_img' >
                                                                    <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>
                                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                                    <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>

                                                                </Box>
                                                            </Grid>
                                                        )) : ""}

                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                            </Grid>
                                   

                                </Grid>

								{buttonview == false ?
                                <Box className='heading-btns mt-20' style={{textAlign:'end'}}>
                           <Button variant="contained" className='addbtn mr-10' onClick={handleBack} style={{color:'white', backgroundColor:"black"}}>Back</Button>

                           <Button variant="contained" className='addbtn mr-10' onClick={handleSubmit} style={{color:'white', backgroundColor:"black"}}>Update</Button>
                                </Box>
								:
								<Box className='heading-btns mt-20' style={{textAlign:'end'}}>
								<Button variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
							</Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
