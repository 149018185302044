import React from 'react';
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
    const navigate = useNavigate();

    const handleReturn = () => {
        navigate('/');
    };

    return (
        <div style={styles.container}>
            <div style={styles.iconContainer}>
                <div style={styles.icon}>✔</div>
            </div>
            <h2 style={styles.title}>Password Set Successfully</h2>
            <p style={styles.message}>
                Your password has been set successfully. You can now return to the login page.
            </p>
            <Button onClick={handleReturn} className="cancel_btn" style={{ color: '#B4522D' }}>
                Return to Login
            </Button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '800px',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        textAlign: 'center',
        margin: 'auto',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        backgroundColor: '#ccffdd',
        marginBottom: '20px',
    },
    icon: {
        fontSize: '36px',
        color: '#28a745',
    },
    title: {
        margin: '0',
        fontSize: '24px',
        color: '#333',
        marginBottom: '10px',
    },
    message: {
        fontSize: '14px',
        color: '#555',
        lineHeight: '1.5',
    },
};

export default SuccessPage;
