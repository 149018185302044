import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './chat'
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
export default function Chat() {
    let navigate = useNavigate()


    //Test

    const handleMessage = () => {
    const messageRef = firebase.database().ref('messages').push();
    messageRef.set({
        

        // sender: '666817120af6a3b4018fc17d', //Ravi
        sender: '666816f90af6a3b4018fc178', //suman
        receiver: '66557c4988a2851a48645c15',
        message: 'Test Message',
        mediaType: '',
        media: '',
        enquiryId: '6671779c0b4f0160a4422b44',
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        status: 'sent',

   
        // readBy: ['666817120af6a3b4018fc17d']//Ravi
        readBy: ['666816f90af6a3b4018fc178'] //suman
    });
    //  setSendMessages('')

    const messageBody = {
        "to": "eXmB0WtMRGBybI3cBdOkTL:APA91bFaQ4rkSFIOk8Ufijd8GGpvewssAgdb6YiG1tva9qiPjK6DSWzthRWjmgibiQpsxdiWLlcmxou4a8NzFapXi1TdLmbuG9-Yk33zSI2V5IaSp0Nwut4kfvBtvpOHLyE4gy5ZsGtO",
        "notification": {
            "body": 'Hi',
            "title": `sent an message`,
            "notificationType": 'Message'
        },
    }
    fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
        },
        body: JSON.stringify(messageBody),
    }).then((res) => console.log(res, 'notisentresponse')).catch((err) => console.log(err, "notinotsentcatch"))
    console.log(`message sent successfully!`);
}

    return (
        <Box className='companies'>
            <MiniDrawer type={10}/>
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
                {/* <Button onClick={handleMessage}>Hi</Button> */}
            </Box>
        </Box>
    )
}