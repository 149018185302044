import { PlaylistAddRounded, SearchOutlined } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios'
import DOMPurify from 'dompurify';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar, SnackbarProvider } from 'notistack'
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Select from '@mui/material/Select';
import { Box, Button, Typography, ClickAwayListener, FormControl,Avatar, Grow, InputAdornment, MenuItem, Menu, MenuList, Popper, TextField, Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Checkbox from '@mui/material/Checkbox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
const localizer = momentLocalizer(moment);
import {useLocation} from 'react-router-dom';
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
export default function Studio() {
    let navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const locationdata = useLocation()
    const AddStudio = () => {
        navigate('/addstudio');
    }
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [serviceDetails, setServiceDetails] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("all");
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');

    const [anchorE2, setAnchorE2] = React.useState(null);
    const [allDetails, setAllDetails] = React.useState('');
    const [calenderOpen, setCalenderOpen] = React.useState(false);
    const [followersOpen, setFollowersOpen] = React.useState(false);
    const [isDashboard ,setIsDashboard] = React.useState(locationdata.state?locationdata.state:false)


    const open1 = Boolean(anchorE2);
    const OpenMenu = (event, data) => {
        setAllDetails(data)
        setAnchorE2(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorE2(null);
    };
    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const handleCancelClick = () => {
        setSearch('');
       
    };

    const handleView = (data) => {
  navigate('/viewstudio',{ state: allDetails })
        // setAnchorE2(null);
        // setAlldata(allDetails);
        // setOpenview(true)

    }

    const handleClickOpen = () => {
        setAnchorEl(null);
        setAnchorE2(null);
        setDeleteId(allDetails._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };
    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    const [calenderData, setCalenderData] = React.useState([]);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    React.useEffect(() => {
        getBookings()
    }, [])

    const getBookings = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `paymentsdata/getthebookings`)
            .then((res) => {
                console.log(res.data.data, 'calenderdataaaaa');
                setCalenderData(res.data.data)

            })
            .catch((error) => {
                console.log(error, "error");
            })
    }

    React.useEffect(() => {
        getServiceDetails() 
    }, [rowsPerPage, page, search, status])

    const getServiceDetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `adminstudio/getstudiosdata?page=${page}&size=${rowsPerPage}&search=${search}&isStatus=${status == 'all' ? '' : status}&Dashboard=${isDashboard}`)
            .then((res) => {
                console.log(res,"aaaaaaaaaaaaaaaaaaa");
                setServiceDetails(res.data.getdata)
                setTotal(res.data.total)
            })
            .catch((error) => {
                alert('error show')
                console.log("Inside the error")
                console.log(error, "error");
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(serviceDetails);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;
        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        setServiceDetails(fromvar);

        var body = {
            index_position: currElIndexNumber
        }
        axios.put(process.env.REACT_APP_BASE_URL + `adminstudio/updatestudioposition/${final._id}`, body)
            .then((res) => {
                console.log(res.data, "drag response");
                getServiceDetails();
            })
            .catch((err) => console.log(err.response, "drag & drop"));
    };

    const EditDetails = () => {
        navigate("/editstudio", { state: allDetails })
    }


    const DeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `adminstudio/deletestudio/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Studio deleted successfully", { variant: 'success' })
                getServiceDetails()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleChangeVisible = async (e, data) => {
        var body = {
            isStatus: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `adminstudio/updateboolean/${data._id}`, body)
            .then((res) => {
                console.log(res,"resssss");
                if (res.data.message =="Status Enabled") {
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                }
                else{
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                getServiceDetails();
            }).catch((err => {
                console.log("error while shop enable");
            }))
    }

    const handleRemove = () => {
        setStatus("all")
        setSearch('')
    }

    const handleClick = (event, data) => {
        alert("1111111111")
        console.log(event, "eventevent")
        // setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleChangeCheck = async (e, data) => {
        var body = {
            isService: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `adminstudio/updatestudiosetService/${data._id}`, body)
            .then((res) => {
                console.log(res, "resres");
                getServiceDetails();
            }).catch((err => {
                enqueueSnackbar(err.response.data.error, { variant: 'error' })
                console.log(err.response.data.error, "error while shop enable");
            }))
    }

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
    };
    const events = calenderData.map((caldata) => ({
        id: caldata._id,
        title: caldata.Bookingname,
        userName: caldata.serviceID ? caldata.serviceID.name : "-",
        start: new Date(caldata.start.dateTime),
        end: new Date(caldata.end.dateTime),
        email: caldata.email,
        Description: caldata.description,
        summary: caldata.summary,
        location: caldata.location,


    }));


    const viewBookings = (row) => {
        // setCalenderOpen(true)
        navigate('/viewbooking')
    }
    const closeCalender = () => {
        setCalenderOpen(false)
    }
    const viewPosts = () => {

    }
    const viewReviews = () => {
    }
    const viewFollowers = () => {
        setFollowersOpen(true)
    }
    const handleFollowersClose = () => {
        setFollowersOpen(false)
    }


    const sanitizedContent = DOMPurify.sanitize(alldata.description   , { ALLOWED_TAGS: ['img'] });
    console.log(alldata, "alldataalldata");
    return (
        <Box className='companies'>
            <MiniDrawer type={8} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4'className='page-heading'>Studios</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => AddStudio()} style={{ marginTop: '10px', backgroundColor:'black' }}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Studio</Button>
                </Box>
                <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10 }} alignItems='flex-end'>
    <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginBottom: 20 }}>
        <FormControl variant="outlined" fullWidth size="small">
            <TextField
                id="filled-size-small"
                variant="outlined"
                size="small"
                value={search}
                onChange={handleSearchChange}
                placeholder='Search Here...'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlinedIcon className='fz-18' />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {search && (
                                <IconButton onClick={handleCancelClick}>
                                    <CancelIcon className='fz-18' />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </FormControl>
    </Grid>

    <Grid item xs={6} sm={3} md={3} lg={3} style={{ marginBottom: '23px' }}>
        <FormControl variant="outlined" fullWidth size="small">
            <Typography component='div' className='label'><Box fontWeight='bold' display='inline'>Filter</Box></Typography>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                variant='outlined'
                className='select'
            >
                <MenuItem value={'all'} className='menuitems'><Box fontWeight='bold' display='inline'>All</Box></MenuItem>
                <MenuItem value={true} className='menuitems'><Box fontWeight='bold' display='inline'>Approved</Box></MenuItem>
                <MenuItem value={false} className='menuitems'><Box fontWeight='bold' display='inline'>Rejected</Box></MenuItem>
            </Select>
        </FormControl>
    </Grid>

    {status !== 'all' &&
        <Grid item xs={12} sm={3} md={2} lg={2} style={{ marginBottom: '23px' }}>
            <Button onClick={handleRemove} style={{ backgroundColor: 'black', color: 'white', marginTop: '10px' }}>Reset</Button>
        </Grid>
    }
</Grid>

                <Box className='whiteContainer'>
                    <Box>

                        <TableContainer component={Paper} id='table'>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                                <TableHead className='DT-head'>
                                    <TableRow className=''>

                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Image</TableCell>
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Studio name</TableCell>
                                        {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Category</TableCell> */}
                                        {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Status</TableCell> */}
                                        {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 10, maxWidth: 10, padding: '6px 16px', fontWeight:'bold' }}>Primary</TableCell> */}
                                        {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Followers</TableCell> */}
                                     
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Sections</TableCell>
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Bookings</TableCell>
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Action</TableCell>
                                    </TableRow>

                                </TableHead>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters">
                                        {(provided) => (

                                            <TableBody className="characters " ref={provided.innerRef}>
                                                {serviceDetails && serviceDetails?.length > 0 ? serviceDetails.map((row, i) => (

                                                    <Draggable key={row._id} draggableId={row._id} index={i} >
                                                        {(provided) => (
                                                            <>
                                                    <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className=''>
                                                     <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                                                    {row.profile?.length > 0 ? (
                                                        <img
                                                            src={process.env.REACT_APP_BASE_URL + `${row.profile}`}
                                                            height={50}
                                                            width={50}
                                                            style={{ borderRadius: '50%' }}
                                                           />
                                                    ) : (
                                                        <Avatar
                                                            sx={{ width: 32, height: 32, margin: 1 }}
                                                            alt={row.username ? row.username.charAt(0).toUpperCase() : 'Y'} // Alt attribute for accessibility
                                                        >
                                                            {row.username ? row.username.charAt(0).toUpperCase() : 'Y'}
                                                        </Avatar>
                                                    )}
                                                </TableCell>
                                                        
                                                                    <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>{row && row.name}</TableCell >
                                                                    {/* <TableCell className='DT-row' align="left" style={{ minWidth: 30 }}> {row && row.Category_id?.length ? row.Category_id.map((data) => data.category_name).join(', '):"-"}</TableCell > */}

                                                                    {/* <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                                                                        <FormControlLabel className='m-0' control={<IOSSwitch onChange={(e) => handleChangeVisible(e, row)} checked={row.isStatus} sx={{ m: 1 }} />} />
                                                                    </TableCell> */}

                                                                    {/* <TableCell align="left" component="th" scope="row" padding="none" className='DT-row'  >
                                                                        <Checkbox onChange={(e) => handleChangeCheck(e, row)} checked={row.isService} 
                                                                        style={{ color: 'black' }}/>
                                                                    </TableCell> */}

                                                                    {/* <TableCell className='DT-row' align="left" style={{ minWidth: 30, cursor: 'pointer' }} onClick={() => viewFollowers(row)}>{row && row.Followers ? row.Followers : "-"}</TableCell > */}
                                                                    
                                                                

                                                                     <TableCell className='DT-row' align="left" style={{ minWidth: 50, cursor: 'pointer' }} onClick={() => viewPosts(row)}>{row && row.Rooms ? row.Rooms.length : "-"} </TableCell >
                                                                    <TableCell className='DT-row' align="left" style={{ minWidth: 50 }} onClick={() => viewBookings(row)}>{calenderData.length}</TableCell >
                                                                    <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                                        <Button                                                         
                                                                            id="basic-button"
                                                                            aria-controls={open1 ? 'basic-menu' : undefined}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={open1 ? 'true' : undefined}
                                                                            onClick={(e) => OpenMenu(e, row)}
                                                                        >
                                                                            <MoreVertIcon sx={{ color: '#000' }} />
                                                                        </Button>
                                                                        <Menu id="basic-menu" anchorEl={anchorE2}
                                                                            open={open1} onClose={closeMenu}
                                                                            MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                                                                            <MenuItem onClick={() => EditDetails(row)}><ModeEditIcon className='icons text-grey' /> Edit</MenuItem>
                                                                            <MenuItem onClick={(e) => handleClickOpen(row)}><DeleteIcon className='icons text-grey' /> Delete</MenuItem>
                                                                            <MenuItem onClick={(e) => handleView(row)}><RemoveRedEyeIcon className='icons text-grey' /> View</MenuItem>
                                                                            {/* <MenuItem ><FiberManualRecordIcon className='icons text-grey' /> Status</MenuItem> */}


                                                                        </Menu>

                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                )) : <TableRow className=''>
                                                    <TableCell className='DT-row' align="center" colSpan={5}>
                                                        No Data Found
                                                    </TableCell >
                                                </TableRow>}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Table>
                        </TableContainer>

                        <TablePagination

                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className='DT-pagination'
                        />
                        <Dialog open={open} keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            className='custom_modal'>
                            <DialogTitle>Would you like to delete Studio?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleClose} className='cancel_btn' style={{ color: '#FFFFFF', backgroundColor: '#000' }}>No</Button>
                                <Button onClick={DeleteService} className='theme-btn'  style={{ color: '#FFFFFF', backgroundColor: '#000' }}>Yes</Button>
                            </DialogActions>
                        </Dialog>

                        <BootstrapDialog
                            onClose={handleClickClose}
                            aria-labelledby="customized-dialog-title"
                            open={openview}
                            className='custom_modal'
                            maxWidth='md'
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                Studio Details
                            </DialogTitle>

                            <DialogContent dividers>
                                <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>

                                    <Box className=''>
                                        <Typography component='span' variant='body1' className='fz-15 semibold'>Studio: </Typography>
                                        <Typography component='span' variant='body1' className='fz-15 '>{alldata && alldata.name}</Typography>

                                    </Box>
                                    
                                    <Box className=''>
                                        <Typography component='span' variant='body1' className='fz-15 semibold'>Category: </Typography>
                                        <Typography component='span' variant='body1' className='fz-15 '>{alldata && alldata.Category_id?.length && alldata.Category_id.map((data) => data.category_name).join(', ')}</Typography>
                                    </Box>
                                    <Box className=''>
                                        <Typography component='span' variant='body1' className='fz-15 semibold'>Location: </Typography>
                                        <Typography component='span' variant='body1' className='fz-15 '>{alldata.location && alldata.location ? alldata.location : '-'}</Typography>
                                    </Box>

                                    

                                </Grid>

                                <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="carosuel mb-10">

                                            <Typography component='span' variant='body1' className='fz-15 semibold'>Primary Image: </Typography>

                                            <Swiper
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper "
                                                slidesPerView={1}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >


                                                <SwiperSlide>
                                                    <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${alldata && alldata.profile}`} /></SwiperSlide>


                                            </Swiper>
                                        </div>

                                    </Grid>





                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="carosuel mb-10">

                                            <Typography component='span' variant='body1' className='fz-15 semibold'>Coverimage: </Typography>

                                            <Swiper
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper "
                                                slidesPerView={1}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >
                                                    <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${alldata && alldata.converimage}`} />

                                            </Swiper>
                                        </div>

                                    </Grid>


                                    {alldata && alldata.additionalimage && alldata.additionalimage?.length > 0 &&
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <div className="carosuel mb-10">

                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Additional Image: </Typography>

                                                <Swiper
                                                    modules={[Navigation, Pagination]}
                                                    className="mySwiper "
                                                    slidesPerView={1}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                >

                                                    {alldata && alldata.additionalimage && alldata.additionalimage?.length > 0 && alldata.additionalimage.map((tdata, i) => (
                                                        <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                                    )
                                                    )}
                                                </Swiper>
                                            </div>

                                        </Grid>
                                    }

                                    {alldata && alldata.additionalvideo && alldata.additionalvideo?.length > 0 &&
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <div className="carosuel mb-10">

                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Additional Videos: </Typography>

                                                <Swiper
                                                    modules={[Navigation, Pagination]}
                                                    className="mySwiper "
                                                    slidesPerView={1}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                >

                                                    {alldata && alldata.additionalvideo && alldata.additionalvideo?.length > 0 && alldata.additionalvideo.map((tdata, i) => (
                                                        <SwiperSlide> <video controls width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                                    )
                                                    )}
                                                </Swiper>
                                            </div>

                                        </Grid>
                                    }

                                </Grid>

                                <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                                {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                    : <Typography className='p18_tag' >There is no description</Typography>}

                            </DialogContent>
                            <DialogActions>
                                <Button className='cancel_btn'style={{ color: '#B4522D' }} onClick={handleClickClose}>Close</Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </Box>
                </Box>
            </Box>

         
            <div style={{ marginLeft: '400px', marginTop: '100px' }}>
                <Modal
                    isOpen={calenderOpen}
                    onRequestClose={closeCalender}
                    contentLabel="View Bookings Modal"
                >
                    <div style={{ display: 'grid', gridTemplateColumns: '70% 40%', gap: '20px', marginLeft: '200px', marginTop: '100px' }}>
                        <div className="calendar-container">
                            <div className="calendar">
                                <Button onClick={closeCalender} sx={{ '&:hover': { border: '1px solid white' } }}>
                                    <ArrowBackIcon size={40} />
                                </Button>
                                <Calendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    onSelectEvent={handleSelectEvent}
                                    style={{ height: 500 }}
                                />
                            </div>
                        </div>
                        {selectedEvent && (
                            <div className="event-details">
                                <h2>Event Details</h2>
                                <p><strong>Title:</strong> {selectedEvent.title}</p>
                                <p><strong>Name:</strong> {selectedEvent.userName}</p>
                                <p><strong>Start:</strong> {selectedEvent.start.toString()}</p>
                                <p><strong>End:</strong> {selectedEvent.end.toString()}</p>
                                <p><strong>Description:</strong> {selectedEvent.Description}</p>
                                <p><strong>Email:</strong> {selectedEvent.email}</p>
                                <p><strong>Location:</strong> {selectedEvent.location}</p>
                            </div>
                        )}
                    </div>

                    <button onClick={closeCalender}>Close</button>
                </Modal>
            </div>

            {/* -------------------------------------------------------------------------------------------- */}
            <Dialog open={followersOpen} keepMounted
                onClose={handleFollowersClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'>
                <DialogTitle>Followers Detailes</DialogTitle>
                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">
                        {alldata && alldata.following?.length > 0 && alldata.following.map((data) => (
                            <Card sx={{ minWidth: 275, margin: 2, border: '1px solid #ccc', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom> </Typography>
                                    <Box className=''>
                                        <Avatar src={process.env.REACT_APP_BASE_URL + `${data.profile}`} sx={{ width: 80, height: 80, marginRight: 1 }} />
                                        <Typography component='span' variant='body1' className='fz-15 '>{data.username && data.username}</Typography>
                                    </Box>
                                    <Box className='' sx={{ marginLeft: 'auto' }}>
                                        <Typography component='span' variant='body1' className='fz-15 '>{data.date && data.date}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn'style={{ color: '#B4522D' }} onClick={handleFollowersClose}>Close</Button>
                </DialogActions>

            </Dialog>


        </Box>
    )
}