import { Person } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import { useSnackbar, SnackbarProvider } from 'notistack'

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  let navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [buttonview, setButtonview] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar()

  const validation = () => {
    if (!email) {
      setError("Email field is required");
      return false;
    } else {
      return true;
    }
  };

  const ResetPassword = async () => {
    let valid = validation();
    if (valid == true) {
      setButtonview(true);
      let body = { email: email };
      await axios
        .post(process.env.REACT_APP_BASE_URL + `adminpart/forgotpassword`, body)
        .then((res) => {
          setButtonview(false);
          // Reset Password email has been sent to your email
          navigate("/");
          enqueueSnackbar("Reset Password email has been sent to your email", { variant: 'success', autoHideDuration: 5000 })
        })
        .catch((err) => {
          setButtonview(false);

          let errors = err.response.data.message;
          setError(errors);
          //  let errors =err.response.data.message
          //  if (errors =="Email doesn't exist!") {
          //     setError(errors)
          //  }
          //  else{
          //     console.log(err);
          //  }
        });
    }
  };

  return (
    <Box className="login">
      <Grid container>

        {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: 'linear-gradient(to right, #366253, #B4522D, #8E211D, #131313)' }}> */}
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: '#000'}}>
          <Box className='login_img'>
            <Box className='logintext_box'>
              <h1 className='heading'>Empower Your Cultural Network!</h1>
              <Typography component='p' variant='body1' className='subheading'>
                Manage talents, models, and studios seamlessly. Your hub for streamlined operations and creative control!
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className="Login_content">
            <Typography component="h4" variant="h4" className="mb-10">
              Forgot Password
            </Typography>
            <Typography component="p" variant="p" className="mb-30 fz-14">
              Enter your email to reset the password.
            </Typography>
            <Box className="form">
              <FormControl variant="standard">
                <Typography component="div" className="label">
                  Email <Typography component='span' className='star'>*</Typography>
                </Typography>
                <TextField
                  id="filled-size-small"
                  variant="outlined"
                  size="small"
                  className="textfield"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person className="start_icon" />
                      </InputAdornment>
                    ),
                  }}
                />
                {error && (
                  <p className="error-message" style={{ color: "red" }}>
                    {error}
                  </p>
                )}
              </FormControl>
            </Box>
            {buttonview == false ? (
              <Button className="loginbtn" onClick={ResetPassword}>
                Continue
              </Button>
            ) : (
              <Button className="loginbtn">Please Wait...</Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
