import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, Avatar, InputAdornment, TextField, Typography, Input, OutlinedInput, Checkbox, ListItemText, IconButton } from '@mui/material'
import { Card, CardContent } from "@mui/material";
import { PlaylistAddRounded, SearchOutlined } from '@mui/icons-material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import shadows from '@mui/material/styles/shadows';
import axios from 'axios';



export default function Payment() {
    const [search, setSearch] = React.useState("");
    const [history, setHistory] = React.useState(false)
    const [PaymentDetailes, setPaymentDetailes] = React.useState(false)
    const [users, setUsers] = React.useState("");
    const [price, setPrice] = React.useState("");
    const [name, setName] = React.useState("");
    const [saddres, setAddres] = React.useState("");
    const [card, setCard] = React.useState("");
    const [alldata, setAlldata] = React.useState('')

    const headerformdata = {
        'Content-Type': 'multipart/form-data',
    }

    console.log(users, "usrss");


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    const handleCancelClick = () => {
        setSearch('');
    };

    const handleBack = () => {
        setPaymentDetailes(false)
    }
    const handleSubmit = async () => {
        const body = {
            serviceProviderID: "",
            ServieId: '',
            admin_id: '',
            success_url: '',
            cancel_url: "",
            pricing: ""
        }
        await axios.post(process.env.REACT_APP_BASE_URL + `paymentsdata/servicecomplete`, body, { headers: headerformdata })
            .then((res) => {

            })
            .catch((error) => {

            })
    }
    const pamentMethod = () => {
        setPaymentDetailes(true)

    }
    React.useEffect(() => {
        getUsers()
    }, [])
    const getUsers = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `talents/getalltalents`)
            .then((res) => {
                setUsers(res.data.combinedData)
            })
            .catch((error) => {

            })
    }
    const handlePaymentHistory = (data) => {
        setAlldata(data)
        setHistory(true)
    }
    console.log(alldata, "alldata");
    return (
        <Box className='Addcompany'>
            {/* Assuming MiniDrawer is a component for navigation or side menu */}
            <MiniDrawer type={9} submenuOrder={1} />

            <Box component="section" className="contentWraper">
                <Box className='whiteContainer'>
                    <Box className='content p-20'>
                        <Grid container spacing={1}>
                            {/* Left Box */}
                            <Grid item xs={12} sm={4} sx={{height: "80vh",overflow: "auto",}}>
                                <FormControl variant="outlined"  size="small" style={{ marginBottom: 30, marginRight:5}}>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        value={search}
                                        onChange={handleSearchChange}
                                        placeholder="Search Here..."
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchOutlinedIcon className="fz-18" />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {search && (
                                                        <IconButton onClick={handleCancelClick}>
                                                            <CancelIcon className="fz-18" />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>

                                {users.length > 0 && users.map((user, index) => (
                                    <Card key={index} style={{ marginBottom: 8, marginRight: 5, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', cursor: 'pointer' }} onClick={() => handlePaymentHistory(user)}>
                                        <CardContent>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Avatar sx={{ width: 40, height: 40, marginRight: 5 }}>
                                                    {'A'}
                                                </Avatar>
                                                <Typography component="div" variant="body1" className="fz-15 semibold" style={{ marginLeft: 8 }}>
                                                    {user.name}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>

                            {/* Right Box */}
                            {history && !PaymentDetailes ? (
                                <Grid item xs={12} sm={8}>
                                    <Box className='headingBox'>

                                        <Button variant="contained" className='addbtn' style={{ marginTop: '10px', float: 'right', backgroundColor:'black' }} onClick={pamentMethod}>
                                            <PlaylistAddRounded className='fz-18 mr-5' /> Make Payment
                                        </Button>
                                    </Box>
                                    <Typography component='h4' variant='h4' align='center' className='page-heading'  >Payment History</Typography>
                                    <Typography  >{alldata.name} </Typography>
                                    <Typography component='h4' variant='h4' className='page-heading' align='center' style={{ marginTop: '15px', float: 'right', textAlign: 'center' }}></Typography>
                                    <Card style={{ marginBottom: 8, boxShadow: '0px 4px 20px rgba(1, 0, 0, .7)' }}>
                                        <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography component="div" variant="body1" className="fz-15 semibold">AED 1800</Typography>
                                            <Typography component="div" variant="body1" className="fz-15">Service name</Typography>
                                            <Typography component="div" variant="body1" className="fz-15">12-1-2024 12:00 pm</Typography>
                                        </CardContent>
                                    </Card>
                                    <Card style={{ marginBottom: 8, boxShadow: '0px 4px 20px rgba(1, 0, 0, .7)' }}>                                        <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography component="div" variant="body1" className="fz-15 semibold">AED 1800</Typography>
                                        <Typography component="div" variant="body1" className="fz-15">Service name</Typography>
                                        <Typography component="div" variant="body1" className="fz-15">12-1-2024 12:00 pm</Typography>
                                    </CardContent>
                                    </Card>
                                    <Card style={{ marginBottom: 8, boxShadow: '0px 4px 20px rgba(1, 0, 0, .7)' }}>    <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography component="div" variant="body1" className="fz-15 semibold">AED 1800</Typography>
                                        <Typography component="div" variant="body1" className="fz-15">Service name</Typography>
                                        <Typography component="div" variant="body1" className="fz-15">12-1-2024 12:00 pm</Typography>
                                    </CardContent>
                                    </Card>
                                    <Card style={{ marginBottom: 8, boxShadow: '0px 4px 20px rgba(1, 0, 0, .7)' }}>                                        <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography component="div" variant="body1" className="fz-15 semibold">AED 1800</Typography>
                                        <Typography component="div" variant="body1" className="fz-15">Service name</Typography>
                                        <Typography component="div" variant="body1" className="fz-15">12-1-2024 12:00 pm</Typography>
                                    </CardContent>
                                    </Card>
                                </Grid>
                            ) : (
                                history && PaymentDetailes && (
                                    <Grid item xs={12} sm={8}>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Amount</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        placeholder='Enter amount'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Receiver Name</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        placeholder='Name'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Address</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={saddres}
                                                        onChange={(e) => setAddres(e.target.value)}
                                                        placeholder='Address'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Card No</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={card}
                                                        onChange={(e) => setCard(e.target.value)}
                                                        placeholder='Card number'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    className='addbtn'
                                                    style={{ marginTop: '15px', float: 'right', color:'white', backgroundColor:'black' }}
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className='addbtn'
                                                    style={{ marginTop: '15px', float: 'right', marginRight: "30px", color:'white', backgroundColor:'black' }}
                                                    onClick={handleBack}
                                                >
                                                    Back
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}