// firebase.js
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCZZtiypfAxh2fXDosLXK9Z92a_EJujX4s",
    authDomain: "chatapp-rn-79e4e.firebaseapp.com",
    databaseURL: "https://chatapp-rn-79e4e-default-rtdb.firebaseio.com",
    projectId: "chatapp-rn-79e4e",
    storageBucket: "chatapp-rn-79e4e.appspot.com",
    messagingSenderId: "512330630070",
    appId: "1:512330630070:web:7e12f53ca543f2142a7717",
    measurementId: "G-VHLEHZY7BJ"
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      const token = await getToken(messaging);
      console.log(token,'FCM Token');
    } else {
      console.log('Notification permission denied.');
    }
    onMessage(messaging, (payload) => {
      console.log(payload,"Foreground Message");
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
export { messaging, setupNotifications };
