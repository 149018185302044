import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Button from "@mui/material/Button";
import { Box, Typography, InputAdornment, OutlinedInput, FormControl, Input } from '@mui/material';
import { Send, SettingsInputComponentTwoTone } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from 'moment';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VideocamIcon from '@mui/icons-material/Videocam';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getMessaging, getToken } from 'firebase/messaging';
import MiniDrawer from '../../../components/drawer'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// const firebaseConfig = {
//     apiKey: "AIzaSyCZZtiypfAxh2fXDosLXK9Z92a_EJujX4s",
//     authDomain: "chatapp-rn-79e4e.firebaseapp.com",
//     databaseURL: "https://chatapp-rn-79e4e-default-rtdb.firebaseio.com",
//     projectId: "chatapp-rn-79e4e",
//     storageBucket: "chatapp-rn-79e4e.appspot.com",
//     messagingSenderId: "512330630070",
//     appId: "1:512330630070:web:7e12f53ca543f2142a7717",
//     measurementId: "G-VHLEHZY7BJ"
// };

// const firebaseConfig = {
//     apiKey: "AIzaSyCuefzW-LvKJeVCtK6HhO0LxGuwGtlnQFU",
//     authDomain: "talentuser-350a2.firebaseapp.com",
//     databaseURL: "https://talentuser-350a2-default-rtdb.firebaseio.com",
//     projectId: "talentuser-350a2",
//     storageBucket: "talentuser-350a2.appspot.com",
//     messagingSenderId: "656012907979",
//     appId: "1:656012907979:web:9bd86de29a5e9d1f8c924e",
//     measurementId: "G-33RCM6CY58"
//   };


// firebase.initializeApp(firebaseConfig);

const Page2 = () => {
    let location = useLocation()
    let admindetail = JSON.parse(localStorage.getItem("userData"))
    const [userId, setUserId] = React.useState(location.state._id)
    const [sendmessages, setSendMessages] = React.useState('')
    const [admindetails, setAdminDetails] = React.useState('')
    const [receiverDetails, setReceiverDetails] = React.useState('')
    const [adminMessage, setAdminMessage] = React.useState([])
    const [mediaType, setMediaType] = React.useState('')
    const [allmessages, setAllmessages] = useState([]);
    const [alldetails, setAll] = useState([]);
    const [status, setStatus] = useState('');
    const inputRef = React.useRef(null);
    const [open, setOpen] = useState(false);
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const scrollRef = React.useRef();
    const storage = getStorage()
    const messaging = getMessaging();
    const [deletedata, setData] = useState('');
    
    React.useMemo(() => {
        if (location.state.role == 'Talent') {
            let test = alldetails.filter((data) => data.talent_id == userId)
            setReceiverDetails(test[0])
        }
        else if (location.state.role == 'User') {
            let test = alldetails.filter((data) => data.user_id == userId)
            setReceiverDetails(test[0])
        }
    }, [alldetails])

    React.useMemo(() => {
        let abc = alldetails.filter((data) => data.user_id == userId)
        if (abc?.length > 0) {
            setStatus(abc[0])
        }
    }, [alldetails])

    const handleClose = () => {
        setOpen(false)
    }    

    React.useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [adminMessage]);

    React.useEffect(() => {
        getToken(messaging, { vapidKey: "BCL7SMn0_Qyg6KE_HDwWMEJd1nUE3bvbOpGq4eB_EsH0H50Q2IduHfOhFyggVp4TFBgwNtvRnWonu7IEk9EM8dA" })
            .then((currentToken) => {
                if (currentToken) {
                    console.log('FCM Token:', currentToken);
                } else {
                    console.log('No FCM token available.');
                }
            }).catch((error) => {
                console.error(error, 'Error getting FCM token:');
            });
    }, [])

    const multipleImgChange = (e, index) => {
        setMediaType('image')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = state1.filter(x => x != null)
        var data1 = state2.filter(x => x != null)
        setState1(data)
        setState2(data1)
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const handleVideoUpload = (e, index) => {
        setMediaType('video')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = state1.filter(x => x != null)
        var data1 = state2.filter(x => x != null)
        setState1(data)
        setState2(data1)
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }

    React.useMemo(() => {
        if (allmessages?.length > 0) {
            let adminmessages = allmessages.filter(message => message.sender === admindetails[0].admin_id || message.receiver === admindetails[0].admin_id);
            let test = adminmessages.filter((data) => data.sender === userId || data.receiver === userId)
            setAdminMessage(test)
        }
    }, [admindetails])

    useEffect(() => {
        const fetchData = async () => {
            const dbRef = firebase.database().ref();
            dbRef.on('value', snapshot => {
                let admindetails = snapshot.val().Users
                let admin = Object.values(admindetails);
                setAll(admin)
                let test = admin.filter((data) => data.role === 'Admin')
                setAdminDetails(test)
            });
        };

        const FetchMessage = async () => {
            const FindId = firebase.database().ref('messages');
            FindId.on('value', (snapshot) => {
                const messages = [];
                snapshot.forEach((childSnapshot) => {
                    const Id = childSnapshot.key;
                    const message = { ...childSnapshot.val(), id: Id };
                    messages.push(message);
                });
                setAllmessages(messages);
            });
        }
        fetchData();
        FetchMessage();
        return () => {
            firebase.database().ref().off();
        };
    }, []);

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage(e)
        }
    }

    const uploadImage = async (localImageUri) => {
        console.log(localImageUri, 'localImageUrilocalImageUrilocalImageUrilocalImageUri')
        return new Promise(async (resolve, reject) => {
            const storageRef = ref(storage, localImageUri.type.includes('image') ? `/images/${localImageUri.name}` : `/videos/${localImageUri.name}`);
            try {
                await uploadBytes(storageRef, localImageUri);
                console.log('File uploaded successfully!');
                const downloadURL = await getDownloadURL(storageRef);
                resolve(downloadURL);
            } catch (error) {
                console.error('Error uploading file:', error);
                reject(error);
            }
        });
    };

    const uploadVideo = async (localVideoUri) => {
        return new Promise(async (resolve, reject) => {
            const storageRef = ref(storage, `/video/${localVideoUri.name}`);
            try {
                await uploadBytes(storageRef, localVideoUri);
                console.log('File uploaded successfully!');
                const downloadURL = await getDownloadURL(storageRef);
                resolve(downloadURL);
            } catch (error) {
                console.error('Error uploading file:', error);
                reject(error);
            }
        });
    }

    const handleSendMessage = async () => {
        var multipleImage = state2.filter(x => x != null);
        if (multipleImage && multipleImage.length > 0) {
            multipleImage.forEach(async (mediaUri) => {
                const messageType = mediaUri.type;
                const mediaMessageRef = firebase.database().ref('messages').push();
                const imageUrl = await uploadImage(mediaUri)
                mediaMessageRef.set({
                    sender: admindetails[0].admin_id,
                    receiver: userId,
                    mediaType: messageType,
                    media: imageUrl,
                    message: '',
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                    status: 'sent',
                });
                setState1([])
                setState2([])
                const messageBody = {
                    "to": receiverDetails.fcm,
                    "notification": {
                        "body": '',
                        "title": `${location.state.username} sent an image`,
                        "notificationType": 'Message'
                    },
                }
                fetch('https://fcm.googleapis.com/fcm/send', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
                    },
                    body: JSON.stringify(messageBody),
                }).then((res) => console.log(res, 'notisentresponse')).catch((err) => console.log(err, "notinotsentcatch"))
                console.log(`${messageType} message sent successfully!`);
            })
        }

        if (sendmessages != '') {
            const messageRef = firebase.database().ref('messages').push();
            messageRef.set({
                sender: admindetails[0].admin_id,
                receiver: userId,
                message: `${sendmessages}`,
                mediaType: '',
                media: '',
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                status: 'sent',
                readBy: [userId]
            });
            setSendMessages('')
            const messageBody = {
                "to": receiverDetails.fcm,
                "notification": {
                    "body": `${sendmessages}`,
                    "title": `${location.state.username} sent an message`,
                    "notificationType": 'Message'
                },
            }
            fetch('https://fcm.googleapis.com/fcm/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
                },
                body: JSON.stringify(messageBody),
            }).then((res) => console.log(res, 'notisentresponse')).catch((err) => console.log(err, "notinotsentcatch"))
            console.log(`message sent successfully!`);
        }
    }

    const handleAutocorrect = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(
                    'https://api.openai.com/v1/chat/completions',
                    {
                        model: 'gpt-3.5-turbo',
                        messages: [
                            { role: 'system', content: 'You are a helpful assistant.' },
                            { role: 'user', content: `Correct the following text: ${sendmessages}` },
                        ],
                        max_tokens: 50,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer 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`
                        },
                    }
                );
                const suggestion = response.data.choices[0].message.content.trim();
                const match = suggestion.match(/(?:[^"]*: )?"([^"]+)"/);
                const cleanedText = match ? match[1] : suggestion;
                const isCorrected = cleanedText.toLowerCase() !== suggestion.toLowerCase();
                resolve(cleanedText)

            } catch (error) {
                console.error('Error performing sentiment analysis:', error);
                reject(error)
            }
        })
    };

    function convertDateFormat(originalDate) {
        const parts = originalDate.split(", ");
        const [datePart, timePart] = parts[0].split("/");
        const [month, day, year] = [datePart, timePart, parts[1]].map(part => parseInt(part));
        const [time, period] = parts[1].split(" ");
        return `${day}/${month}/${year}, ${time} ${period}`;
    }

    const RemoveMessageNew = (data) => {
        const messageRef = firebase.database().ref(`/messages/${data.id}`);
        messageRef.remove()
        handleClose()
    }

    const RemoveMessage = (data) => {
        setData(data)
        setOpen(true)
    }

    return (
        <React.Fragment>
            <Box>
                <Box className="background-data">
                    <Typography variant="h4" component="h4" sx={{ textAlign: 'center', marginTop: '10px' }} >
                        Chat Message
                    </Typography>

                    <Box className="chat-container" ref={scrollRef}>
                        {adminMessage && adminMessage.length > 0 ? adminMessage.map((message, index) => {
                            let date = new Date(message.timestamp).toLocaleString();
                            let formattedDateTime = date.toLocaleString();
                            let format = convertDateFormat(formattedDateTime);
                            return (
                                <Grid container key={index}>
                                    <Grid item xs={12}>

                                        {message && message.receiver == admindetails[0].admin_id ?
                                            <Card sx={{ width: "48%", margin: 1, background: "#fff", borderRadius: '30px' }}>
                                                <Typography sx={{ zIndex: 15, margin: 2 }}>
                                                    {location.state.username}
                                                </Typography>
                                                <CardContent>
                                                    <Typography sx={{ zIndex: 15, margin: -1 }} >
                                                        {message && message.message ? message.message : message.media ? <img src={message.media} style={{ width: '200px', height: 'auto' }} /> : ""}
                                                    </Typography>
                                                    <Typography variant="h6" component="h6" sx={{ zIndex: 15, textAlign: 'right' }} >
                                                        {format}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                            :
                                            <Card sm container sx={{ width: "48%", margin: 1, float: "right", justifyContent: "space-around", backgroundColor: "#c1c7e0", borderRadius: '30px' }}>
                                                <div className="icon-wrapper">
                                                    <Button onClick={() => RemoveMessage(message)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </div>
                                                <Typography sx={{ zIndex: 15, margin: 2 }} >
                                                    {message && message.sender == admindetails[0].admin_id ? "Admin" : ""}
                                                </Typography>
                                                <CardContent>
                                                    <Typography sx={{ zIndex: 15, margin: -0.5 }} >
                                                        {message && message.message ? message.message : message.media && message.mediaType.includes('image') ? <img src={message.media} style={{ width: '200px', height: 'auto' }} /> : message.mediaType.includes('video') ? <video src={message.media} controls /> : ""}
                                                    </Typography>
                                                    <Typography sx={{ zIndex: 15, margin: -0.5, textAlign: 'right' }} >
                                                        {format}
                                                    </Typography>
                                                </CardContent>
                                            </Card>}
                                    </Grid>
                                </Grid>
                            )
                        }) : ""}
                    </Box>
                </Box>

                {state1.length > 0 ? state1.map((x, i) => (
                    <Grid item xs={6} sm={4} md={4} lg={4} key={i}>
                        <Box className='uploaded_img' >
                            <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                            {state2[i].type.includes('image') ? <img src={x} alt='' height={155} width={195} /> : <video src={x} controls alt='' hight={160} width={200} ></video>}
                        </Box>
                    </Grid>
                )) : ""}

                <Box className="form-box mb-10 mt-20 alt-input">
                    <FormControl style={{ width: "95%", marginLeft: 15, marginBottom: 20 }}>
                        <OutlinedInput
                            ref={inputRef}
                            style={{ paddingRight: 0 }}
                            id="standard-adornment-password"
                            placeholder='Message'
                            value={sendmessages}
                            onKeyPress={_handleKeyDown}
                            onChange={(e) => setSendMessages(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end" className='end-adornments adornments'>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <label htmlFor="contained-button-file1" className="w-full" style={{ marginLeft: 10 }}>
                                            <Box className='multiple_upload_btn18' >
                                                <Input
                                                    inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                    onChange={multipleImgChange}
                                                    style={{ display: "none", }}
                                                    id="contained-button-file1"
                                                    type="file"
                                                />
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><AttachFileIcon /></Box>
                                            </Box>
                                        </label>

                                        <label htmlFor="contained-button-file2" className="w-full" style={{ marginLeft: 10 }}>
                                            <Box className='multiple_upload_btn18' >
                                                <Input
                                                    inputProps={{ accept: "video/mp4" }}
                                                    onChange={handleVideoUpload}
                                                    style={{ display: "none", }}
                                                    id="contained-button-file2"
                                                    type="file"
                                                />
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon /></Box>
                                            </Box>
                                        </label>
                                        <Button onClick={handleSendMessage} className="msgSendBtn"><Send /></Button>
                                    </Box>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
            </Box>
            <Dialog open={open} keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'>
                <DialogTitle>Would you like to delete Message?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn' style={{ color: '#B4522D' }}>No</Button>
                    <Button onClick={() => RemoveMessageNew(deletedata)} className='theme-btn'  style={{ color: '#FFFFFF', backgroundColor: '#B4522D' }}>Yes</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default Page2;