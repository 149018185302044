import { IconButton } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import React from 'react';

export default function SnackbarCloseButton({ value }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(value)} variant="soft" sx={{background:"#fff",}} size='small' >
      <IconClose fontSize='5px' />
    </IconButton>
  );
}