import React from 'react'
import { Box, Button, FormControl, Grid, InputAdornment, FormControlLabel, Radio, RadioGroup, TextField, Typography, Input, Tabs, Tab } from '@mui/material'
import MiniDrawer from '../../../components/drawer'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Category, SearchOutlined } from '@mui/icons-material';
import { Card, CardContent } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'
import VideocamIcon from '@mui/icons-material/Videocam';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import dayjs from 'dayjs';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { OutlinedInput } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ListItemText } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CompareIcon from '@mui/icons-material/Compare';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MmsIcon from '@mui/icons-material/Mms';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// for service page 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { usePlacesWidget } from "react-google-autocomplete";
import AutoComplete from './autocomplete';

// -----------------------------------------------------------------------------------------------------------


const stripePromise = loadStripe('pk_test_51PJUGISAkKmdrZwY1ONClLOMNxxCARBSJYKc1w9LtnFQZrIHXVE03L95vq1TxqCej8R1aAcAX0nxow1Of6MJvyxb00dCkDGQ5U');

const Addstudio = () => {

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#3554D1',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#3554D1',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;
        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#3554D1',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor:
                    '#3554D1',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#3554D1',
            // backgroundColor:
            //     theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor:
                '#545454',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor:
                '#3554D1',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <DashboardIcon />,
            2: <MmsIcon />,
            3: <Dvr />,
        };
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };


    const steps = ['Basic Information', 'Additional Information'];
    let admindetails = JSON.parse(localStorage.getItem("userData"))
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [profImg1, setProfImg1] = React.useState([])
    const [profImg2, setProfImg2] = React.useState([])
    const [buttonview, setButtonview] = React.useState(false)
    const [buttonview12, setButtonview12] = React.useState(false)
    const [buttonview1, setButtonview1] = React.useState(true)
    const [personName, setPersonName] = React.useState([])
    const [activeStep, setActiveStep] = React.useState(0);
    const [categoryDetails, setCategoryDetails] = React.useState([])
    const [studioname, setStudioname] = React.useState('')
    // const [username, setUserName] = React.useState('')
    const [email, setEmail] = React.useState('')
    // const [price, setPrice] = React.useState('')
    const [category, setCategory] = React.useState('')
    // const [duration, setDuration] = React.useState('perservice')
    const [location, setLocation] = React.useState('')
    const [diameter, setDiameter] = React.useState('')
    const [studioprice, setStudioPrice] = React.useState('')
    const [selectedOption, setSelectedOption] = React.useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [error, setError] = React.useState({ name: "", service: "", email: "", Category: "" })
    const [viewpage, setViewpage] = React.useState(1)

    const [video1, setVideo1] = React.useState([])
    const [video2, setVideo2] = React.useState([])
    const [addImg1, setAddImg1] = React.useState([])
    const [addImg2, setAddImg2] = React.useState([])
    const [richeditor, setRichtor] = React.useState("")
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [editorState1, setEditorState1] = React.useState(EditorState.createEmpty());
    const [images, setImages] = React.useState({})
    const [videos, setVideos] = React.useState({})
    console.log(images, "imagesdata");
    console.log(videos, "imagesdata");
    const dataofeditor = `${richeditor}`
    const [aditnalValues, setaditnalValues] = React.useState(1)
    const [servicename, setServiceName] = React.useState('')
    const [duration, setDuration] = React.useState('perservice')
    const [profImg3, setProfImg3] = React.useState([])
    const [profImg4, setProfImg4] = React.useState([])
    const [personNameService, setPersonNameService] = React.useState([])
    const [price, setPrice] = React.useState('')
    const [price1, setPrice1] = React.useState(0)
    console.log(price1,'price1price1price1')
    const [state3, setState3] = React.useState([])
    const [state4, setState4] = React.useState([])
    const [city, setCity] = React.useState('')
    const [date, setDate] = React.useState(dayjs(new Date()));
    const [selectedFromDateTime, setSelectedFromDateTime] = React.useState(dayjs(new Date()));
    const [selectedToDateTime, setSelectedToDateTime] = React.useState(dayjs(new Date()));
    const [studioId, setStudioId] = React.useState('')

    // -------------------service page
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [serviceDetails, setServiceDetails] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("all");
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [showAddModule, setShowAddModule] = React.useState(false)
    const [serviceImageproError, setServiceImageproError] = React.useState('')
    const [cardOpen, setCardOpen] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [video11, setVideo11] = React.useState([]);
    const [images11, setImages11] = React.useState([]);

    const [cityName, setCityName] = React.useState('')
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')

    // ----------------------------------------------------------------------
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    React.useEffect(() => {

        if (dataofeditor) {
            try {
                const contentState = stateFromHTML(dataofeditor);
                setEditorState(EditorState.createWithContent(contentState));
            } catch (error) {
                console.error("Error during HTML to ContentState conversion:", error);
            }
        }
    }, [dataofeditor]);


    React.useEffect(() => {
        convertContentToHTML();
    }, [editorState]);

    const [convertedContent, setConvertedContent] = React.useState(null);


    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    }

    const convertContentToHTML = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentAsHTML = stateToHTML(currentContent);
        setConvertedContent(currentContentAsHTML)
    }


    React.useEffect(() => {
        convertContentToHTML1();
    }, [editorState1]);

    const [convertedContent1, setConvertedContent1] = React.useState(null);

    const handleEditorChange1 = (newEditorState) => {
        setEditorState1(newEditorState);
    }

    const convertContentToHTML1 = () => {
        const currentContent1 = editorState1.getCurrentContent();
        const currentContentAsHTML1 = stateToHTML(currentContent1);
        setConvertedContent1(currentContentAsHTML1)
    }

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });

    const profileImageChange = (e, index) => {

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg1(prevValue => prevValue.concat(fileArray))
        setProfImg2(prevValue => prevValue.concat(filepath))
    }

    const DeleteProf = (e, index) => {
        var filteredpreview = profImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg2.filter((val, i) => {
            return i !== index;
        });
        setProfImg1(filteredpreview);
        setProfImg2(filteredraw);
    }



    const multipleImgChange = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }



    React.useEffect(() => {
        getCategory()
    }, [])
    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `admincategory/getallcategories?isdropdown=true`)
            .then((res) => {
                console.log(res.data, "get category");
                setCategoryDetails(res.data.data)
            })
            .catch((error) => {
                console.log(error, "error in get category");
            })
    }



    // service Aditional files
    const serviceAditionalFiles = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setState3(prevValue => prevValue.concat(fileArray))
        setState4(prevValue => prevValue.concat(filepath))
    }

    const indexDeleteService = (e, index) => {
        var filteredpreview = state3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state4.filter((val, i) => {
            return i !== index;
        });
        setState3(filteredpreview);
        setState4(filteredraw);
    }




    //additionsl images

    const AdditionalImgChange = (e) => {
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setAddImg1(prevValue => prevValue.concat(fileArray))
        setAddImg2(prevValue => prevValue.concat(filepath))
    }


    const additionalDelete = (e, index) => {
        var filteredpreview = addImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = addImg2.filter((val, i) => {
            return i !== index;
        });
        setAddImg1(filteredpreview);
        setAddImg2(filteredraw);
    }



    // for  add sercvicee
    const profileImageChangeService = (e, index) => {

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg3(prevValue => prevValue.concat(fileArray))
        setProfImg4(prevValue => prevValue.concat(filepath))
    }

    const DeleteProfService = (e, index) => {
        var filteredpreview = profImg3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg4.filter((val, i) => {
            return i !== index;
        });
        setProfImg3(filteredpreview);
        setProfImg4(filteredraw);
    }


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const Validation = () => {
        if (!studioname && profImg2?.length == 0 && personName?.length == 0) {
            setError({ name: "This field is required.", service: "This field is required.", email: "This field is required.", price1: "This field is required" })
            return false
        }
        if (!studioname.trim()) {
            setError({ name: "This field is required." })
            return false
        }
        // if (!username) {
        //     setError({ username: "This field is required." })
        //     return false
        // }
        if (!email) {
            setError({ email: "This field is required." })
            return false
        }
        if (email) {
            if (!emailRegex.test(String(email).toLowerCase())) {
                setError({ email: "Please enter valid email address" })
                return false
            }
        }

        if (!price1) {
            setError({ price1: "This field is required."})
            return false
        }

        // if (personName?.length == 0) {
        //     setError({ service: "This field is required." })
        //     return false
        // }
        return true
    }

    // seervice validation
    const ValidationService = () => {
        if (!servicename && personNameService?.length == 0) {
            setError({ name: "This field is required.", Category: "This field is required." })
            return false
        }
        if (!servicename) {
            setError({ name: "This field is required." })
            return false
        }


        if (personNameService?.length == 0) {
            setError({ Category: "This field is required." })
            return false
        }
        return true
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
    }
    console.log(selectedOption, "selectjjhjknkjnkjnkl")
    const CreateService = async () => {
        let valid = Validation()
        if (valid) {

            // const { error, paymentMethod } = await stripe.createPaymentMethod({
            //     type: 'card',
            //     card: elements.getElement(CardElement),
            // });

            setButtonview(true)
            let categoryid = personName.map((data) => data._id);
            const formData = new FormData();
            formData.append("name", studioname)
            formData.append("isAdmin", true)
            formData.append("isStatus", true)
            formData.append("isService", false)
            // formData.append("Category_id", categoryid)
            formData.append("admin_id", admindetails._id)
            // formData.append("place", location)
            formData.append("email", email)
            // formData.append("username", username)
            formData.append("description", convertedContent)
            formData.append("city", '')
            formData.append("state", '')
            formData.append("pin", '')
            formData.append("role", 'Studio')
            formData.append('location',cityName)
            formData.append("isOnline", false)
            formData.append("address", '')
            formData.append("diameter", diameter)
            formData.append("price", price1)
            formData.append("place", selectedOption)
            // formData.append("paymentMethodId", paymentMethod ? paymentMethod.id : '')
            { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
            { state2?.length > 0 ? state2.forEach((item) => { formData.append("converimage", item) }) : formData.append("converimage", '') }
            console.log([...formData], "4444");
            await axios.post(process.env.REACT_APP_BASE_URL + `talents/addtalentsdeta`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "res");
                    setButtonview(false)
                    navigate('/studio')
                    enqueueSnackbar("Studio added successfully", { variant: 'success' })
                })
                .catch((error) => {
                    setButtonview(false)
                    console.log(error, "eeee");
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

                })
        }

    }

    const formatPrice = (price1) => {
        let priceStr = price1.toString().replace(/[^0-9]/g, ''); 
        priceStr = priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
        return priceStr;
    }

    const handleCardDetailes = () => {
        setCardOpen(true)
    }


    const SubmitContinue = async () => {

        let valid = Validation()
        if (valid) {
            setButtonview1(true)
            let categoryid = personName.map((data) => data._id)
            setButtonview12(true)
            const formData = new FormData();
            formData.append("name", studioname)
            formData.append("isAdmin", true)
            formData.append("isStatus", true)
            formData.append("isService", false)
            // formData.append("Category_id", categoryid)
            formData.append("admin_id", admindetails._id)
            formData.append("location", cityName)
            formData.append("email", email)
            formData.append("description", convertedContent)
            formData.append("city", '')
            formData.append("state", '')
            formData.append("pin", '')
            formData.append("role", 'Studio')
            formData.append("isOnline", false)
            formData.append("address", '')
            formData.append("diameter", diameter)
            // formData.append("price", studioprice)
            formData.append("price", price1)
            // formData.append("password", '1234')
            { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
            { state2?.length > 0 ? state2.forEach((item) => { formData.append("converimage", item) }) : formData.append("converimage", '') }
            await axios.post(process.env.REACT_APP_BASE_URL + `talents/addtalentsdeta`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res.data, "res");
                    let mId = res.data.modelId
                    setButtonview1(false)
                    setViewpage(2)
                    setStudioId(mId)
                    setButtonview12(false)
                })
                .catch((error) => {
                    setButtonview1(false)
                    setButtonview12(false)
                    console.log(error, "eeee");
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                })
        }

    }

    const handleUplode = () => {
        setButtonview(true)
        // if (addImg2.length > 0) ImageUpDateMethod();
         VideoUpdateMethod();
        navigate('/studio')
    }


    const ImageUpDateMethod = () => {
        return new Promise((resolve, reject) => {
            const formDataservice = new FormData();
            formDataservice.append("createdBy", studioId)
            { addImg2?.length > 0 ? addImg2.forEach((item) => { formDataservice.append("images", item) }) : formDataservice.append("images", '') }
            axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmpost', formDataservice, { headers: headerformdata })
                .then(res => {
                    // enqueueSnackbar("Added Successfully", { variant: 'success' })
                    resolve(res.data)
                })
                .catch(err => {
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                    reject(err)
                })
        })
    }

    // const VideoUpdateMethod = () => {
    //     return new Promise((resolve, reject) => {
    //         const formDataservice = new FormData();
    //         formDataservice.append("createdBy", studioId)
    //         { video2?.length > 0 ? video2.forEach((item) => { formDataservice.append("videos", item) }) : formDataservice.append("videos", '') }
    //         axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmvideo', formDataservice, { headers: headerformdata })
    //             .then((res) => {
    //                 console.log(res, "res");
    //                 enqueueSnackbar("Added Successfully", { variant: 'success' })
    //                 resolve(res.data)

    //             })
    //             .catch((error) => {
    //                 reject(error)
    //                 enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

    //             })
    //     })
    // }

    
    const VideoUpdateMethod = () => {
        console.log(video11,'video11',images11,studioId)
            var formdata = new FormData();
            formdata.append("user_id", studioId);
            { video11?.length > 0 ? video11.forEach((item) => { formdata.append("media", item) }) : formdata.append("media", '') }
            { images11?.length > 0 ? images11.forEach((item) => { formdata.append("media", item) }) : formdata.append("media", '') }

            for (var key of formdata.entries()) {
                console.log(key[0] + ', ' + key[1])
            }
            axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmvideo', formdata, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "resin videos");
                    enqueueSnackbar("Added Successfully", { variant: 'success' });
                })
                .catch((error) => {
                    enqueueSnackbar(error.message === 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' });
                });
       
    };



    const Forservice = async () => {
        let abc = ValidationService()
        if (abc) {
            ServiceAdd()
        }
    }




    const ServiceAdd = async () => {
        let serviceCategory_id = personNameService.map((data) => data._id)
        setButtonview(true)
        setButtonview1(true)
        const formDataservice = new FormData();
        formDataservice.append("section", servicename)
        formDataservice.append("length", length)
        formDataservice.append("height", height)
        formDataservice.append("admin_id", admindetails._id)
        formDataservice.append("available_days", timings)
        formDataservice.append("Details", convertedContent)
        formDataservice.append("Description", convertedContent)
        formDataservice.append("studio_id", studioId)
        { profImg4?.length > 0 ? profImg4.forEach((item) => { formDataservice.append("section_image", item) }) : formDataservice.append("section_image", '') }
        { state4?.length > 0 ? state4.forEach((item) => { formDataservice.append("sectionAddition_img", item) }) : formDataservice.append("sectionAddition_img", '') }
        console.log([...formDataservice], "formDataserviceformDataservice");
        await axios.post(process.env.REACT_APP_BASE_URL + `adminstudio/addroomsinstudio`, formDataservice, { headers: headerformdata })
            .then((res) => {
                console.log(res, "res");
                setShowAddModule(false)
                setButtonview1(false)
                enqueueSnackbar("Added Successfully", { variant: 'success' })
            })
            .catch((error) => {
                setButtonview1(false)
                // setButtonview(false)
                console.log(error, "eeee in Service");
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

            })
    }



    const SubmitBack = () => {
        setViewpage((prevActiveStep) => prevActiveStep - 1);
    }


    const handleMultipleServiceSelect = (event, item) => {
        setError({ service: '' })
        const { target: { value }, } = event;
        const idCounts = value.reduce((acc, item) => {
            acc[item._id] = (acc[item._id] || 0) + 1;
            return acc;
        }, {});

        const filteredData = value.filter(item => idCounts[item._id] === 1);
        setPersonNameService(filteredData);
    }







    const AdditionalVideoChange = (e, index) => {

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setVideo1(prevValue => prevValue.concat(fileArray))
        setVideo2(prevValue => prevValue.concat(filepath))

        const files = Array.from(event.target.files);
        const videos = files.filter(file => file.type.includes('video'));
        const imgs = files.filter(file => file.type.includes('image'));

        setVideo11(prev => [...prev, ...videos]);
        setImages11(prev => [...prev, ...imgs]);
    }

    const indexVideoDelete = (e, index) => {
        var filteredpreview = video1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = video2.filter((val, i) => {
            return i !== index;
        });
        setVideo1(filteredpreview);
        setVideo2(filteredraw);
    }

    const indexVideoDelete12 = (e, index) => {
        e.preventDefault();
        setVideo11(prev => prev.filter((_, i) => i !== index));
    };

    const indexImageDelete = (e, index) => {
        e.preventDefault();
        setImages11(prev => prev.filter((_, i) => i !== index));
    };

    const handleMultipleSelect = (event, item) => {
        setError({ service: '' })
        const { target: { value }, } = event;
        const idCounts = value.reduce((acc, item) => {
            acc[item._id] = (acc[item._id] || 0) + 1;
            return acc;
        }, {});

        const filteredData = value.filter(item => idCounts[item._id] === 1);
        setPersonName(filteredData);
    }


    const handleFromDateTimeChange = (newValue) => {
        console.log(newValue);
        setSelectedFromDateTime(newValue);
        setError(prevError => ({ ...prevError, fromTime: '' }))
    };
    const handleToDateTimeChange = (newValue) => {
        setSelectedToDateTime(newValue);
        setError(prevError => ({ ...prevError, toTime: '' }))
    };




    const handleChangeDetails = (e, val) => {
        console.log(val, "vvv");
        setaditnalValues(val)

    }


    const [fileTypes, setFileTypes] = React.useState([]);
    React.useEffect(() => {
        const fetchFileTypes = async () => {
            const types = await Promise.all(state1.map(async fileUrl => {
                const type = await getFileType(fileUrl);
                return type;
            }));
            setFileTypes(types);
        };

        fetchFileTypes();
    }, [state1]);

    const getFileType = async (fileUrl) => {
        try {
            const response = await axios.get(fileUrl, { responseType: 'blob' });
            console.log(response.data, "vresponseresponseresponse");
            return response.data.type;
        } catch (error) {
            return 'video/mp4';
        }
    }
    React.useEffect(() => {
        getPhotoes();
        getVideos();
    }, [])
    const getPhotoes = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getphotosbyid/${studioId}`)
            .then((res) => {
                setImages(res.data.allimage)
            })
            .catch((error) => {

            })
    }
    const getVideos = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getvideosbyid/${studioId}`)
            .then((res) => {

                setVideos(res.data.allvideos)

            })
            .catch((error) => {

            })
    }


    const TabChenge = () => {
        let NextValue = aditnalValues + 1
        setaditnalValues(NextValue)

    }

    const previesTab = () => {
        let preValue = aditnalValues - 1
        setaditnalValues(preValue)

    }
    // service  page 


    const handleModleOpen = () => {
        setShowAddModule(true)
    }
    const handleServiceModuleClose = () => {
        setShowAddModule(false)
    }
    React.useEffect(() => {
        getServiceDetails()
    }, [rowsPerPage, page, search, status])

    const getServiceDetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `services/gettheservice?page=${page}&size=${rowsPerPage}&search=${search}&isService=${status == 'all' ? '' : status}`)
            .then((res) => {
                console.log(res.data, 'res.data');
                setServiceDetails(res.data.data)
                setTotal(res.data.totalcount)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }


    const EditDetails = () => {
        navigate('/editservice', { state: allDetails })
    }


    const DeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `services/deleteservice/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Deleted successfully", { variant: 'success' })
                getServiceDetails()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                // handleClose()
            })
    }
    // --------------------------------------------------------------------------------------------------

    return (
        <Box className='Addcompany'>
            <MiniDrawer type={8} />
            <Box component="section" className="contentWraper">

                <Box className=' whiteContainer'>
                    <Box className='content p-20'>
                        <Grid container rowSpacing={5} columnSpacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Add Studio</Typography>
                                <Stepper alternativeLabel activeStep={viewpage} connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                {viewpage == 1 ?
                                    <>
                                        <Grid container rowSpacing={2} columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Studio Name <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={studioname}
                                                        onChange={(e) => { setStudioname(e.target.value); setError({ name: "" }) }}
                                                        placeholder='Title'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}

                                            </Grid>
                                            {/* 
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>UserName <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={username}
                                                        onChange={(e) => { setUserName(e.target.value); setError({ username: "" }) }}
                                                        placeholder='UserName'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.username && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.username}</p>}

                                            </Grid> */}

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Email <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={email}
                                                        onChange={(e) => { setEmail(e.target.value); setError({ email: "" }) }}
                                                        placeholder='Email'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.email && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.email}</p>}

                                            </Grid>


                                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Category <Typography component='span' className='star'>*</Typography></Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={personName}
                                                        multiple
                                                        onChange={handleMultipleSelect}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (personName.length === 0) {
                                                                return "No Category";
                                                            } else
                                                                if (personName.length === 1) {
                                                                    return personName[0].category_name;
                                                                } else {
                                                                    return `${personName.length} selected category`;
                                                                }
                                                        }}
                                                        MenuProps={MenuProps}
                                                        variant='outlined'
                                                        className='select'
                                                    >
                                                        {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
                                                            <MenuItem key={name._id} value={name} >
                                                                <Checkbox checked={personName.some(data => data._id == name._id)} />
                                                                <ListItemText primary={name.category_name} />
                                                            </MenuItem>
                                                        )) : <MenuItem>No user found</MenuItem>}
                                                    </Select>
                                                </FormControl>
                                                {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>}

                                            </Grid> */}






                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Location </Typography>
                                                    <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} className="textfield" />
                                                    {/* <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        placeholder='Location'
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    /> */}
                                                </FormControl>
                                            </Grid>

                                            {/* 
                                            <Grid item lg={12} className='pt-0'>
                                                <Typography component='div' className='label '>Type:</Typography>
                                                <Box className=" ">
                                                    <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                                                        <FormControlLabel value="Indoor" control={<Radio />} label="Indoor" />
                                                        <FormControlLabel value="Outdoor" control={<Radio />} label="Outdoor" />
                                                    </RadioGroup>
                                                </Box>
                                            </Grid> */}


                                            <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                                <Typography component='div' className='label'>Description </Typography>
                                                <div className="editorclass">
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorChange}
                                                        toolbarClassName="toolbar"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorcontent"

                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline'],
                                                            },
                                                            blockType: {
                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered'],
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right', 'justify'],
                                                            },
                                                            link: {
                                                                options: ['link'],
                                                            },
                                                            emoji: {
                                                                options: [
                                                                    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                                    '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                                ],
                                                            },
                                                            image: {
                                                                uploadEnabled: true,
                                                                previewImage: true,
                                                                inputAccept: 'image/*',
                                                                alt: { present: false, mandatory: false },
                                                            },
                                                        }}

                                                    />


                                                </div>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={8} lg={8}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Price <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        placeholder='Price'
                                                        value={formatPrice(price1)}
                                                        // onChange={(e) => setPrice1(e.target.value)}
                                                        onChange={(e) => { setPrice1(e.target.value); setError({ price1: "" }) }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.price1 && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.price1}</p>}
                                            </Grid>

                                            {/* Profile Image Section */}
                                            <Grid item xs={12} md={6}>
                                                <Typography component='div' className='label'>Studio Image</Typography>
                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {profImg1.length < 1 &&
                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                            </Box>
                                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChange} hidden type="file" />
                                                        </Button>
                                                    }
                                                </Box>
                                                {profImg1.length > 0 &&
                                                    <Grid container spacing={1} className="multiple_upload_container mb-10">
                                                        {profImg1.map((x, i) => (
                                                            <Grid item xs={12} md={12}>
                                                                <Box className='uploaded_img'>
                                                                    <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>
                                                                    <img src={x} alt='' height={155} width={195} />
                                                                </Box>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                }
                                            </Grid>

                                            {/* Additional Images Section */}
                                            {/* <Grid item xs={12} md={6}>
                                                <Typography component='div' className='label'> Studio Cover Images</Typography>
                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {state1.length < 1 &&
                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                            </Box>
                                                            <input accept={"image/png, image/jpg,image"} onChange={multipleImgChange} hidden type="file" />
                                                        </Button>
                                                    }
                                                </Box>
                                                {state1.length > 0 &&
                                                    <Grid container spacing={1} className="multiple_upload_container mb-10">
                                                        {state1.map((x, i) => (
                                                            <Grid item xs={12} md={12}>
                                                                <Box className='uploaded_img'>
                                                                    <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                                    <img src={x} alt='' height={155} width={195} />
                                                                </Box>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                }
                                            </Grid> */}

                                            {/* Note Section */}
                                            <Grid item xs={12}>
                                                <Typography className='fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                            </Grid>


                                        </Grid>

                                        <Box style={{ marginTop: 10 }} className='heading-btns'>
                                            <Button onClick={handleCardDetailes} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Add Card Details </Button>
                                        </Box>
                                        {cardOpen && (
                                            <div className="modal">
                                                <Card>
                                                    <div className="modal-content">
                                                        <form onSubmit={CreateService} style={{
                                                            backgroundColor: '#f0f0f0',
                                                            borderRadius: '10px',
                                                            padding: '20px',
                                                            width: '300px',
                                                            margin: '0 auto',
                                                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                                                        }}>
                                                            <CardElement />
                                                        </form>
                                                    </div>

                                                </Card>
                                            </div>
                                        )}
                                    </>
                                    :

                                    // 22222222222
                                    <>


                                        <Box style={{ display: 'flex ', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box className='flex-box mb-10 segmentControl' style={{ justifyContent: 'start' }}>
                                                <Tabs
                                                    value={aditnalValues}
                                                    onChange={handleChangeDetails}
                                                    textColor="secondary"
                                                    indicatorColor="secondary"
                                                    aria-label="secondary tabs example"
                                                    className='segmentControl'
                                                    variant="fullWidth" // Add this line to make tabs full width
                                                    sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                                                >
                                                    <Tab className='subsegment' sx={{ textTransform: "none" }} value={1} label="Images" />
                                                    <Tab className='subsegment' sx={{ textTransform: "none" }} value={2} label="Video" />
                                                    <Tab className='subsegment' sx={{ textTransform: "none" }} value={3} label="Sections" />
                                                </Tabs>
                                            </Box>
                                        </Box>
                                        <Grid item xs={12} sm={12} md={12}  >

                                            {aditnalValues === 1 &&
                                                <>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography component="div" className="label">Additional Images</Typography>
                                                            <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                                {/* {addImg1.length < 1 && ( */}
                                                                    <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                            <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                                        </Box>
                                                                        <input
                                                                            accept="image/png, image/jpg, image/jpeg"
                                                                            onChange={(e) => {
                                                                                AdditionalImgChange(e);
                                                                                e.target.value = null;
                                                                            }}
                                                                            hidden
                                                                            multiple
                                                                            type="file"
                                                                        />
                                                                    </Button>
                                                                {/* )} */}
                                                            </Box>
                                                        </Grid>

                                                        {addImg1.length > 0 && (
                                                            <Grid item xs={12}>
                                                                <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                                    {addImg1.map((image, index) => (
                                                                        <Grid item key={index} xs={6} sm={4} md={4} lg={4}>
                                                                            <Box className="uploaded_img" sx={{ position: 'relative' }}>
                                                                                <Button className="close_icon" onClick={(e) => additionalDelete(e, index)} style={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
                                                                                    <Close />
                                                                                </Button>
                                                                                <img src={image} alt={`Uploaded Image ${index}`} height={155} width={195} style={{ display: 'block' }} />
                                                                                {/* {addImg1.length > 1 && (
                                                               <FilterNoneIcon className="filter_none_icon" style={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }} />
                                                             )} */}
                                                                            </Box>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        )}

                                                        <Grid item xs={12}>
                                                            <Typography className="fz-12 mt-10 mb-10" component="p" variant="p">
                                                                <strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>

                                            }



                                            {/* {aditnalValues === 2 &&

                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Typography component='div' className='label'>Additional Videos</Typography>
                                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                            {video1.length < 1 &&
                                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon className="fz-18 mr-5" /> Upload</Box>
                                                                    <input accept={"video/mp4"} onChange={AdditionalVideoChange} hidden type="file" />
                                                                </Button>
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    {video1.length > 0 &&
                                                        <Grid item xs={12}>
                                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                                {video1.map((x, i) => (
                                                                    <Grid item key={i} xs={6} sm={4} md={4} lg={4}>
                                                                        <Box className='uploaded_img' >
                                                                            <video src={x} alt='' height={155} width={195} />
                                                                            <Button className='close_icon' onClick={(e) => indexVideoDelete(e, i)}><Close /></Button>
                                                                        </Box>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            } */}

{aditnalValues === 2 &&
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography component='div' className='label'>Additional Media</Typography>
                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                        {/* {video11.length < 1 && images11.length < 1 && */}
                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <VideocamIcon className="fz-18 mr-5" /> Upload
                                                                </Box>
                                                                <input accept="video/mp4,image/*" onChange={AdditionalVideoChange} hidden type="file" multiple />
                                                            </Button>
                                                        {/* } */}
                                                    </Box>
                                                </Grid>
                                                {(video11.length > 0 || images11.length > 0) &&
                                                    <Grid item xs={12}>
                                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                            {video11.map((x, i) => (
                                                                <Grid item key={i} xs={6} sm={4} md={4} lg={4}>
                                                                    <Box className='uploaded_img'>
                                                                        <video src={URL.createObjectURL(x)} alt='' height={155} width={195} controls />
                                                                        <Button className='close_icon' onClick={(e) => indexVideoDelete12(e, i)}><Close /></Button>
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                            {images11.map((x, i) => (
                                                                <Grid item key={i} xs={6} sm={4} md={4} lg={4}>
                                                                    <Box className='uploaded_img'>
                                                                        <img src={URL.createObjectURL(x)} alt='' height={155} width={195} />
                                                                        <Button className='close_icon' onClick={(e) => indexImageDelete(e, i)}><Close /></Button>
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            } 


                                            {/* ------------------------------------------------------------------------------------------------------------------------------------ */}

                                            {aditnalValues === 3 &&
                                                <div> <Box style={{ marginTop: 10 }} className='heading-btns'>
                                                    <Button onClick={handleModleOpen} variant="contained" className='addbtn mr-10' >Add Section</Button> </Box>
                                                    <Dialog open={showAddModule} keepMounted
                                                        onClose={handleServiceModuleClose}
                                                        aria-describedby="alert-dialog-slide-description"
                                                        className='custom_modal'>
                                                        <DialogTitle>Add Section</DialogTitle>
                                                        <DialogContent dividers>
                                                            <Grid container rowSpacing={2} columnSpacing={2}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                                                        <TextField
                                                                            id="filled-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            className='textfield'
                                                                            value={servicename}
                                                                            onChange={(e) => { setServiceName(e.target.value); setError({ name: "" }) }}
                                                                            placeholder='Title'
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                    {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}

                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    <Typography component='div' className='label'>Primary Image </Typography>

                                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                                        {profImg3.length < 1 &&
                                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChangeService} hidden type="file" />
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>




                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    {profImg3.length > 0 &&
                                                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                                            {profImg3.length > 0 ? profImg3.map((x, i) => (
                                                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                                    <Box className='uploaded_img' >
                                                                                        <Button className='close_icon' onClick={(e) => DeleteProfService(e, i)}><Close /></Button>
                                                                                        <img src={x} alt='' height={155} width={195} />
                                                                                    </Box>
                                                                                </Grid>
                                                                            )) : ""}

                                                                        </Grid>
                                                                    }
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                                                </Grid>


                                                                {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                                                                    <FormControl variant="standard" fullWidth size="small">
                                                                        <Typography component='div' className='label'>Category <Typography component='span' className='star'>*</Typography></Typography>
                                                                        <Select
                                                                            labelId="demo-select-small"
                                                                            id="demo-select-small"
                                                                            value={personNameService}
                                                                            multiple
                                                                            onChange={handleMultipleServiceSelect}
                                                                            input={<OutlinedInput />}
                                                                            renderValue={(selected) => {
                                                                                if (personNameService.length === 0) {
                                                                                    return "No Category";
                                                                                } else
                                                                                    if (personNameService.length === 1) {
                                                                                        return personNameService[0].category_name;
                                                                                    } else {
                                                                                        return `${personNameService.length} selected category`;
                                                                                    }
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            variant='outlined'
                                                                            className='select'
                                                                        >
                                                                            {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
                                                                                <MenuItem key={name._id} value={name} >
                                                                                    <Checkbox checked={personNameService.some(data => data._id == name._id)} />
                                                                                    <ListItemText primary={name.category_name} />
                                                                                </MenuItem>
                                                                            )) : <MenuItem>No user found</MenuItem>}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {/* {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>} */}

                                                                {/* </Grid> */}

                                                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <Typography component='div' className='label'>Price </Typography>
                                                                        <TextField
                                                                            id="filled-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            className='textfield'
                                                                            placeholder='Price'
                                                                            value={price}
                                                                            onChange={(e) => setPrice(e.target.value)}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                                endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                    <FormControl variant="standard" fullWidth size="small">
                                                                        <Typography component='div' className='label'>Duration</Typography>
                                                                        <Select
                                                                            labelId="demo-select-small"
                                                                            id="demo-select-small"
                                                                            value={duration}
                                                                            onChange={(e) => setDuration(e.target.value)}
                                                                            variant='outlined'
                                                                            className='select'
                                                                        >
                                                                            <MenuItem value={'perhour'} className='menuitems'>per-hour</MenuItem>
                                                                            <MenuItem value={'perservice'} className='menuitems'>per-service</MenuItem>
                                                                            <MenuItem value={'perday'} className='menuitems'>per-day</MenuItem>


                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>



                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <Typography component='div' className='label'>Location </Typography>
                                                                        <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} className="textfield" />
                                                                        {/* <TextField
                                                                            id="filled-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            className='textfield'
                                                                            placeholder='Location'
                                                                            value={location}
                                                                            onChange={(e) => setLocation(e.target.value)}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                            }}
                                                                        /> */}
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography component='div' className='label'>From Time </Typography>
                                                                    <FormControl variant="standard" fullWidth className="datepicker">
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']} >
                                                                                <DateTimePicker
                                                                                    onChange={handleFromDateTimeChange}
                                                                                    value={selectedFromDateTime && selectedFromDateTime}
                                                                                    minDate={dayjs()}
                                                                                    renderInput={(props) => <TextField {...props} />}
                                                                                    format="DD-MM-YYYY hh:mm a"
                                                                                    viewRenderers={{
                                                                                        hours: renderTimeViewClock,
                                                                                        minutes: renderTimeViewClock,
                                                                                        seconds: renderTimeViewClock,
                                                                                    }}
                                                                                    className="textfield"
                                                                                />
                                                                            </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {error.fromTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fromTime}</p>}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography component='div' className='label'>To Time</Typography>
                                                                    <FormControl variant="standard" fullWidth className="datepicker">
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                                                <DateTimePicker
                                                                                    size="small"
                                                                                    className="textfield"
                                                                                    onChange={handleToDateTimeChange}
                                                                                    value={selectedToDateTime && selectedToDateTime}
                                                                                    minDate={dayjs(selectedFromDateTime)}
                                                                                    renderInput={(props) => <TextField {...props} />}
                                                                                    format="DD-MM-YYYY hh:mm a"
                                                                                    viewRenderers={{
                                                                                        hours: renderTimeViewClock,
                                                                                        minutes: renderTimeViewClock,
                                                                                        seconds: renderTimeViewClock,
                                                                                    }}
                                                                                />
                                                                            </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {error.toTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.toTime}</p>}
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                                                    <Typography component='div' className='label'>Description </Typography>
                                                                    <div className="editorclass">
                                                                        <Editor
                                                                            editorState={editorState1}
                                                                            onEditorStateChange={handleEditorChange1}
                                                                            toolbarClassName="toolbar"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorcontent"

                                                                            toolbar={{
                                                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                                                inline: {
                                                                                    options: ['bold', 'italic', 'underline'],
                                                                                },
                                                                                blockType: {
                                                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                                                },
                                                                                list: {
                                                                                    options: ['unordered', 'ordered'],
                                                                                },
                                                                                textAlign: {
                                                                                    options: ['left', 'center', 'right', 'justify'],
                                                                                },
                                                                                link: {
                                                                                    options: ['link'],
                                                                                },
                                                                                emoji: {
                                                                                    options: [
                                                                                        '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                                                        '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                                                    ],
                                                                                },
                                                                                image: {
                                                                                    uploadEnabled: true,
                                                                                    previewImage: true,
                                                                                    inputAccept: 'image/*',
                                                                                    alt: { present: false, mandatory: false },
                                                                                },
                                                                            }}

                                                                        />


                                                                    </div>
                                                                </Grid>



                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    <Typography component='div' className='label'>Additional Files</Typography>

                                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                                        {state3.length < 1 &&

                                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                                                <input accept={"image/png, image/jpg,image/jpeg,video/mp4"} onChange={serviceAditionalFiles} hidden multiple type="file" />
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                                {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}

                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    {state3.length > 0 &&
                                                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                                            {state3.length > 0 ? state3.map((x, i) => (
                                                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                                    <Box className='uploaded_img' >
                                                                                        {fileTypes[i]?.startsWith('image/') && (
                                                                                            <>
                                                                                                <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt="Loaded content" height={155} width={195} />
                                                                                            </>
                                                                                        )}
                                                                                        {fileTypes[i]?.startsWith('video/') && (
                                                                                            <>
                                                                                                <video type={fileTypes[i]} controls src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt="Loaded content" height={155} width={195} />
                                                                                            </>
                                                                                        )}
                                                                                        <Button className='close_icon' onClick={(e) => indexDeleteService(e, i)}><Close /></Button>
                                                                                    </Box>
                                                                                </Grid>
                                                                            )) : ""}
                                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                                <label htmlFor="contained-button-file1" className="w-full">
                                                                                    <Box className='multiple_upload_btn' >
                                                                                        <Input
                                                                                            inputProps={{ accept: "image/png, image/jpg,image/jpeg,video/mp4" }}
                                                                                            onChange={serviceAditionalFiles}
                                                                                            style={{ display: "none", }}
                                                                                            id="contained-button-file1"
                                                                                            type="file"
                                                                                        />
                                                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                                                    </Box>
                                                                                </label>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                                                </Grid>
                                                                {buttonview1 == false ?
                                                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                                                        {aditnalValues > 2 && <Button onClick={Forservice} variant="contained" className='addbtn mr-10' >Submit</Button>}
                                                                    </Box>
                                                                    :
                                                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                                                        <Button variant="contained" className='addbtn mr-10' >Please Wait...</Button>
                                                                    </Box>}
                                                            </Grid>
                                                        </DialogContent>


                                                    </Dialog>


                                                    {/* <Box component="section" className="contentWraper"> */}


                                                    {/* <Box className='whiteContainer'>
                                                            <Box> */}
                                                    <TableContainer component={Paper} id='table'>
                                                        <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>

                                                            {/* <DragDropContext onDragEnd={handleOnDragEnd} >
                                                                            <Droppable droppableId="characters">
                                                                                {(provided) => (

                                                                                    <TableBody className="characters "   {...provided.droppableProps} ref={provided.innerRef}>
                                                                                        {serviceDetails && serviceDetails?.length > 0 ? serviceDetails.map((row, i) => (

                                                                                            <Draggable key={row._id} draggableId={row._id} index={i} >
                                                                                                {(provided) => (
                                                                                                    <>
                                                                                                        <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className=''>
                                                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20, }}><img style={{ height: 50, width: 50, borderRadius: '50%' }} src={process.env.REACT_APP_BASE_URL + `${row.profileimage}`} /></TableCell >
                                                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>{row && row.servicename}</TableCell >
                                                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 100 }}>{row && row.talent_id ? row.talent_id.name : row.model_id ? row.model_id.name : row.studio_id ? row.studio_id.name : "-"}  </TableCell>
                                                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>{row && row.pricing} </TableCell >
                                                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 30 }}> {row && row.Category_id?.length && row.Category_id.map((data) => data.category_name).join(', ')}</TableCell >

                                                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                                                                                                                <FormControlLabel className='m-0' control={<IOSSwitch onChange={(e) => handleChangeVisible(e, row)} checked={row.isService} sx={{ m: 1 }} />} />
                                                                                                            </TableCell>

                                                                                                            <TableCell align="left" component="th" scope="row" padding="none" className='DT-row'  >
                                                                                                                <Checkbox onChange={(e) => handleChangeCheck(e, row)} checked={row.setService} />
                                                                                                            </TableCell>
                                                                                                            <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                                                                                <Button
                                                                                                                    id="basic-button"
                                                                                                                    aria-controls={open1 ? 'basic-menu' : undefined}
                                                                                                                    aria-haspopup="true"
                                                                                                                    aria-expanded={open1 ? 'true' : undefined}
                                                                                                                    onClick={(e) => OpenMenu(e, row)}
                                                                                                                >
                                                                                                                    <MoreVertIcon />
                                                                                                                </Button>
                                                                                                                <Menu
                                                                                                                    id="basic-menu"
                                                                                                                    anchorEl={anchorE2}
                                                                                                                    open={open1}
                                                                                                                    onClose={closeMenu}
                                                                                                                    MenuListProps={{
                                                                                                                        'aria-labelledby': 'basic-button',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <MenuItem onClick={() => EditDetails(row)}><ModeEditIcon className='icons text-grey' /> Edit</MenuItem>
                                                                                                                    <MenuItem onClick={(e) => handleClickOpen(row)}><DeleteIcon className='icons text-grey' /> Delete</MenuItem>
                                                                                                                    <MenuItem onClick={(e) => handleView(row)}><RemoveRedEyeIcon className='icons text-grey' /> View</MenuItem>


                                                                                                                </Menu>

                                                                                                            </TableCell>

                                                                                                        </TableRow>
                                                                                                    </>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )) : <TableRow className=''>
                                                                                            <TableCell className='DT-row' align="center" colSpan={5}>
                                                                                                No Data Found
                                                                                            </TableCell >
                                                                                        </TableRow>}
                                                                                    </TableBody>
                                                                                )}
                                                                            </Droppable>
                                                                        </DragDropContext> */}
                                                        </Table>
                                                    </TableContainer>




                                                    {/* <TablePagination
                                                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                                                    component="div"
                                                                    count={total}
                                                                    rowsPerPage={rowsPerPage}
                                                                    page={page}
                                                                    onPageChange={handleChangePage}
                                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                                    className='DT-pagination'
                                                                /> */}




                                                    {/* <Dialog open={open} keepMounted
                                                                    onClose={handleClose}
                                                                    aria-describedby="alert-dialog-slide-description"
                                                                    className='custom_modal'>
                                                                    <DialogTitle>Would you like to delete Service?</DialogTitle>
                                                                    <DialogActions>
                                                                        <Button onClick={handleClose} className='cancel_btn'>No</Button>
                                                                        <Button onClick={DeleteService} className='theme-btn'>Yes</Button>
                                                                    </DialogActions>
                                                                </Dialog> */}





                                                    {/* <BootstrapDialog
                                                                    onClose={handleClickClose}
                                                                    aria-labelledby="customized-dialog-title"
                                                                    open={openview}
                                                                    className='custom_modal'
                                                                    maxWidth='md'
                                                                >
                                                                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                                                        Services Details
                                                                    </DialogTitle>

                                                                    <DialogContent dividers>
                                                                        <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>

                                                                            <Box className=''>
                                                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Service: </Typography>
                                                                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.servicename && alldata.servicename}</Typography>
                                                                            </Box>
                                                                            <Box className=''>
                                                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Username: </Typography>
                                                                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.username && alldata.username}</Typography>
                                                                            </Box>



                                                                            <Box className=''>
                                                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Category: </Typography>
                                                                                <Typography component='span' variant='body1' className='fz-15 '>{alldata && alldata.Category_id?.length && alldata.Category_id.map((data) => data.category_name).join(', ')}</Typography>
                                                                          </Box>

                                                                            <Box className=''>
                                                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Price($): </Typography>
                                                                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.pricing && alldata.pricing}</Typography>
                                                                            </Box>


                                                                            <Box className=''>
                                                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Duration: </Typography>
                                                                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.duration && alldata.duration}</Typography>

                                                                            </Box>
                                                                            <Box className=''>
                                                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Location: </Typography>
                                                                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.location && alldata.location}</Typography>

                                                                            </Box>

                                                                            <Box className=''>
                                                                                <Typography component='span' variant='body1' className='fz-15 semibold'>Date: </Typography>
                                                                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.Date && moment(alldata.Date).format('DD-MM-YYYY')}</Typography>

                                                                            </Box>

                                                                        </Grid>

                                                                        <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">

                                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                                <div className="carosuel mb-10">

                                                                                    <Typography component='span' variant='body1' className='fz-15 semibold'>Primary Image: </Typography>

                                                                                    <Swiper
                                                                                        modules={[Navigation, Pagination]}
                                                                                        className="mySwiper "
                                                                                        slidesPerView={1}
                                                                                        pagination={{
                                                                                            clickable: true,
                                                                                        }}
                                                                                    >


                                                                                        <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${alldata && alldata.profileimage}`} /></SwiperSlide>


                                                                                    </Swiper>
                                                                                </div>

                                                                            </Grid>




                                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                                <div className="carosuel mb-10">

                                                                                    <Typography component='span' variant='body1' className='fz-15 semibold'>Additional Images: </Typography>

                                                                                    <Swiper
                                                                                        modules={[Navigation, Pagination]}
                                                                                        className="mySwiper "
                                                                                        slidesPerView={1}
                                                                                        pagination={{
                                                                                            clickable: true,
                                                                                        }}
                                                                                    >

                                                                                        {alldata && alldata.additionalimage && alldata.additionalimage?.length > 0 && alldata.additionalimage.map((tdata, i) => (
                                                                                            console.log(tdata, "ssssssssssssssss"),

                                                                                            <>
                                                                                                <SwiperSlide>

                                                                                                    {fileTypes[i]?.includes('image') ? <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />
                                                                                                        :
                                                                                                        <video controls type={fileTypes[i]} width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />}

                                                                                                </SwiperSlide>
                                                                                            </>


                                                                                        ))}
                                                                                    </Swiper>

                                                                                </div>

                                                                            </Grid>





                                                                            {alldata && alldata.addionalimage && alldata.addionalimage?.length > 0 &&
                                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                                    <div className="carosuel mb-10">

                                                                                        <Typography component='span' variant='body1' className='fz-15 semibold'>Additional Image: </Typography>

                                                                                        <Swiper
                                                                                            modules={[Navigation, Pagination]}
                                                                                            className="mySwiper "
                                                                                            slidesPerView={1}
                                                                                            pagination={{
                                                                                                clickable: true,
                                                                                            }}
                                                                                        >

                                                                                            {alldata && alldata.addionalimage && alldata.addionalimage?.length > 0 && alldata.addionalimage.map((tdata, i) => {
                                                                                                //     console.log(tdata,"tdata");
                                                                                                //     return <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                                                                                const fileExtension = tdata.split('.').pop().toLowerCase();
                                                                                                console.log(fileExtension, "fileExtensionfileExtension");
                                                                                                if (fileExtension === 'mp4') {
                                                                                                    return (
                                                                                                        <SwiperSlide key={i}>
                                                                                                            <video src={process.env.REACT_APP_BASE_URL + `${tdata}`} type="video/mp4" width="100%" height={250} controls />
                                                                                                        </SwiperSlide>
                                                                                                    );
                                                                                                } else if (['jpg', 'png', 'jpeg'].includes(fileExtension)) {
                                                                                                    return (
                                                                                                        <SwiperSlide key={i}>
                                                                                                            <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} alt="Additional Image" />
                                                                                                        </SwiperSlide>
                                                                                                    );
                                                                                                } else {
                                                                                                    return null;
                                                                                                }

                                                                                            }
                                                                                            )}
                                                                                        </Swiper>
                                                                                    </div>

                                                                                </Grid>
                                                                            }

                                                                            {alldata && alldata.addionalvideo && alldata.addionalvideo?.length > 0 &&
                                                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                                    <div className="carosuel mb-10">

                                                                                        <Typography component='span' variant='body1' className='fz-15 semibold'>Additional Videos: </Typography>

                                                                                        <Swiper
                                                                                            modules={[Navigation, Pagination]}
                                                                                            className="mySwiper "
                                                                                            slidesPerView={1}
                                                                                            pagination={{
                                                                                                clickable: true,
                                                                                            }}
                                                                                        >

                                                                                            {alldata && alldata.addionalvideo && alldata.addionalvideo?.length > 0 && alldata.addionalvideo.map((tdata, i) => (
                                                                                                <SwiperSlide> <video controls width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                                                                            )
                                                                                            )}
                                                                                        </Swiper>
                                                                                    </div>

                                                                                </Grid>
                                                                            }

                                                                        </Grid>

                                                                        <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                                                                        {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                                                            : <Typography className='p18_tag' >There is no description</Typography>}



                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                                                                    </DialogActions>
                                                                </BootstrapDialog> */}
                                                    {/* </Box>
                                                        </Box> */}
                                                    {/* </Box> */}


                                                </div>

                                            }

                                        </Grid>


                                    </>
                                }

                            </Grid>
                        </Grid>

                        {viewpage == 1 ?
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                {buttonview == false ?
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button onClick={CreateService} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Submit</Button>
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
                                    </Box>

                                }
                                {buttonview12 == false ?
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button onClick={SubmitContinue} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Submit and Continue</Button>
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
                                    </Box>
                                }
                            </Box>
                            :

                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Box style={{ marginTop: 10 }} className='heading-btns'>

                                    {aditnalValues > 1 && <Button onClick={previesTab} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Back</Button>}
                                    {aditnalValues < 3 && <Button onClick={TabChenge} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Next</Button>}


                                </Box>


                                {buttonview1 == false ?
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        {aditnalValues > 2 && <Button onClick={handleUplode} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Submit</Button>}
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
                                    </Box>}
                            </Box>
                        }
                    </Box>

                </Box>

            </Box>
        </Box>
    )
}

const PaymentForm = () => (
    <Elements stripe={stripePromise}>
        <Addstudio />
    </Elements>
);

export default PaymentForm;
