import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, IconButton } 
from '@mui/material';
import { Lock, Person } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLayoutEffect } from 'react';

export default function ResetPassword() {
    const { id, token } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleToggleNewPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const validatePasswords = () => {
        let isValid = true;
        if (!newPassword) {
            setPasswordError('This field is required');
            isValid = false;
        }
        if (!confirmPassword) {
            setConfirmPasswordError('This field is required');
            isValid = false;
        }
        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            setConfirmPasswordError('Passwords dose not match');
            isValid = false;
        }
        return isValid;
    };
    
    // useEffect(() => {
    //     const validateToken = async () => {
    //       try {
    //         const response = await axios.get(process.env.REACT_APP_BASE_URL + `talents/linkpassword/${id}/
    //           ${token}`);
    //         console.log(response,'responceee');
    //         // navigate('/expirde');
    //       } catch (err) {
    //         // navigate('/expirde');
    //       }
    //     };
    //     validateToken();
    //   },[]);

      
    const gettoken = async() => {
        const isValid = validatePasswords();
        if (isValid) {
            try {
                const response = await axios.post(
                    process.env.REACT_APP_BASE_URL + `talents/setpassword/${id}/${token}`,
                    { confirmPassword, newPassword }
                );
    
                console.log(response.data, 'Response');
                enqueueSnackbar(response.data.message || 'Password set successfully.', { variant: 'success' });
                navigate('/');
            } catch (error) {
                console.error('Error setting password:', error);
                enqueueSnackbar(error.response?.data?.message || 'Error setting password', { variant: 'error' });
            }
        }
    };
    return (
        <Box className='login'>
            <Grid container spacing={0}>
                {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: 'linear-gradient(to right, #366253, #B4522D, #8E211D, #131313)' }}> */}
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: '#000'}}>
                    <Box className='login_img'>
                        <Box className='logintext_box'>
                            <h1 className='heading'>Empower Your Cultural Network!</h1>
                            <Typography component='p' variant='body1' className='subheading'>
                            Manage talents, models, and studios seamlessly. Your hub for streamlined operations and creative control!
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className="Login_content">
                        <Typography component="h4" variant="h4" className="mb-10">
                            Set Password
                        </Typography>
                        <Box className="form">
                            <FormControl variant="standard">
                                <Typography component="div" className="label">
                                    Password <Typography component='span' className='star'>*</Typography>
                                </Typography>
                                <TextField
                                    id="outlined-adornment-password"
                                    variant="outlined"
                                    size="small"
                                    className="textfield"
                                    placeholder="Password"
                                    value={newPassword}
                                    onChange={(e) => { setNewPassword(e.target.value); setPasswordError(''); setConfirmPasswordError(''); }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className="start_icon" /></InputAdornment>,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleToggleNewPassword}>
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                />
                                  {passwordError && <p className="error-message" style={{ color: 'red' }}>{passwordError}</p>}
                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component="div" className="label">
                                    Confirm Password <Typography component='span' className='star'>*</Typography>
                                </Typography>
                                <TextField
                                    id="outlined-adornment-confirm-password"
                                    variant="outlined"
                                    size="small"
                                    className="textfield"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError(''); setPasswordError(''); }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className="start_icon" /></InputAdornment>,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleToggleConfirmPassword}>
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                />
                                {confirmPasswordError && <p className="error-message" style={{ color: 'red' }}>{confirmPasswordError}</p>}
                            </FormControl>
                        </Box>
                        <Button className="loginbtn" onClick={gettoken}>
                            Reset Password
                        </Button>
                    </Box>
                </Grid>
                </Grid>
                
            {/* </Grid> */}
        </Box>
    );
}
