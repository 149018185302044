
import { Box, Button, Typography, TextField } from '@mui/material'
import React from 'react'
import { usePlacesWidget } from "react-google-autocomplete";

export default function AutoComplete({cityName, setCityName, lat, setLat,lng, setLng}) {
    
    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            console.log(place, 'placeplace');
            setCityName(place.formatted_address)
            // setCity(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
        },
        options: {
            componentRestrictions: { country: ['fr', 'ae'] }, // Restrict to France and UAE
            types: ['geocode'], // Focus on geocoding
            fields: ['formatted_address', 'geometry'],
          },
    });

    const filteredResults = (predictions, status) => {
        const validCities = ['Paris', 'Dubai'];
        
        return predictions.filter(prediction => {
          const city = prediction.description.split(',')[0].trim();
          return validCities.includes(city);
        });
      };

    const filteredAutocompleteRef = React.useMemo(() => {
        return {
          ...autocompleteRef,
          filteredResults: filteredResults,
        };
      }, [autocompleteRef]);


    return (
        <TextField
            id="filled-size-small"
            variant="outlined"
            size="small"
            name='specialization'
            className='textfield'
            placeholder='Address'
            inputRef={ref}
            defaultValue = {cityName}
            filteredAutocompleteRef={filteredAutocompleteRef}
        />
    );
}

