import React from 'react'
import { useState } from 'react';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import MiniDrawer from '../../components/drawer'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
export default function testComponent () {
  
    const [image1,setImage1] = React.useState([])
    const [imag2,setImage2] = React.useState([])

     const handleImageUplode= () =>{
        let fileObject =[];
        let fileArry =[];
        let filePath = [];
        fileObject.push(e.target.files)
         for ( let i=0;i<= fileObject[0].length ;i++)
         {
            if(i<fileObject[0].length){
                fileArry.push(URL.createObjectURL(fileObject[0][i]))
                filePath.push(fileObject[0][i])
            }
         }
            setImage1(prevValue => prevValue.concat(fileArry))
            setImage2(prevValue => prevValue.concat(filePath))
     }

    return(
        <Box>
        <Box component="section" className="contentWraper">
<Grid container item xs={24} sm={24} md={16} lg={6} xl={5}
      sx={{
        backgroundColor: 'lightblue',
        padding: 2,
        height: '100vh', 
      }}>
      </Grid>
      <Grid>
        <Typography>image</Typography>
    {image1.length==0&&
    <Button>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
        <input accept={"image/png, image/jpg,image/jpeg"}  onChange={handleImageUplode} hidden type="file" />
        </Button>}
      </Grid>
    </Box>
        </Box>
    );
 };
