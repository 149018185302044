

export const register = () => {
if ('serviceWorker' in navigator)  {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log(registration.scope,'Service Worker registered with scope');
        })
        .catch((error) => {
          console.error(error,'Error registering service worker333333333333333');
        });
    }
  };

  