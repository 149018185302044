import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import debounce from 'lodash/debounce';


export default function AddCategory() {
    const { enqueueSnackbar } = useSnackbar()
    const headerformdata = {
		'Content-Type': 'multipart/form-data',
		
	}
	const [existingName , setExistingName]  = React.useState('')
    const [buttonview, setButtonview] = React.useState(false) 

    const navigate = useNavigate()
    const [age, setAge] = React.useState('');
    const [imageAddError, setImageAddError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])
    const [ name ,setName]  = React.useState('')
    const [lastname, setLastname] = React.useState('')
    const [ ShortDescription ,setShortDescription] = React.useState('')
    const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])
    const [imageError, setImageError] = React.useState('')
    const [error, setError] = React.useState({ name: "", email: "", phone: "" })
    const [ email, setEmail] = React.useState('')
    const [ phone, setPhone] = React.useState('')
    const [ role, setRole] = React.useState('')
    const [ roleId, setRoleId] = React.useState('')
    const [country, setCountry] = React.useState('');
    console.log(roleId,"roleeeeee");
    let admindetails = JSON.parse(localStorage.getItem("userData"))
 // validation
 const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 const phoneRegex = /^\d{1,10}$/;

 const formatPhone = (phone) => {
    let priceStr = phone.toString().replace(/[^0-9]/g, '');
    return `+${country.dialCode}${priceStr}`;
  };


//   const handleChange = (value, country) => {
//     setPhone(value);
//     setCountry(country);
//   };
const [isValid, setIsValid] = React.useState(false);
const [profImg1, setProfImg1] = React.useState([])
const [profImg2, setProfImg2] = React.useState([])

const debouncedOnChange = debounce((value, country) => {
    handleChange(value, country);
  }, 300);

  const handleChange = (value, country) => {
    // Validate the phone number
    if (value.length >= 10 && country.dialCode) {
      setIsValid(true);
      setPhone(`+${country.dialCode} ${value}`);
      setCountry(country);
    } else {
      setIsValid(false);
      setPhone('');
      setCountry('');
    }
  };

    const Validation = () => {
        if (!name && !email && !phone &&!roleId) {
            setError({ name: "This field is required.", email: "This field is required.", phone: "This field is required.", role: "This field is required." })
            return false
        }
        if (!name.trim()) {
            setError({ name: "This field is required." })
            return false
        }
        if (!email) {
            setError({ email: "This field is required." })
            return false
        }
        if (email) {
            if (!emailRegex.test(String(email).toLowerCase())) {
                setError({ email: "Please enter valid email address" })
                return false
            }
        }
        if (!phone) {
            setError({ phone: "This field is required." })
            return false
        }
        if (!phone) {
        if (!phoneRegex.test(!phone)) {
            setError({ phone: "Please enter a valid phone number." });
            return false;
          }
        }
          if (!roleId) {
            setError({ role: "This field is required." })
            return false
        }
        return true
    } 

    React.useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `roledata/getdropdownroles`)
            .then((res) => {
                console.log(res.data, "res.datares.data");
                setRole(res.data.dropdowndata)
                setTotal(res.data.total)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }

    const handleBack = () =>{
        navigate('/user')
    }

    const profileImageChange = (e, index) => {

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg1(prevValue => prevValue.concat(fileArray))
        setProfImg2(prevValue => prevValue.concat(filepath))
    }

    const DeleteProf = (e, index) => {
        var filteredpreview = profImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg2.filter((val, i) => {
            return i !== index;
        });
        setProfImg1(filteredpreview);
        setProfImg2(filteredraw);
    }



    const handleSubmit = async() => {
        let validate = Validation()   
        const selectedRole = role.find(data => data._id === roleId);  
         console.log(selectedRole,"selectedRoleselectedRoleselectedRole");
        if(validate){
         setButtonview(true)
        const formData = new FormData();
        formData.append("name", name)
        formData.append('lastname',lastname)
        formData.append("isAdmin", true)
        formData.append("isStatus", true)
        formData.append("isService", false)
        formData.append("admin_id", admindetails._id)
        formData.append("location", '')
        formData.append("Date", "")
        formData.append("email", email)
        formData.append("username", name)
        formData.append("description", '')
        formData.append("city", '')
        formData.append("state", '')
        formData.append("pin", '')
        formData.append("phone",phone)
        formData.append("role", 'User')
        formData.append("isOnline", false)
        formData.append("address", '')
        formData.append("role_name", roleId)
        formData.append("roleusers", true)
        { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }

        await axios.post(process.env.REACT_APP_BASE_URL + `talents/addtalentsdeta`, formData, { headers: headerformdata }).then((res) => {
			setButtonview(false)
			enqueueSnackbar("User added Successfully", { variant: 'success', autoHideDuration: 2000 })
			navigate('/user');
        }).catch((error) => {
			setButtonview(false)
			 let errors =error.response.data.message
			 if(errors == "Service name already exist!") {
				setExistingName("User name already exist!")
				enqueueSnackbar("User name already exist!", { variant: 'error' })
			 }
			 else{
				console.log(error, "error");
				enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
			 }
        })
    }
}
    return (
        <Box className='Addcompany'>
            <MiniDrawer type={11}/>
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> First Name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='First Name'
                                                onChange={(e) => {setName(e.target.value);setExistingName('');setError('')}}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {error.name && <p className="error-message"  style={{color:"red"}}>{error.name}</p>} 
                                          {existingName && <p className="error-message"  style={{color:"red"}}>{existingName}</p>} 

										  
                                        </FormControl>
                                    </Grid>
                               
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> Last Name </Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Last Name'
                                                onChange={(e) => setLastname(e.target.value)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {/* {error.name && <p className="error-message"  style={{color:"red"}}>{error.name}</p>} 
                                          {existingName && <p className="error-message"  style={{color:"red"}}>{existingName}</p>}  */}

										  
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> Email <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Email'
                                                onChange={(e) => {setEmail(e.target.value);setError('')}}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {error.email && <p className="error-message"  style={{color:"red"}}>{error.email}</p>} 

										  
                                        </FormControl>
                                    </Grid>
                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Phone{' '}
                                                <Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <PhoneInput
                                                value={phone}
                                                onChange={handleChange}
                                                inputExtraProps={{
                                                    name: 'phone',
                                                }}
                                                inputStyle={{ width: '300px' }}
                                                country={'ae'}
                                                enableAreaCodes={true}
                                                enableSearch={true}
                                            />
                                            {error.phone && <p className="error-message" style={{ color: "red" }}>{error.phone}</p>}
                                        </FormControl>
                                    </Grid>

									<Typography component='div' className='label'>Select Role <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={roleId ? roleId : 'sel'}
											onChange={(e) => { setRoleId(e.target.value); }}>
											<MenuItem disabled value={'sel'} >Select Role</MenuItem>
											{role && role?.length > 0 ? role.map((data) => (
											<MenuItem value={data._id}>{data.role_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
										</Select>
									</FormControl>
								
                                    {error.role && <p className="error-message"  style={{color:"red"}}>{error.role}</p>} 

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Typography component='div' className='label'>Profile Image</Typography>
                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                        {profImg1.length < 1 &&
                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                                </Box>
                                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChange} hidden type="file" />
                                                            </Button>
                                                        }
                                                    </Box>
                                                    {profImg1.length > 0 &&
                                                        <Grid container spacing={1} className="multiple_upload_container mb-10">
                                                            {profImg1.map((x, i) => (
                                                                <Grid item xs={12} md={12}>
                                                                    <Box className='uploaded_img'>
                                                                        <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>
                                                                        <img src={x} alt='' height={155} width={195} />
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    }
                                                </Grid>


                                </Grid>

								{buttonview == false ?
                                <Box className='heading-btns mt-20' style={{textAlign:'end'}}>
                                     <Button variant="contained" className='addbtn mr-10' onClick={handleBack}style={{color:'white', backgroundColor:"black"}}>Back</Button>
                                    <Button variant="contained" className='addbtn mr-10' onClick={handleSubmit}style={{color:'white', backgroundColor:"black"}}>Submit</Button>
                                </Box>
								:
								<Box className='heading-btns mt-20' style={{textAlign:'end'}}>
								<Button variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
							</Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
