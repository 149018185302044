import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Tooltip, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField,Avatar, FormControlLabel, Checkbox } from '@mui/material';
import { ArrowDropDown, DeleteOutlined, FilterAltRounded, PauseCircleOutlined, SearchOutlined, SelectAll, Verified } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Input } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar, SnackbarProvider } from 'notistack';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';



export default function DataTable() {
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    let navigate = useNavigate()
    const [newdata, setNewdata] = React.useState([]);
    const [deleteId, setDeleteId] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    console.log(search,"searchsearchsearch")

    React.useEffect(() => {
        GetCategoryList()
    }, [page, rowsPerPage,search]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleSearchChange = (e) => {
      console.log(e.target.value,"eeeeeeeeeeee")
      setSearch(e.target.value);
      setPage(0);
  };

  const handleCancelClick = () => {
    setSearch('');
  };

    const GetCategoryList = async() => {
      console.log(``)
        await axios.get(process.env.REACT_APP_BASE_URL + `admincategory/getallcategories?page=${page}&size=${rowsPerPage}&search=${search}`)
            .then((res) => {
                console.log(res.data,"category get");
                setNewdata(res.data.data);
                setTotal(res.data.totalcount)
            })
            .catch((err) => {
                console.log(err, "error in get service");
            })

    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const [selectedItems,setSelectedItems] = React.useState('')
    console.log(selectedItems,"selectedItemsselectedItemsselectedItems")
    const handleSelectAll = (event) => {
       const isChecked = event.target.checked; 
       console.log(isChecked,"isCheckedisChecked")
       setSelectedItems(isChecked ? allItems : [])  
    }; 

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(newdata);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;
        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }
        setNewdata(fromvar);
        var body = {
            index_position: currElIndexNumber
        }

        axios.put(process.env.REACT_APP_BASE_URL + `admincategory/updateposition/${final._id}`, body)
            .then((res) => {
                GetCategoryList()
            })
            .catch((err) => {
                console.log(err, "error in get users");
            })

    }


    const EditData = (row) => {
        navigate("/editcategory", { state: row })
    }
    const DeletData = (id) => {
        setDeleteId(id._id);
        setOpen(true)
    }

    const handleDeleteMultiple = () => {
      const selectedIds = Object.keys(deleteId).filter(id => deleteId[id]);
      
    };
    const [allItems, setAllItems] = React.useState([]);
    const handleDelete = async() => {
        await axios.put(process.env.REACT_APP_BASE_URL + `admincategory/deletecategory/${deleteId}`)
            .then((res) => {
                GetCategoryList()
                setAllItems(res.data._id)
                setOpen(false);
                enqueueSnackbar("Category Deleted Successfully", { variant: 'success' })
            })
            .catch((err) => {
                setOpen(true);
                if (err.response.data.status == 4) {
                    enqueueSnackbar(err.response.data.message, { variant: 'error' })
                }


                console.log(err, "error in get users");
            });
    };

    return (

        <Box>
        <TextField
          label="Search"
          // variant="outlined"
          // id="filled-size-small"
          variant="outlined"
          // size="small"
          // fullWidth
          style={{ width: '300px', alignItems: 'center', justifyContent: 'flex-end' }}
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <SearchOutlinedIcon className='fz-18' />
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="end">
                    {search && (
                        <IconButton onClick={handleCancelClick}>
                            <CancelIcon className='fz-18' />
                        </IconButton>
                    )}
                </InputAdornment>
            ),
        }}
        />
       
        {/* <Button variant="contained" style={{marginLeft:'600px', backgroundColor: 'black'}}>Delete</Button> */}
        
            <TableContainer component={Paper} id='table'>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
        <TableHead className='DT-head'>
          <TableRow className=''>
          <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight: 'bold' }}>
                  <FormControlLabel
                    
                    control={
                      <Checkbox
                        onChange={handleSelectAll}
                        size="small"
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'black',
                          },
                          '&.Mui-checked + .MuiSvgIcon-root': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                  />
                </TableCell>
            <TableCell align="left" className='TRow DT-checkbox' style={{ width: 200, padding: '6px 16px', fontWeight:'bold'}}>Image</TableCell>
            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px', fontWeight:'bold' }}>Category name</TableCell>
            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px', fontWeight:'bold' }}>Description </TableCell>
            <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Action </TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <TableBody className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {newdata && newdata.length > 0 ? newdata.map((row, i) => (
                  <Draggable key={row._id} draggableId={row._id} index={i}>
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className=''
                      >

<TableCell className='DT-row' align="left" style={{ minWidth: 50}}>
                        <FormControlLabel
                        control={<Checkbox onChange={handleDeleteMultiple(row)} name="click" checked={row.selected} size='small' sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>}/>
                          {/* <input
                            type="checkbox"
                            checked={row.selected}
                            onChange={(e) => handleDeleteMultiple(row)}
                            // checked={SelectAll} 
                            // onChange={handleSelectAll} 
                          /> */}
                        </TableCell>
                        <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                          {row.photo?.length > 0 ? (
                            <img
                              src={process.env.REACT_APP_BASE_URL + `${row.photo}`}
                              height={50}
                              width={50}
                              style={{ borderRadius: '50%' }}
                            />
                          ) : (
                            <Avatar
                              sx={{ width: 32, height: 32, margin: 1 }}
                              alt={row.username ? row.username.charAt(0).toUpperCase() : 'Y'} // Alt attribute for accessibility
                            >
                              {row.username ? row.username.charAt(0).toUpperCase() : 'Y'}
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell className='DT-row' align="left" style={{ minWidth: 100 }}>{row.category_name}</TableCell>
                        <TableCell className='DT-row' align="left" style={{ minWidth: 100 }}>{row.short_desc}</TableCell>
                        <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }}>
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Edit">
                              <Box className='a_tag' onClick={() => EditData(row)}>
                                <ModeEditIcon className='icons text-grey' />
                              </Box>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <Box onClick={() => DeletData(row)}>
                                <DeleteIcon className='icons text-grey' />
                              </Box>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        

                      </TableRow>
                    )}
                  </Draggable>
                )) : (
                  <TableRow className=''>
                    <TableCell className='DT-row' align="center" colSpan={5}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='DT-pagination'
            />

            <Dialog open={open} keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'>
                <DialogTitle>Would you like to delete Category?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn' style={{ color: '#B4522D' }}>No</Button>
                    <Button onClick={handleDelete} className='theme-btn' style={{ color: '#FFFFFF', backgroundColor: '#B4522D' }}>Yes</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}
