import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography,OutlinedInput ,Checkbox,ListItemText} from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate,useLocation } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function EditCategory() {
    const { enqueueSnackbar } = useSnackbar()
    let location = useLocation()
    const headerformdata = {
		'Content-Type': 'multipart/form-data',
	}
    console.log(location.state,"location");
	const [existingName , setExistingName]  = React.useState('')
    const [buttonview, setButtonview] = React.useState(false) 
    const navigate = useNavigate()
    const [ name ,setName]  = React.useState(location.state.title)
    const [ ShortDescription ,setShortDescription] = React.useState(location.state.description)
    const [ error, setErrors] = React.useState('')
    const [personName, setPersonName] = React.useState(location.state.category
       )
    const [categoryDetails, setCategoryDetails] = React.useState([])

    React.useEffect(() => {
        getCategory()
    }, [])
 // validation
 const Validation = () => {
   
    if(!name ){
        setErrors("This field is required");
	
			return false
    }
    else{
        return true
    }
    
}
const handleBack = () =>{
    navigate('/subcategory')
}

    const handleUpdate = async() => {
        let validate = Validation()  
        let categoryid = personName.map((data) => data._id)    
        if(validate){
         setButtonview(true)
         let body ={
            // title:name,
            category:categoryid,
            description:ShortDescription
          }
        console.log(body,"body");
        await axios.put(process.env.REACT_APP_BASE_URL + `admincategory/updatesubcat/${location.state._id}`, body, { headers: headerformdata }).then((res) => {
			setButtonview(false)
			enqueueSnackbar("Sub Category updated Successfully", { variant: 'success' })
			navigate('/subcategory');
        }).catch((error) => {
			setButtonview(false)
            enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
		
        })
    }
}
const getCategory = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `admincategory/getallcategories?isdropdown=true`)
        .then((res) => {
            console.log(res.data, "get category");
            setCategoryDetails(res.data.data)
        })
        .catch((error) => {
            console.log(error, "error in get category");
        })
}
const handleMultipleSelect = (event, item) => {
    const { target: { value }, } = event;
    const idCounts = value.reduce((acc, item) => {
        acc[item._id] = (acc[item._id] || 0) + 1;
        return acc;
    }, {});
    const filteredData = value.filter(item => idCounts[item._id] === 1);
    setPersonName(filteredData);
}
    return (
        <Box className='Addcompany'>
            <MiniDrawer type={2}/>
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit  Sub Category</Typography>
                     <Grid container rowSpacing={2} columnSpacing={2}>                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> Title <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                value={name}
                                                onChange={(e) => {setName(e.target.value);setExistingName('');setErrors('')}}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {error && <p className="error-message"  style={{color:"red"}}>{error}</p>} 
                                          {existingName && <p className="error-message"  style={{color:"red"}}>{existingName}</p>} 
                                        </FormControl>
                                    </Grid>
                               
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Category <Typography component='span' className='star'>*</Typography></Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={personName}
                                                        multiple
                                                        onChange={handleMultipleSelect}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (personName.length === 0) {
                                                                return "No Category";
                                                            } else
                                                                if (personName.length === 1) {
                                                                    return personName[0].category_name;
                                                                } else {
                                                                    return `${personName.length} selected category`;
                                                                }
                                                        }}
                                                        MenuProps={MenuProps}
                                                        variant='outlined'
                                                        className='select'
                                                    >
                                                        {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
                                                            <MenuItem key={name._id} value={name} >
                                                                <Checkbox checked={personName.some(data => data._id == name._id)} />
                                                                <ListItemText primary={name.category_name} />
                                                            </MenuItem>
                                                        )) : <MenuItem>No user found</MenuItem>}
                                                    </Select>
                                                </FormControl>
                                                {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>}

                                            </Grid>
							
                                   
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Short Description</Typography>
                                            <textarea
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textarea'
                                                placeholder='Short Description'
                                                value={ShortDescription}
                                                onChange={(e) =>setShortDescription(e.target.value) }
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

								{buttonview == false ?

                                <Box className='heading-btns mt-20' style={{textAlign:'end'}}>
                                   <Button  onClick={handleBack} variant="contained" className='addbtn mr-10' >Back</Button>

                                    <Button variant="contained" className='addbtn mr-10' onClick={handleUpdate}>Update</Button>
                                </Box>
								:
								<Box className='heading-btns mt-20' style={{textAlign:'end'}}>
								<Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
							</Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}