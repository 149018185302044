import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Checkbox, Grid, InputAdornment, TextField, FormControlLabel, Typography } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'


export default function Addrole() {

    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [role, setRole] = React.useState({ title: "" })
    const [error, setError] = React.useState({ title: "" })

    let name, value;
    const handleRole = (e) => {
        name = e.target.name;
        value = e.target.value
        setRole({ ...role, [name]: value })
    }

    const Validator = () => {
        if (!role.title) {
            setError({ title: "This field is required." })
            return false
        }
        return true
    }
    const handleBack = () =>{
        navigate('/role')
    }
    const handleSubmit = async () => {
        var Valid = Validator()
        if (Valid) {
            let body = {
                role_title: role.title.toString(),
     
                    addbooking: checkBooking.addBooking,
                    listbooking: checkBooking.listBooking,
                    editbooking: checkBooking.editBooking,
                    deletebooking: checkBooking.deleteBooking,
             
             
                    addtalent: checkTalents.addTalents,
                    listtalent: checkTalents.listTalents,
                    deletetalent: checkTalents.deleteTalents,
                    edittalent: checkTalents.editTalents,
                
             
                    addmodel: checkModels.addModels,
                    listmodel: checkModels.listModels,
                    editmodel:checkModels.editModels,
                    deletemodel: checkModels.deleteModels,
               
                    addstudio: checkStudios.addStudios,
                    liststudio: checkStudios.listStudios,
                    editstudio: checkStudios.editStudios,
                    deletestudio: checkStudios.deleteStudios,
              
                    chat: checkSubChat.chat,
              
                    addcustomer: checkCustomer.addCustomer,
                    listcustomer: checkCustomer.listCustomer,
                    deletecustomer: checkCustomer.deleteCustomer,
                    editcustomer: checkCustomer.editCustomer,
           
     
                    addservice: checkService.addService,
                    listservice: checkService.viewService,
                    editservice: checkService.editService,
                    deleteservice: checkService.deleteService,
              
    
                    addusers: checkUser.addUser,
                    listuser: checkUser.viewUser,
                    edituser: checkUser.editUser,
                    deleteuser: checkUser.deleteUser,
               
               
                    addcate: checkCategory.addCategory,
                    listcate: checkCategory.viewCategory,
                    editcate: checkCategory.editCategory,
                    deletecate: checkCategory.deleteCategory,

                    dashboard:checkDashboard.dashboard,
                    roleManagement:checkManagement.roleManagement
                
            }
            axios.post(process.env.REACT_APP_BASE_URL + 'roledata/addroleaccess', body).then((res) => {
                enqueueSnackbar("Role added successfully", { variant: 'success' })
                navigate("/role")
                console.log(res,"roles addedd");
            }).catch((err) => {
                console.log(err);
                let errors =err.response.data.message
                if(errors == "Role name already exist!") {
                    enqueueSnackbar("Role name already exist!",{ variant: 'error' })
                 }
                else{
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                }

            })
        }
    }

    // CHECKBOX
    const [checkBooking, setCheckBooking] = React.useState({ addBooking: false, listBooking: false, editBooking: false, deleteBooking: false })
    // const handleBookings = (e) => {
    //     console.log(e.target.name);
    //     if (e.target.name === 'deleteBooking') {
    //         setCheckBooking({ addBooking: e.target.checked, listBooking: e.target.checked, editBooking: e.target.checked, deleteBooking: e.target.checked })
    //     }
    //     if (e.target.name === 'editBooking') {
    //         setCheckBooking({ addBooking: e.target.checked, listBooking: e.target.checked, editBooking: e.target.checked })
    //     }
    //     if (e.target.name === 'addBooking') {
    //         setCheckBooking({ addBooking: e.target.checked, listBooking: e.target.checked })
    //     }
    //     if (e.target.name === 'listBooking') {
    //         setCheckBooking({ listBooking: e.target.checked })
    //     }
    // }

    const handleBookings = (e) => {
        const { name, checked } = e.target;
        setCheckBooking(prevState => {
            switch (name) {
                case 'deleteBooking':
                    return { ...prevState, deleteBooking: checked };
                case 'editBooking':
                    return { ...prevState, editBooking: checked };
                case 'addBooking':
                    return { ...prevState, addBooking: checked };
                case 'listBooking':
                    return { ...prevState, listBooking: checked };
                default:
                    return prevState;
            }
        });
    };
    

    const [checkTalents, setCheckTalents] = React.useState({ addTalents: false, listTalents: false, editTalents: false, deleteTalents: false })
    // const handleCheckTalents = (e) => {
    //     if (e.target.name === 'deleteTalents') {
    //         setCheckTalents({ addTalents: e.target.checked, listTalents: e.target.checked, editTalents: e.target.checked, deleteTalents: e.target.checked })
    //     }
    //     if (e.target.name === 'editTalents') {
    //         setCheckTalents({ addTalents: e.target.checked, listTalents: e.target.checked, editTalents: e.target.checked })
    //     }
    //     if (e.target.name === 'addTalents') {
    //         setCheckTalents({ addTalents: e.target.checked, listTalents: e.target.checked })
    //     }
    //     if (e.target.name === 'listTalents') {
    //         setCheckTalents({ listTalents: e.target.checked })
    //     }
    // }

    const handleCheckTalents = (e) => {
        const { name, checked } = e.target;
        setCheckTalents(prevState => {
            switch (name) {
                case 'deleteTalents':
                    return { ...prevState, deleteTalents: checked };
                case 'editTalents':
                    return { ...prevState, editTalents: checked };
                case 'addTalents':
                    return { ...prevState, addTalents: checked };
                case 'listTalents':
                    return { ...prevState, listTalents: checked };
                default:
                    return prevState;
            }
        });
    };

    const [checkModels, setCheckModels] = React.useState({ addModels: false, listModels: false, editModels: false, deleteModels: false });
    const handleCheckModels = (e) => {
        const { name, checked } = e.target;
        setCheckModels(prevState => {
            switch (name) {
                case 'deleteModels':
                    return { ...prevState, deleteModels: checked };
                case 'editModels':
                    return { ...prevState, editModels: checked };
                case 'addModels':
                    return { ...prevState, addModels: checked };
                case 'listModels':
                    return { ...prevState, listModels: checked };
                default:
                    return prevState;
            }
        });
    };

    const [checkStudios, setCheckStudios] = React.useState({ addStudios: false, listStudios: false, editStudios: false, deleteStudios: false })
    // const handleStudios = (e) => {
    //     if (e.target.name === 'deleteStudios') {
    //         setCheckStudios({ addStudios: e.target.checked, listStudios: e.target.checked, editStudios: e.target.checked, deleteStudios: e.target.checked })
    //     }
    //     if (e.target.name === 'editStudios') {
    //         setCheckStudios({ addStudios: e.target.checked, listStudios: e.target.checked, editStudios: e.target.checked })
    //     }
    //     if (e.target.name === 'addStudios') {
    //         setCheckStudios({ addStudios: e.target.checked, listStudios: e.target.checked })
    //     }
    //     if (e.target.name === 'listStudios') {
    //         setCheckStudios({ listStudios: e.target.checked })
    //     }
    // }
    const handleStudios = (e) => {
        const { name, checked } = e.target;
        setCheckStudios(prevState => {
            switch (name) {
                case 'deleteStudios':
                    return { ...prevState, deleteStudios: checked };
                case 'editStudios':
                    return { ...prevState, editStudios: checked };
                case 'addStudios':
                    return { ...prevState, addStudios: checked };
                case 'listStudios':
                    return { ...prevState, listStudios: checked };
                default:
                    return prevState;
            }
        });
    };
    

    const [checkSubChat ,setCheckChat] = React.useState({ chat: false})
    const handleCheckChat = (e) => {
            setCheckChat({ chat: e.target.checked})
    }

    const [checkUser, setCheckUser] = React.useState({ addUser: false, viewUser: false, editUser: false, deleteUser: false })
    // const handleCheckUser = (e) => {
    //     if (e.target.name === 'deleteUser') {
    //         setCheckUser({ addUser: e.target.checked, viewUser: e.target.checked, editUser: e.target.checked, deleteUser: e.target.checked })
    //     }
    //     if (e.target.name === 'editUser') {
    //         setCheckUser({ addUser: e.target.checked, viewUser: e.target.checked, editUser: e.target.checked })
    //     }
    //     if (e.target.name === 'addUser') {
    //         setCheckUser({ addUser: e.target.checked, viewUser: e.target.checked })
    //     }
    //     if (e.target.name === 'viewUser') {
    //         setCheckUser({ viewUser: e.target.checked })
    //     }
    // }

    const handleCheckUser = (e) => {
        const { name, checked } = e.target;
        setCheckUser(prevState => {
            switch (name) {
                case 'deleteUser':
                    return { ...prevState, deleteUser: checked };
                case 'editUser':
                    return { ...prevState, editUser: checked };
                case 'addUser':
                    return { ...prevState, addUser: checked };
                case 'viewUser':
                    return { ...prevState, viewUser: checked };
                default:
                    return prevState;
            }
        });
    };
    

    const [checkCustomer, setCheckCustomer] = React.useState({ addCustomer: false, listCustomer: false, editCustomer: false, deleteCustomer: false })
    // const handleCheckCustomer = (e) => {
    //     if (e.target.name === 'deleteCustomer') {
    //         setCheckCustomer({ addCustomer: e.target.checked, listCustomer: e.target.checked, editCustomer: e.target.checked, deleteCustomer: e.target.checked })
    //     }
    //     if (e.target.name === 'editCustomer') {
    //         setCheckCustomer({ addCustomer: e.target.checked, listCustomer: e.target.checked, editCustomer: e.target.checked })
    //     }
    //     if (e.target.name === 'addCustomer') {
    //         setCheckCustomer({ addCustomer: e.target.checked, listCustomer: e.target.checked })
    //     }
    //     if (e.target.name === 'listCustomer') {
    //         setCheckCustomer({ listCustomer: e.target.checked })
    //     }
    // }

    const handleCheckCustomer = (e) => {
        const { name, checked } = e.target;
        setCheckCustomer(prevState => {
            switch (name) {
                case 'deleteCustomer':
                    return { ...prevState, deleteCustomer: checked };
                case 'editCustomer':
                    return { ...prevState, editCustomer: checked };
                case 'addCustomer':
                    return { ...prevState, addCustomer: checked };
                case 'listCustomer':
                    return { ...prevState, listCustomer: checked };
                default:
                    return prevState;
            }
        });
    };
    

    const [checkService, setCheckService] = React.useState({addService: false, viewService: false, editService: false, deleteService: false})
    // const handleCheckService= (e) => {
    //     if (e.target.name === 'deleteService') {
    //         setCheckService({ addService: e.target.checked, viewService: e.target.checked, editService: e.target.checked, deleteService: e.target.checked })
    //     }
    //     if (e.target.name === 'editService') {
    //         setCheckService({ addService: e.target.checked, viewService: e.target.checked, editService: e.target.checked })
    //     }
    //     if (e.target.name === 'addService') {
    //         setCheckService({ addService: e.target.checked, viewService: e.target.checked })
    //     }
    //     if (e.target.name === 'viewService') {
    //         setCheckService({ viewService: e.target.checked })
    //     }
    // }
    const handleCheckService = (e) => {
        const { name, checked } = e.target;
        setCheckService(prevState => {
            switch (name) {
                case 'deleteService':
                    return { ...prevState, deleteService: checked };
                case 'editService':
                    return { ...prevState, editService: checked };
                case 'addService':
                    return { ...prevState, addService: checked };
                case 'viewService':
                    return { ...prevState, viewService: checked };
                default:
                    return prevState;
            }
        });
    };

    const [checkCategory, setCheckCategory] = React.useState({addCategory: false, viewCategory: false, editCategory: false, deleteCategory: false})
    // const handleCheckCategory = (e) => {
    //     if (e.target.name === 'deleteCategory') {
    //         setCheckCategory({ addCategory: e.target.checked, viewCategory: e.target.checked, editCategory: e.target.checked, deleteCategory: e.target.checked })
    //     }
    //     if (e.target.name === 'editCategory') {
    //         setCheckCategory({ addCategory: e.target.checked, viewCategory: e.target.checked, editCategory: e.target.checked })
    //     }
    //     if (e.target.name === 'addCategory') {
    //         setCheckCategory({ addCategory: e.target.checked, viewCategory: e.target.checked })
    //     }
    //     if (e.target.name === 'viewCategory') {
    //         setCheckCategory({ viewCategory: e.target.checked })
    //     }
    // }
    const handleCheckCategory = (e) => {
        const { name, checked } = e.target;
        setCheckCategory(prevState => {
            switch (name) {
                case 'deleteCategory':
                    return { ...prevState, deleteCategory: checked };
                case 'editCategory':
                    return { ...prevState, editCategory: checked };
                case 'addCategory':
                    return { ...prevState, addCategory: checked };
                case 'viewCategory':
                    return { ...prevState, viewCategory: checked };
                default:
                    return prevState;
            }
        });
    };


    const [checkDashboard, setCheckDashboard] = React.useState({dashboard: false})

    const handleCheckdashboard = (e) => {
        const { name, checked } = e.target;
        setCheckDashboard(prevState => {
            switch (name) {
                case 'dashboard':
                    return { ...prevState, dashboard: checked };
                default:
                    return prevState;
            }
        });
    };

    const [checkManagement, setCheckManagement] = React.useState({roleManagement: false})

    const handleCheckroles = (e) => {
        const { name, checked } = e.target;
        setCheckManagement(prevState => {
            switch (name) {
                case 'roleManagement':
                    return { ...prevState, roleManagement: checked };
                default:
                    return prevState;
            }
        });
    };
    


    return (
        <Box className='Addcompany'>
            <MiniDrawer type={2} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Role</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" >
                                            <Typography component='div' className='label'>Role name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="title"
                                                value={role.title}
                                                onChange={(e) => { handleRole(e); setError({ title: "" }) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                        </FormControl>
                                    </Grid>


                                    {/* FROM PAX */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>
                                        <Box >
                                            <Typography className=' fz-18 semibold' component='h4' variant='h4'>Permissions</Typography>
                                            <Box className='mt-30'>
                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Services</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Service </Typography>} control={<Checkbox onChange={handleCheckService} name="addService" checked={checkService.addService} size='small' sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Service </Typography>} control={<Checkbox onChange={handleCheckService} name="viewService" checked={checkService.viewService} size='small' sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Service </Typography>} control={<Checkbox onChange={handleCheckService} name="editService" checked={checkService.editService} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Service </Typography>} control={<Checkbox onChange={handleCheckService} name="deleteService" checked={checkService.deleteService} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>
                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Bookings</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="addBooking" checked={checkBooking.addBooking} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="listBooking" checked={checkBooking.listBooking} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="editBooking" checked={checkBooking.editBooking} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="deleteBooking" checked={checkBooking.deleteBooking} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>

<Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Talents</Typography>
<Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
    <Box sx={{ width: '250px' }}>
        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="addTalents" checked={checkTalents.addTalents} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
    </Box>
    <Box sx={{ width: '250px' }}>
        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="listTalents" checked={checkTalents.listTalents} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
    </Box>
    <Box sx={{ width: '250px' }}>
        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="editTalents" checked={checkTalents.editTalents} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
    </Box>
    <Box sx={{ width: '250px' }}>
        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="deleteTalents" checked={checkTalents.deleteTalents} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
    </Box>
</Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Models</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="addModels" checked={checkModels.addModels} size='small'   sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="listModels" checked={checkModels.listModels} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="editModels" checked={checkModels.editModels} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="deleteModels" checked={checkModels.deleteModels} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Studios</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Studios </Typography>} control={<Checkbox onChange={handleStudios} name="addStudios" checked={checkCategory.addStudios} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Studios </Typography>} control={<Checkbox onChange={handleStudios} name="listStudios" checked={checkCategory.listStudios} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Studios </Typography>} control={<Checkbox onChange={handleStudios} name="editStudios" checked={checkCategory.editStudios} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Studios </Typography>} control={<Checkbox onChange={handleStudios} name="deleteStudios" checked={checkCategory.deleteStudios} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Chat</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Chat </Typography>} control={<Checkbox onChange={handleCheckChat} name="addSubcategory" checked={checkSubChat.chat} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Customer Management</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Customers </Typography>} control={<Checkbox onChange={handleCheckCustomer} name="addCustomer" checked={checkCustomer.addCustomer} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Customers </Typography>} control={<Checkbox onChange={handleCheckCustomer} name="listCustomer" checked={checkCustomer.listCustomer} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Customers </Typography>} control={<Checkbox onChange={handleCheckCustomer} name="editCustomer" checked={checkCustomer.editCustomer} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Customers </Typography>} control={<Checkbox onChange={handleCheckCustomer} name="deleteCustomer" checked={checkCustomer.deleteCustomer} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>User Management</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="addUser" checked={checkUser.addUser} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="viewUser" checked={checkUser.viewUser} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="editUser" checked={checkUser.editUser} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="deleteUser" checked={checkUser.deleteUser} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Categories</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="addCategory" checked={checkCategory.addCategory} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="viewCategory" checked={checkCategory.viewCategory} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="editCategory" checked={checkCategory.editCategory} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="deleteCategory" checked={checkCategory.deleteCategory} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Dashboard</Typography>
                                                <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Dashboard </Typography>} control={<Checkbox onChange={handleCheckdashboard} name="dashboard" checked={checkDashboard.dashboard} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Roles</Typography>
                                                <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Roles Mangement </Typography>} control={<Checkbox onChange={handleCheckroles} name="roleManagement" checked={checkManagement.roleManagement} size='small'  sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }}/>} />
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                <Button variant="contained" className='addbtn mr-10' onClick={handleBack} style={{ color: 'white', backgroundColor: 'black' }}>Back</Button>

                                    <Button onClick={handleSubmit} variant="contained" className='addbtn mr-10' style={{ color: 'white', backgroundColor: 'black' }}>Submit</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}






