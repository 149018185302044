import React from 'react';
import { Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, IconButton, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import Thankyou from '../../Images/Thank you.jpg'
export default function ResetPassword() {
    let navigate = useNavigate();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [newpassword, setNewPassword] = React.useState('');
    const [conformpassword, setConformPassword] = React.useState('');
    const [passerror, setPassError] = React.useState('');
    const [conpasserror, setConpassError] = React.useState('');
    const [email, setEmail] = React.useState(location.state?.email || '');
    const [error, setError] = React.useState('');
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleToggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const validation = () => {
        if (!newpassword && !conformpassword) {
            setPassError('This field is required');
            setConpassError('This field is required');
            return false;
        } else if (!newpassword) {
            setPassError('This field is required');
            return false;
        } else if (!conformpassword) {
            setConpassError('This field is required');
            return false;
        } else if (newpassword !== conformpassword) {
            setConpassError('password dose not match ');
            return false;
        }
        return true;
    };
    const handleSubmit = async () => {
        let valid = validation();
        if (valid) {
            let body = {
                confirmPassword: conformpassword,
                newPassword: newpassword,
                email: email
            };
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}adminpart/adminresetpassword`, body);
                navigate('/');
                // navigate('/thankyou')
                enqueueSnackbar('Password reset successfully', { variant: 'success' });
            } catch (err) {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
                console.error('Error:', err);
            }
        }
    };
    return (
        <Box className='login'>
        <Grid container spacing={0}>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: 'linear-gradient(to right, #366253, #B4522D, #8E211D, #131313)' }}> */}
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: '#000'}}>
                <Box className='login_img'>
                    <Box className='logintext_box'>
                        <h1 className='heading'>Empower Your Cultural Network!</h1>
                        <Typography component='p' variant='body1' className='subheading'>
                        Manage talents, models, and studios seamlessly. Your hub for streamlined operations and creative control!
                        </Typography>
                    </Box>
                </Box>
            </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className="Login_content">
                        <Typography component="h4" variant="h4" className="mb-10">
                            Reset Password
                        </Typography>
                        <Box className="form">
                            <FormControl variant="standard">
                                {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
                                <Typography component="div" className="label">
                                <span style={{ fontWeight: 'bold' }}>Password</span>
                                    <Typography component='span' className='star'>*</Typography>
                                </Typography>
                                <TextField
                                    id="outlined-adornment-password"
                                    variant="outlined"
                                    size="small"
                                    className="textfield"
                                    placeholder="Password"
                                    value={newpassword}
                                    onChange={(e) => { setNewPassword(e.target.value); setPassError(''); setError(''); }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className="start_icon" /></InputAdornment>,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleToggleNewPassword}>
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={showNewPassword ? 'text' : 'password'}
                                />
                                {passerror && <p className="error-message" style={{ color: 'red' }}>{passerror}</p>}
                            </FormControl>
                            <FormControl variant="standard">
                            <Typography component="div" className="label"> <span style={{ fontWeight: 'bold' }}>Confirm Password</span> <Typography component='span' className='star'>*</Typography> </Typography>
                                <TextField
                                    id="outlined-adornment-confirm-password"
                                    variant="outlined"
                                    size="small"
                                    className="textfield"
                                    placeholder="Confirm Password"
                                    value={conformpassword}
                                    onChange={(e) => { setConformPassword(e.target.value); setConpassError(''); setError(''); }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className="start_icon" /></InputAdornment>,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleToggleConfirmPassword}>
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                />
                                {conpasserror && <p className="error-message" style={{ color: 'red' }}>{conpasserror}</p>}
                            </FormControl>
                        </Box>
                        <Button className="loginbtn" onClick={handleSubmit}>
                            Reset Password
                        </Button>
                    </Box>
                    </Grid>
                {/* </Grid> */}
            </Grid>
        </Box>
    );
}
