import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Input, Tabs, Tab } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../components/drawer'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'
import VideocamIcon from '@mui/icons-material/Videocam';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { OutlinedInput } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ListItemText } from '@mui/material';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CompareIcon from '@mui/icons-material/Compare';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MmsIcon from '@mui/icons-material/Mms';
import Check from '@mui/icons-material/Check';
// for service page 
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import CancelIcon from '@mui/icons-material/Cancel';
import { usePlacesWidget } from "react-google-autocomplete";
import AutoComplete from './autocomplete'
// -----------------------------------------------------------------------------------------------------------
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#3554D1',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#3554D1',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#3554D1',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#3554D1',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#3554D1',
        // backgroundColor:
        //     theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#545454',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#3554D1',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <DashboardIcon />,
        2: <MmsIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


export default function AddTalent() {

    let admindetails = JSON.parse(localStorage.getItem("userData"))
    let navigate = useNavigate()
    let location = useLocation()
    console.log(location, 'locationlocation in model');
    const [personName, setPersonName] = React.useState(location.state.Category_id)
    const [value, setValue] = React.useState(dayjs(location.state.Date));

    const { enqueueSnackbar } = useSnackbar()
    const [imageAddError, setImageAddError] = React.useState('')
    const [imageproError, setImageproError] = React.useState('')

    const [state1, setState1] = React.useState([...[], location.state.converimage ? location.state.converimage : []])
    const [state2, setState2] = React.useState([...[], location.state.converimage ? location.state.converimage : []])
    const [hight, setHight] = React.useState(location.state.modelInfo ? location.state.modelInfo.Height : "")

    const [gender, setGender] = React.useState(location.state.modelInfo ? location.state.modelInfo.gender : "")

    const [bodyType, setBodyType] = React.useState(location.state.modelInfo ? location.state.modelInfo.body_type : "")
    const [color, setColor] = React.useState(location.state.modelInfo ? location.state.modelInfo.skin_color : "")
    const [weight, setWeight] = React.useState(location.state.modelInfo ? location.state.modelInfo.weight : "")
    const [eyes, setEyes] = React.useState(location.state.modelInfo ? location.state.modelInfo.eyes : "")
    const [hair, sethair] = React.useState(location.state.modelInfo ? location.state.modelInfo.hair : "")
    const [dress, setDress] = React.useState(location.state.modelInfo ? location.state.modelInfo.dress : "")
    const [bust, setBust] = React.useState(location.state.modelInfo ? location.state.modelInfo.bust : "")
    const [waist, setWaist] = React.useState(location.state.modelInfo ? location.state.modelInfo.Waist : "")
    const [hips, setHips] = React.useState(location.state.modelInfo ? location.state.modelInfo.Hips : "")
    const [shoe, setShoe] = React.useState(location.state.modelInfo ? location.state.modelInfo.shoe : '')
    const [age, setAge] = React.useState(location.state.modelInfo ? location.state.modelInfo.age : '')
    const [profImg1, setProfImg1] = React.useState([...[], location.state.profile ? location.state.profile : []])
    const [profImg2, setProfImg2] = React.useState([...[], location.state.profile ? location.state.profile : []])
    const [buttonview, setButtonview] = React.useState(false)
    const [buttonview12, setButtonview12] = React.useState(false)
    const [buttonview1, setButtonview1] = React.useState(true)
    const [categoryDetails, setCategoryDetails] = React.useState(location.state.category_id ? location.state.category_id : '')
    const [serviceDetailes, setServiceDetailes] = React.useState('')
    // const [serviceValue, setServiceValue] = React.useState('')
    const [modelname, setModelName] = React.useState(location.state.name ? location.state.name : '')
    const [lastname,setLastname] = React.useState(location.state.lastname ? location.state.lastname : '')
    // const [username, setUserName] = React.useState(location.state.username?location.state.username:"")
    const [category, setCategory] = React.useState(location.state.Category_id ? location.state.Category_id._id : '')
    const [locations, setLocation] = React.useState(location.state.location)
    const [error, setError] = React.useState({ name: "", service: "" })
    const [viewpage, setViewpage] = React.useState(1)
    const [video1, setVideo1] = React.useState([])
    const [video2, setVideo2] = React.useState([])
    const [email, setEmail] = React.useState(location.state.email)
    const [addImg1, setAddImg1] = React.useState([])
    const [addImg2, setAddImg2] = React.useState([])
    const [richeditor, setRichtor] = React.useState(location.state.description)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    console.log(editorState, 'editorStateeditorState');
    const dataofeditor = `${richeditor}`
    const [isPrimary, setIsPrimary] = React.useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Basic Information', 'Additional Information'];
    const [aditnalValues, setaditnalValues] = React.useState(1)
    const [servicename, setServiceName] = React.useState('')
    const [duration, setDuration] = React.useState('perservice')
    const [date, setDate] = React.useState(dayjs(new Date()));
    const [modleId, setModelId] = React.useState('')
    const [profImg3, setProfImg3] = React.useState([])
    const [profImg4, setProfImg4] = React.useState([])
    const [personNameService, setPersonNameService] = React.useState([])
    const [price, setPrice] = React.useState()
    const [state3, setState3] = React.useState([])
    const [state4, setState4] = React.useState([])
    const [place, setPlace] = React.useState([])
    const [editorState1, setEditorState1] = React.useState(EditorState.createEmpty());
    const [city, setCity] = React.useState('')
    const [images1, setImages1] = React.useState({})
    const [videos1, setVideos1] = React.useState({})
    const [cityName, setCityName] = React.useState(location.state.location)
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')



    // -------------------service page---------------------------------------
    const [showAddModule, setShowAddModule] = React.useState(false)
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [serviceDetails, setServiceDetails] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');
    const [serviceImageproError, setServiceImageproError] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("all");
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [selectedFromDateTime, setSelectedFromDateTime] = React.useState(dayjs(new Date()));
    const [selectedToDateTime, setSelectedToDateTime] = React.useState(dayjs(new Date()));
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [allDetails, setAllDetails] = React.useState('');

    const [images, setImages] = React.useState({})
    const [videos, setVideos] = React.useState({})
    console.log(images, "imagesdata");
    console.log(videos, "imagesdata1111111");
    const open1 = Boolean(anchorE2);
    const OpenMenu = (event, data) => {
        setAllDetails(data)
        setAnchorE2(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorE2(null);
    };

    const handleEyeColoer = (event) => {
        setEyes(event.target.value);

    };
    const handleHairColor = (event) => {
        sethair(event.target.value);

    };
    // ----------------------------------------------------------------------

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    React.useEffect(() => {
        if (dataofeditor) {
            try {
                const contentState = stateFromHTML(dataofeditor);
                setEditorState(EditorState.createWithContent(contentState));
            } catch (error) {
                console.error("Error during HTML to ContentState conversion:", error);
            }
        }
    }, [dataofeditor]);

    React.useEffect(() => {
        getCategory()
    }, [])

    // React.useEffect(() => {
    //     getService()
    // }, [])

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });



    React.useEffect(() => {
        convertContentToHTML();
    }, [editorState]);

    const [convertedContent, setConvertedContent] = React.useState(null);
    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    }

    const convertContentToHTML = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentAsHTML = stateToHTML(currentContent);
        setConvertedContent(currentContentAsHTML)
    }


    React.useEffect(() => {
        convertContentToHTML1();
    }, [editorState1]);

    const [convertedContent1, setConvertedContent1] = React.useState(null);

    const handleEditorChange1 = (newEditorState) => {
        setEditorState1(newEditorState);
    }

    const convertContentToHTML1 = () => {
        const currentContent1 = editorState1.getCurrentContent();
        const currentContentAsHTML1 = stateToHTML(currentContent1);
        setConvertedContent1(currentContentAsHTML1)
    }

    // const getService = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `services/servicedropdown?isdropdown=true`)
    //         .then((res) => {
    //             console.log(res.data.dropdata, "get service");
    //             setServiceDetailes(res.data.dropdata)
    //         })
    //         .catch((error) => {
    //             console.log(error, "error in get service");
    //         })
    // }


    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `/admincategory/getallcategories?isdropdown=true`)
            .then((res) => {

                setCategoryDetails(res.data.data)
            })
            .catch((error) => {

            })
    }


    React.useEffect(() => {
        getPhotoes();
        getVideos();
    }, [])
    const getPhotoes = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getphotosbyid/${location.state._id}`)
            .then((res) => {
                setImages(res.data.allimage)
            })
            .catch((error) => {
            })
    }
    const getVideos = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getvideosbyid/${location.state._id}`)
            .then((res) => {

                setVideos(res.data.allvideos)

            })
            .catch((error) => {

            })
    }

    const profileImageChange = (e, index) => {
        setImageproError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg1(prevValue => prevValue.concat(fileArray))
        setProfImg2(prevValue => prevValue.concat(filepath))
    }

    const DeleteProf = (e, index) => {
        var filteredpreview = profImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg2.filter((val, i) => {
            return i !== index;
        });
        setProfImg1(filteredpreview);
        setProfImg2(filteredraw);
    }




    const fetchMedia = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}followandlike/gettsmmedia/${location.state._id}`);
            const media = response.data.media.media;
            const flatMedia = media.flat();

            const images11 = flatMedia.filter(item => item.mimetype && item.mimetype.startsWith('image'));
            const videos11 = flatMedia.filter(item => item.mimetype && item.mimetype.startsWith('video'));

            console.log(images11, "images and videos", videos11);
            setImages1(images11);
            setVideos1(videos11);
        } catch (error) {
            console.error('Error fetching media:', error);
        }
    };

    React.useEffect(() => {
        fetchMedia();
    }, []);

    const handleAddMedia = async (e, type) => {
        const formData = new FormData();
        for (const file of e.target.files) {
            formData.append('media', file);
        }
        formData.append('user_id', location.state._id);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}followandlike/addtsmvideo`, formData);
            if (response.data.status === 1) {
                fetchMedia();
            }
        } catch (error) {
            console.error('Error adding media:', error);
        }
    };

    const handleRemoveMedia = async (mediaPath) => {
        console.log(mediaPath, 'mediaPathmediaPath');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}followandlike/deletetsmmedia`, {
                user_id: location.state._id,
                media_path: mediaPath
            });
            if (response.data.status === 1) {
                fetchMedia();
            } else {
                console.error('Error removing media:', response.data.message);
            }
        } catch (error) {
            console.error('Error removing media:', error);
        }
    };





    const multipleImgChange = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }


    //additionsl images

    const AdditionalImgChange = (e) => {
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setAddImg1(prevValue => prevValue.concat(fileArray))
        setAddImg2(prevValue => prevValue.concat(filepath))
    }


    const additionalDelete = (e, index) => {
        var filteredpreview = addImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = addImg2.filter((val, i) => {
            return i !== index;
        });
        setAddImg1(filteredpreview);
        setAddImg2(filteredraw);
    }



    const [fileTypes, setFileTypes] = React.useState([]);
    React.useEffect(() => {
        const fetchFileTypes = async () => {
            const types = await Promise.all(state1.map(async fileUrl => {
                const type = await getFileType(fileUrl);
                return type;
            }));
            setFileTypes(types);
        };

        fetchFileTypes();
    }, [state1]);

    const getFileType = async (fileUrl) => {
        try {
            const response = await axios.get(fileUrl, { responseType: 'blob' });
            console.log(response.data, "vresponseresponseresponse");
            return response.data.type;
        } catch (error) {
            return 'video/mp4';
        }
    }
    // service Aditional files
    const serviceAditionalFiles = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setState3(prevValue => prevValue.concat(fileArray))
        setState4(prevValue => prevValue.concat(filepath))
    }

    const indexDeleteService = (e, index) => {
        var filteredpreview = state3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state4.filter((val, i) => {
            return i !== index;
        });
        setState3(filteredpreview);
        setState4(filteredraw);
    }



    const Validation = () => {
        if (!modelname && profImg2?.length == 0 && personName?.length == 0) {
            setError({ name: "This field is required.", service: "This field is required." })
            return false
        }
        if (!modelname.trim()) {
            setError({ name: "This field is required." })
            return false
        }
        // if (!username) {
        //     setError({ username: "This field is required." })
        //     return false
        // }
        if (profImg2?.length == 0) {
            setImageproError("This field is required.")
            return false
        }
        // if (personName?.length == 0) {
        //     setError({ service: "This field is required." })
        //     return false
        // }
        return true
    }
    const ValidationService = () => {
        if (!servicename && profImg2?.length == 0 && personNameService?.length == 0) {
            setError({ name: "This field is required.", Category: "This field is required." })
            return false
        }
        if (!servicename) {
            setError({ name: "This field is required." })
            return false
        }

        if (profImg2?.length == 0) {
            setImageproError("This field is required.")
            return false
        }
        if (personNameService?.length == 0) {
            setError({ Category: "This field is required." })
            return false
        }
        return true
    }

    const headerformdata = {
        'Content-Type': 'multipart/form-data',
    }


    const handleMultipleSelect = (event, item) => {
        setError({ service: '' })
        const { target: { value }, } = event;
        const idCounts = value.reduce((acc, item) => {
            acc[item._id] = (acc[item._id] || 0) + 1;
            return acc;
        }, {});

        const filteredData = value.filter(item => idCounts[item._id] === 1);
        setPersonName(filteredData);
    }
    const UpdateService = async () => {
        let valid = Validation()
        if (valid) {
            // let categoryid = personName.map(async (data) => data._id)
            setButtonview(true)
            const formData = new FormData();
            formData.append("name", modelname)
            formData.append('lastname',lastname)
            // formData.append("Category_id[]", JSON.stringify(categoryid))
            formData.append("location", cityName)
            formData.append("Date", value)
            formData.append("email", email)
            // formData.append("username", username)
            formData.append("description", convertedContent)
            formData.append("role", 'Model')
            formData.append("dress", dress)
            formData.append("bust", bust)
            formData.append("Waist", waist)
            formData.append("Hips", hips)
            formData.append("shoe", shoe)
            formData.append("hair", hair)
            formData.append("eyes", eyes)
            formData.append('Age', age)
            formData.append('Height', hight)
            formData.append('gender',gender)
            formData.append('skin_color',color)
            formData.append('body_type',bodyType)

            { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
            { state2?.length > 0 ? state2.forEach((item) => { formData.append("converimage", item) }) : formData.append("converimage", '') }
            console.log([...formData], "4444");
            await axios.put(process.env.REACT_APP_BASE_URL + `talents/updatetalentsandusers/${location.state._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    let mId = res.data.talents._id
                    setModelId(mId)
                    setButtonview(false)
                    navigate('/models')
                    enqueueSnackbar("Model updated successfully", { variant: 'success' })
                })
                .catch((error) => {
                    setButtonview(false)
                    console.log(error, "eeee in modelsss");
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

                })
        }

    }


    const SubmitContinue = async () => {
        let valid = Validation()
        if (valid) {
            setButtonview1(false)
            setViewpage(2)
            let categoryid = personName.map((data) => data._id)
            // setButtonview(true)
            setButtonview12(true)
            const formData = new FormData();
            formData.append("name", modelname)
            formData.append('lastname', lastname)
            formData.append("Category_id", categoryid)
            formData.append("location", cityName)
            formData.append("Date", value)
            formData.append("email", email)
            // formData.append("username", username)
            formData.append("description", convertedContent)
            formData.append("role", 'Model')
            formData.append("dress", dress)
            formData.append("bust", bust)
            formData.append("Waist", waist)
            formData.append("Hips", hips)
            formData.append("shoe", shoe)
            formData.append("hair", hair)
            formData.append("eyes", eyes)
            formData.append('Age', age)
            formData.append('Height', hight)
            formData.append('gender',gender)
            formData.append('skin_color',color)
            formData.append('body_type',bodyType)
            { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
            { state2?.length > 0 ? state2.forEach((item) => { formData.append("converimage", item) }) : formData.append("converimage", '') }
            // console.log([...formData], "4444");
            await axios.put(process.env.REACT_APP_BASE_URL + `talents/updatetalentsandusers/${location.state._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "then resp[onceee");
                    let mId = res.data.talents._id
                    setModelId(mId)
                    setButtonview1(false)
                    setViewpage(2)
                    setButtonview12(true)
                    enqueueSnackbar("Model updated successfully", { variant: 'success' })
                })
                .catch((error) => {

                    setButtonview1(false)
                    setButtonview12(false)
                    console.log(error, "modleerror");
                    // enqueueSnackbar(error.message == 'Network Error' ? 'Network Error': "Something went wrong.", { variant: 'error' })

                })

        }
    }
    const SubmitBack = () => {
        setViewpage(1)
    }

    // for  add sercvicee
    const profileImageChangeService = (e, index) => {
        setImageproError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg3(prevValue => prevValue.concat(fileArray))
        setProfImg4(prevValue => prevValue.concat(filepath))
    }

    const DeleteProfService = (e, index) => {
        var filteredpreview = profImg3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg4.filter((val, i) => {
            return i !== index;
        });
        setProfImg3(filteredpreview);
        setProfImg4(filteredraw);
    }

    const handleDeletVideo = async (e, id) => {
        axios.put(process.env.REACT_APP_BASE_URL + `followandlike/delettsmvideo/${id}`)
            .then((res) => {
                console.log(res.data);
                enqueueSnackbar("Deleted successfully", { variant: 'success' })
                getVideos();
            })
            .catch((err) => {
                console.log(err);

            })
    }


    const AdditionalVideoChange = (e, index) => {

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setVideo1(prevValue => prevValue.concat(fileArray))
        setVideo2(prevValue => prevValue.concat(filepath))
    }

    const indexVideoDelete = (e, index) => {
        var filteredpreview = video1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = video2.filter((val, i) => {
            return i !== index;
        });
        setVideo1(filteredpreview);
        setVideo2(filteredraw);
    }


    const handleMultipleServiceSelect = (event, item) => {
        setError({ service: '' })
        const { target: { value }, } = event;
        const idCounts = value.reduce((acc, item) => {
            acc[item._id] = (acc[item._id] || 0) + 1;
            return acc;
        }, {});

        const filteredData = value.filter(item => idCounts[item._id] === 1);
        setPersonNameService(filteredData);
    }


    const [expandedImageIndex, setExpandedImageIndex] = React.useState(-1); // State to track expanded image index

    const toggleImageExpand = (index) => {
        if (index === expandedImageIndex) {
            setExpandedImageIndex(-1); // Collapse if same image clicked again
        } else {
            setExpandedImageIndex(index); // Expand clicked image
        }
    };
    const renderImages = () => {
        if (addImg1.length === 0) {
            return (
                <Button className="multiple_upload_btn" variant="contained" component="label">
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                    </Box>
                    <input accept="image/png, image/jpg, image/jpeg"
                        onChange={(e) => { AdditionalImgChange(e); e.target.value = null; }} hidden multiple type="file" />
                </Button>
            );
        } else if (expandedImageIndex !== -1) {
            // Render expanded view with all images in a row
            return (
                <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                    {addImg1.map((image, index) => (
                        <Grid item key={index} xs={6} sm={4} md={4} lg={4}>
                            <Box className="uploaded_img" sx={{ position: 'relative' }}>
                                <Button className="close_icon" onClick={(e) => additionalDelete(e, index)} style={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
                                    <CloseIcon />
                                </Button>
                                {/* <img alt='' height={155} width={195} /> */}

                                <img src={image.slice(0, 4) === "blob" ? image : process.env.REACT_APP_BASE_URL + `${image}`} alt="" height={155} width={195} style={{ display: 'block', cursor: 'pointer' }} onClick={() => toggleImageExpand(index)} />
                                {addImg1.length > 1 && (
                                    <FilterNoneIcon className="filter_none_icon" style={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }} />
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            );
        } else {
            // Render only the first image in a single view
            return (
                <Grid item xs={12}>
                    <Box className="uploaded_img" sx={{ position: 'relative' }}>
                        <Button className="close_icon" onClick={(e) => additionalDelete(e, 0)} style={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
                            <CloseIcon />
                        </Button>
                        <img src={addImg1.slice(0, 4) === "blob" ? addImg1[0] : process.env.REACT_APP_BASE_URL + `${addImg1[0]}`} alt="" height={155} width={195} style={{ display: 'block', cursor: 'pointer' }} onClick={() => toggleImageExpand(0)} />
                        {addImg1.length > 1 && (
                            <FilterNoneIcon className="filter_none_icon" style={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }} />
                        )}
                    </Box>
                </Grid>
            );
        }
    };

    const Forservice = async () => {
        let abc = ValidationService()
        if (abc) {
            ServiceAdd()
        }
    }
    const ServiceAdd = async () => {
        let serviceCategory_id = personNameService.map((data) => data._id)

        setButtonview1(true)
        const formDataservice = new FormData();
        formDataservice.append("servicename", servicename)
        formDataservice.append("pricing", price)
        formDataservice.append("isService", true)
        formDataservice.append("Category_id", serviceCategory_id)
        formDataservice.append("admin_id", admindetails._id)
        formDataservice.append("duration", duration)
        formDataservice.append("location", city)
        formDataservice.append("Date", date)
        formDataservice.append("Description", convertedContent1)
        formDataservice.append("model_id", modleId)
        { profImg4?.length > 0 ? profImg4.forEach((item) => { formDataservice.append("profileimage", item) }) : formDataservice.append("profileimage", '') }
        { state4?.length > 0 ? state4.forEach((item) => { formDataservice.append("additionalimage", item) }) : formDataservice.append("additionalimage", '') }

        await axios.post(process.env.REACT_APP_BASE_URL + `services/addservice`, formDataservice, { headers: headerformdata })
            .then((res) => {
                console.log(res, "res");
                setShowAddModule(false)
                setButtonview1(false)
                // enqueueSnackbar("Added Successfully", { variant: 'success' })
            })
            .catch((error) => {
                setButtonview1(false)
                // setButtonview(false)
                console.log(error, "eeee in Service");
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

            })
    }


    const handleUplode = () => {
        if (addImg2.length > 0) ImageUpDateMethod();
        if (video2.length > 0) VideoUpdateMethod();
        navigate('/models')
        enqueueSnackbar("Added Successfully", { variant: 'success' })
    }
    const ImageUpDateMethod = () => {
        return new Promise((resolve, reject) => {
            const formDataservice = new FormData();
            formDataservice.append("createdBy", location.state._id)
            { addImg2?.length > 0 ? addImg2.forEach((item) => { formDataservice.append("images", item) }) : formDataservice.append("images", '') }
            axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmpost', formDataservice, { headers: headerformdata })
                .then(res => {
                    console.log(res, "photoes responce");
                    // enqueueSnackbar("Added Successfully", { variant: 'success' })
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err, "errrr");
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                    reject(err)
                })
        })
    }

    const VideoUpdateMethod = () => {
        return new Promise((resolve, reject) => {
            const formDataservice = new FormData();
            formDataservice.append("createdBy", location.state._id)
            { video2?.length > 0 ? video2.forEach((item) => { formDataservice.append("videos", item) }) : formDataservice.append("videos", '') }
            axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmvideo', formDataservice, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "res");
                    // enqueueSnackbar("Added Successfully", { variant: 'success' })
                    resolve(res.data)


                })
                .catch((error) => {
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                    reject(error)

                })
        })
    }



    const ServiceUpdate = async () => {
        // let serviceCategory_id = personNameService.map((data) => data._id)

        setButtonview1(true)
        let categoryid = personName.map((data) => data._id)
        setButtonview(true)
        const formData = new FormData();
        formData.append("pricing", price)
        formData.append("isService", true)
        formData.append("Category_id", categoryid)
        formData.append("admin_id", admindetails._id)
        formData.append("duration", duration)
        formData.append("location", location)
        formData.append("Date", value)
        formData.append("Description", convertedContent)
        { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profileimage", item) }) : formData.append("profileimage", '') }
        { state2?.length > 0 ? state2.forEach((item) => { formData.append("additionalimage", item) }) : formData.append("additionalimage", '') }

        await axios.put(process.env.REACT_APP_BASE_URL + `services/updateservice/${location.state._id}`, formData, { headers: headerformdata })
            .then((res) => {
                console.log(res, "res");
                setButtonview(false)
                navigate('/service')
                enqueueSnackbar("Service updated Successfully", { variant: 'success' })
            })
            .catch((error) => {
                setButtonview(false)
                console.log(error, "eeee");
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

            })
    }
    const handleChangeDetails = (e, val) => {
        console.log(val, "vvv");
        setaditnalValues(val)

    }
    const TabChenge = () => {
        let NextValue = aditnalValues + 1
        setaditnalValues(NextValue)

    }

    const previesTab = () => {
        let preValue = aditnalValues - 1
        setaditnalValues(preValue)

    }

    const handleMenuItemSelect = (event) => {
        setColor(event.target.value);

    };
    const handleBodyTypeselect = (event) => {
        setBodyType(event.target.value);

    };

    const handlegenders = (event) => {
        setGender(event.target.value);

    };

    

    // service  page ---------------------------------------------------------------------------------------

    const handleModleOpen = () => {
        setShowAddModule(true)
    }
    const handleServiceModuleClose = () => {
        setShowAddModule(false)
    }

    React.useEffect(() => {
        getServiceDetails()
    }, [])

    const getServiceDetails = async () => {
        console.log(location.state._id, " talent id");
        await axios.get(process.env.REACT_APP_BASE_URL + `services/getservicedetailsbasedtalent/${location.state._id}`)
            .then((res) => {
                console.log(res.data, 'service detailes dataaaaa');
                setServiceDetails(res.data.mydata)
                setTotal(res.data.totalcount)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }


    const EditDetails = () => {
        navigate('/editservice', { state: allDetails })
    }


    const DeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `services/deleteservice/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Deleted successfully", { variant: 'success' })
                getServiceDetails()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                // handleClose()
            })
    }




    const handleFromDateTimeChange = (newValue) => {

        setSelectedFromDateTime(newValue);
        setError(prevError => ({ ...prevError, fromTime: '' }))
    };
    const handleToDateTimeChange = (newValue) => {
        setSelectedToDateTime(newValue);
        setError(prevError => ({ ...prevError, toTime: '' }))
    };

    const Back = () => {
        navigate('/models')
    }
    //--------------------------------------------------------------------------------------------------
    return (
        <Box className='Addcompany'>
            <MiniDrawer type={7} />
            <Box component="section" className="contentWraper">

                <Box className=' whiteContainer'>
                    <Box className='content p-20'>
                        <Grid container rowSpacing={5} columnSpacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Edit Model</Typography>
                                <Stepper alternativeLabel activeStep={viewpage} connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                {viewpage == 1 ?
                                    <>
                                        <Grid container rowSpacing={2} columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>First Name <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={modelname}
                                                        onChange={(e) => { setModelName(e.target.value); setError({ name: "" }) }}
                                                        placeholder='Title'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}

                                            </Grid>
                                            
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Last Name </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={lastname}
                                                        onChange={(e) => { setLastname(e.target.value)}}
                                                        placeholder='Last Name'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Email <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        disabled
                                                        className='textfield'
                                                        value={email}
                                                        onChange={(e) => { setEmail(e.target.value) }}
                                                        placeholder='Email'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>






                                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Category <Typography component='span' className='star'>*</Typography></Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={personName}
                                                        multiple
                                                        onChange={handleMultipleSelect}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (personName.length === 0) {
                                                                return "No Category";
                                                            } else
                                                                if (personName.length === 1) {
                                                                    return personName[0].category_name;
                                                                } else {
                                                                    return `${personName.length} selected category`;
                                                                }
                                                        }}
                                                        MenuProps={MenuProps}
                                                        variant='outlined'
                                                        className='select'
                                                    >
                                                        {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
                                                            <MenuItem key={name._id} value={name} >
                                                                <Checkbox checked={personName.some(data => data._id == name._id)} />
                                                                <ListItemText primary={name.category_name} />
                                                            </MenuItem>
                                                        )) : <MenuItem>No user found</MenuItem>}
                                                    </Select>
                                                </FormControl>
                                                {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>}

                                            </Grid> */}

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Location </Typography>
                                                    <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
                                                    {/* <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        placeholder='Location'
                                                        value={locations}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    /> */}
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Model Height </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={hight}
                                                        onChange={(e) => { setHight(e.target.value); }}
                                                        placeholder='Model Height'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Dress  </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={dress}
                                                        onChange={(e) => { setDress(e.target.value); }}
                                                        placeholder='Dress'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Shoe </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={shoe}
                                                        onChange={(e) => { setShoe(e.target.value); }}
                                                        placeholder='Model Shoe'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'> Waist</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={waist}
                                                        onChange={(e) => { setWaist(e.target.value); }}
                                                        placeholder='  Waist'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Hip</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={hips}
                                                        onChange={(e) => { setHips(e.target.value); }}
                                                        placeholder='hip'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Age</Typography>
                                                    <TextField
                                                        id="age-input"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={age}
                                                        onChange={(e) => { setAge(e.target.value); }}
                                                        placeholder='age'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Skin Color </Typography>
                                                    <Select
                                                        value={color}
                                                        onChange={handleMenuItemSelect}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select skin color</MenuItem>
                                                        <MenuItem value='fair'> Fair</MenuItem>
                                                        <MenuItem value='light'>Light</MenuItem>
                                                        <MenuItem value='blond'>Blond</MenuItem>
                                                        <MenuItem value='brown'>Brown</MenuItem>
                                                        <MenuItem value='black'>Black</MenuItem>
                                                        <MenuItem value='medium_black'>Medium black</MenuItem>
                                                        <MenuItem value='medium'>Medium</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'> Body Type </Typography>
                                                    <Select
                                                        value={bodyType}
                                                        onChange={handleBodyTypeselect}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select Body Type</MenuItem>
                                                        <MenuItem value='endomorph'> Endomorph</MenuItem>
                                                        <MenuItem value='mesomorph'>Mesomorph</MenuItem>
                                                        <MenuItem value='ectomorph'>Ectomorph</MenuItem>
                                                        <MenuItem value='Athletic'>Athletic</MenuItem>
                                                        <MenuItem value='Thin'>Thin</MenuItem>
                                                        <MenuItem value='Chubby'>Chubby</MenuItem>
                                                        <MenuItem value='Gymmastic'>Gymmastic</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'> Hair Color</Typography>
                                                    <Select
                                                        value={hair}
                                                        onChange={handleHairColor}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select Hair Color</MenuItem>
                                                        <MenuItem value='black'>Black</MenuItem>
                                                        <MenuItem value='brown'>Brown</MenuItem>
                                                        <MenuItem value='blond'>Blond</MenuItem>
                                                        <MenuItem value='white'>White</MenuItem>
                                                        <MenuItem value='gray'>Gray</MenuItem>
                                                        <MenuItem value='red'>Red</MenuItem>
                                                        <MenuItem value='green'>Green</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'> Eye Type</Typography>
                                                    <Select
                                                        value={eyes}
                                                        onChange={handleEyeColoer}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select Hair Color</MenuItem>
                                                        <MenuItem value='amber'>Amber</MenuItem>
                                                        <MenuItem value='brown'>Brown</MenuItem>
                                                        <MenuItem value='hazel'>hazel</MenuItem>
                                                        <MenuItem value='red'>red</MenuItem>
                                                        <MenuItem value='gray'>Gray</MenuItem>
                                                        <MenuItem value='blue'>Blue</MenuItem>
                                                        <MenuItem value='green'>Green</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'> Gender Type</Typography>
                                                    <Select
                                                        value={gender}
                                                        onChange={handlegenders}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select Gender Type</MenuItem>
                                                        <MenuItem value='male'> Male</MenuItem>
                                                        <MenuItem value='female'>Female</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            

                                            <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                                <Typography component='div' className='label'>Personal info </Typography>
                                                <div className="editorclass">
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorChange}
                                                        toolbarClassName="toolbar"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorcontent"

                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline'],
                                                            },
                                                            blockType: {
                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered'],
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right', 'justify'],
                                                            },
                                                            link: {
                                                                options: ['link'],
                                                            },
                                                            emoji: {
                                                                options: [
                                                                    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                                    '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                                ],
                                                            },
                                                            image: {
                                                                uploadEnabled: true,
                                                                previewImage: true,
                                                                inputAccept: 'image/*',
                                                                alt: { present: false, mandatory: false },
                                                            },
                                                        }}

                                                    />


                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}  >

                                                <Typography component='div' className='label'>Profile Image</Typography>

                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {profImg1.length < 1 &&
                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChange} hidden type="file" />
                                                        </Button>
                                                    }
                                                </Box>
                                            </Grid>

                                            {imageproError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageproError}</p>}
                                            <Grid item xs={12} sm={12} md={12}  >
                                                {profImg1.length > 0 &&
                                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                        {profImg1.length > 0 ? profImg1.map((x, i) => (
                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                <Box className='uploaded_img' >
                                                                    <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>
                                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                                    <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>

                                                                </Box>
                                                            </Grid>
                                                        )) : ""}

                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    // 22222222222
                                    <>

                                        <Box style={{ display: 'flex ', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box className='flex-box mb-10 segmentControl' style={{ justifyContent: 'start' }}>
                                                <Tabs
                                                    value={aditnalValues}
                                                    onChange={handleChangeDetails}
                                                    textColor="secondary"
                                                    indicatorColor="secondary"
                                                    aria-label="secondary tabs example"
                                                    className='segmentControl'
                                                    variant="fullWidth" // Add this line to make tabs full width
                                                    sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                                                >
                                                    <Tab className='subsegment' sx={{ textTransform: "none" }} value={1} label="Images" />
                                                    <Tab className='subsegment' sx={{ textTransform: "none" }} value={2} label="Video" />
                                                </Tabs>
                                            </Box>
                                        </Box>


                                        <Grid item xs={12} sm={12} md={12}>
                                            {aditnalValues === 1 && (
                                                <>
                                                    {images1.length > 0 ? (
                                                        // images1.map((imageData, i) => (
                                                        images1.map((tdata, j) => (
                                                            <Swiper key={j} modules={[Navigation, Pagination]} className="mySwiper" slidesPerView={1} pagination={{ clickable: true }}>
                                                                <SwiperSlide key={j}>
                                                                    <CancelIcon
                                                                        onClick={() => handleRemoveMedia(tdata.path)} CancelIconCancelIcon
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 5,
                                                                            left: 5,
                                                                            cursor: 'pointer',
                                                                            color: 'white',
                                                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                            borderRadius: '50%',
                                                                            padding: '0.25rem',
                                                                            zIndex: 1
                                                                        }}
                                                                    />
                                                                    <img width={'100%'} height={200} src={`${process.env.REACT_APP_BASE_URL}${tdata.path}`} alt={`Image ${j + 1}`} />
                                                                    {/* <Button onClick={() => handleRemoveMedia(tdata.path)}>Remove</Button> */}
                                                                </SwiperSlide>
                                                            </Swiper>
                                                        ))
                                                        // ))
                                                    ) : (
                                                        <img width={'100%'} height={250} src='/home/infomaze/Downloads/No_Image_Available.jpg' alt='No Image Available' />
                                                    )}
                                                    <Button className="multiple_upload_btn" variant="contained" component="label">
                                                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                        <input accept={"image/png, image/jpg,image/jpeg"} onChange={(e) => handleAddMedia(e, 'image')} hidden multiple type="file" />
                                                    </Button>
                                                </>
                                            )}






                                            {aditnalValues === 2 && (
                                                <>
                                                    {videos1.length > 0 ? (
                                                        // videos1.map((videoObj, index) => (
                                                            videos1.map((video, j) => (

                                                                <div key={j} style={{ position: 'relative', marginBottom: '1rem' }}>
                                                                    <CancelIcon
                                                                        onClick={() => handleRemoveMedia(video.path)}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 5,
                                                                            left: 5,
                                                                            cursor: 'pointer',
                                                                            color: 'white',
                                                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                            borderRadius: '50%',
                                                                            padding: '0.25rem',
                                                                            zIndex: 1
                                                                        }}
                                                                    />

                                                                    <video
                                                                        controls
                                                                        width={'50%'}
                                                                        height={150}
                                                                        src={`${process.env.REACT_APP_BASE_URL}${video.path}`}
                                                                        style={{ display: 'block', position: 'relative' }}
                                                                    />
                                                                </div>
                                                            ))
                                                        // ))
                                                    ) : (
                                                        <p>No videos available</p>
                                                    )}
                                                    <Button className="multiple_upload_btn" variant="contained" component="label">
                                                        <VideocamIcon className="fz-18 mr-5" /> Upload
                                                        <input accept={"video/mp4"} onChange={(e) => handleAddMedia(e, 'video')} hidden type="file" />
                                                    </Button>
                                                </>
                                            )}

                                            {aditnalValues === 3 &&
                                                <div> <Box style={{ marginTop: 10 }} className='heading-btns'>
                                                    <Button onClick={handleModleOpen} variant="contained" className='addbtn mr-10' >Add Service</Button> </Box>
                                                    <Dialog open={showAddModule} keepMounted
                                                        onClose={handleServiceModuleClose}
                                                        aria-describedby="alert-dialog-slide-description"
                                                        className='custom_modal'>
                                                        <DialogTitle>Add Service</DialogTitle>
                                                        <DialogContent dividers>
                                                            <Grid container rowSpacing={2} columnSpacing={2}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                                                        <TextField
                                                                            id="filled-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            className='textfield'
                                                                            value={servicename}
                                                                            onChange={(e) => { setServiceName(e.target.value); setError({ name: "" }) }}
                                                                            placeholder='Title'
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                    {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}

                                                                </Grid>



                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    <Typography component='div' className='label'>Primary Image <Typography component='span' className='star'> *</Typography></Typography>

                                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                                        {profImg3.length < 1 &&
                                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChangeService} hidden type="file" />
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>




                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    {profImg3.length > 0 &&
                                                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                                            {profImg3.length > 0 ? profImg3.map((x, i) => (
                                                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                                    <Box className='uploaded_img' >
                                                                                        <Button className='close_icon' onClick={(e) => DeleteProfService(e, i)}><Close /></Button>
                                                                                        <img src={x} alt='' height={155} width={195} />
                                                                                    </Box>
                                                                                </Grid>
                                                                            )) : ""}

                                                                        </Grid>
                                                                    }
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                                                </Grid>


                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <FormControl variant="standard" fullWidth size="small">
                                                                        <Typography component='div' className='label'>Category <Typography component='span' className='star'>*</Typography></Typography>
                                                                        <Select
                                                                            labelId="demo-select-small"
                                                                            id="demo-select-small"
                                                                            value={personNameService}
                                                                            multiple
                                                                            onChange={handleMultipleServiceSelect}
                                                                            input={<OutlinedInput />}
                                                                            renderValue={(selected) => {
                                                                                if (personNameService.length === 0) {
                                                                                    return "No Category";
                                                                                } else
                                                                                    if (personNameService.length === 1) {
                                                                                        return personNameService[0].category_name;
                                                                                    } else {
                                                                                        return `${personNameService.length} selected category`;
                                                                                    }
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            variant='outlined'
                                                                            className='select'
                                                                        >
                                                                            {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
                                                                                <MenuItem key={name._id} value={name} >
                                                                                    <Checkbox checked={personNameService.some(data => data._id == name._id)} />
                                                                                    <ListItemText primary={name.category_name} />
                                                                                </MenuItem>
                                                                            )) : <MenuItem>No user found</MenuItem>}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {/* {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>} */}

                                                                </Grid>

                                                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <Typography component='div' className='label'>Price </Typography>
                                                                        <TextField
                                                                            id="filled-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            className='textfield'
                                                                            placeholder='Price'
                                                                            value={price}
                                                                            onChange={(e) => setPrice(e.target.value)}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                                endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                                                    <FormControl variant="standard" fullWidth size="small">
                                                                        <Typography component='div' className='label'>Duration</Typography>
                                                                        <Select
                                                                            labelId="demo-select-small"
                                                                            id="demo-select-small"
                                                                            value={duration}
                                                                            onChange={(e) => setDuration(e.target.value)}
                                                                            variant='outlined'
                                                                            className='select'
                                                                        >
                                                                            <MenuItem value={'perhour'} className='menuitems'>per-hour</MenuItem>
                                                                            <MenuItem value={'perservice'} className='menuitems'>per-service</MenuItem>
                                                                            <MenuItem value={'perday'} className='menuitems'>per-day</MenuItem>


                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>



                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <Typography component='div' className='label'>Location </Typography>
                                                                        <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
                                                                        {/* <TextField
                                                                            id="filled-size-small"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            className='textfield'
                                                                            placeholder='Location'
                                                                            value={location}
                                                                            onChange={(e) => setLocation(e.target.value)}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                            }}
                                                                        /> */}
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography component='div' className='label'>Start Time <Typography component='span' className='star'>*</Typography></Typography>
                                                                    <FormControl variant="standard" fullWidth className="datepicker">
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']} >
                                                                                <DateTimePicker
                                                                                    onChange={handleFromDateTimeChange}
                                                                                    value={selectedFromDateTime && selectedFromDateTime}
                                                                                    minDate={dayjs()}
                                                                                    renderInput={(props) => <TextField {...props} />}
                                                                                    format="DD-MM-YYYY hh:mm a"
                                                                                    viewRenderers={{
                                                                                        hours: renderTimeViewClock,
                                                                                        minutes: renderTimeViewClock,
                                                                                        seconds: renderTimeViewClock,
                                                                                    }}
                                                                                    className="textfield"
                                                                                />
                                                                            </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {error.fromTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fromTime}</p>}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography component='div' className='label'>To Time<Typography component='span' className='star'>*</Typography></Typography>
                                                                    <FormControl variant="standard" fullWidth className="datepicker">
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                                                <DateTimePicker
                                                                                    size="small"
                                                                                    className="textfield"
                                                                                    onChange={handleToDateTimeChange}
                                                                                    value={selectedToDateTime && selectedToDateTime}
                                                                                    minDate={dayjs(selectedFromDateTime)}
                                                                                    renderInput={(props) => <TextField {...props} />}
                                                                                    format="DD-MM-YYYY hh:mm a"
                                                                                    viewRenderers={{
                                                                                        hours: renderTimeViewClock,
                                                                                        minutes: renderTimeViewClock,
                                                                                        seconds: renderTimeViewClock,
                                                                                    }}
                                                                                />
                                                                            </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {error.toTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.toTime}</p>}
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                                                    <Typography component='div' className='label'>Description </Typography>
                                                                    <div className="editorclass">
                                                                        <Editor
                                                                            editorState={editorState}
                                                                            onEditorStateChange={handleEditorChange}
                                                                            toolbarClassName="toolbar"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorcontent"

                                                                            toolbar={{
                                                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                                                inline: {
                                                                                    options: ['bold', 'italic', 'underline'],
                                                                                },
                                                                                blockType: {
                                                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                                                },
                                                                                list: {
                                                                                    options: ['unordered', 'ordered'],
                                                                                },
                                                                                textAlign: {
                                                                                    options: ['left', 'center', 'right', 'justify'],
                                                                                },
                                                                                link: {
                                                                                    options: ['link'],
                                                                                },
                                                                                emoji: {
                                                                                    options: [
                                                                                        '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                                                        '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                                                    ],
                                                                                },
                                                                                image: {
                                                                                    uploadEnabled: true,
                                                                                    previewImage: true,
                                                                                    inputAccept: 'image/*',
                                                                                    alt: { present: false, mandatory: false },
                                                                                },
                                                                            }}

                                                                        />


                                                                    </div>
                                                                </Grid>



                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    <Typography component='div' className='label'>Additional Files</Typography>

                                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                                        {state3.length < 1 &&

                                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                                                <input accept={"image/png, image/jpg,image/jpeg,video/mp4"} onChange={serviceAditionalFiles} hidden multiple type="file" />
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                                {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}

                                                                <Grid item xs={12} sm={12} md={12}  >
                                                                    {state3.length > 0 &&
                                                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                                            {state3.length > 0 ? state3.map((x, i) => (
                                                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                                    <Box className='uploaded_img' >
                                                                                        {fileTypes[i]?.startsWith('image/') && (
                                                                                            <>
                                                                                                <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt="Loaded content" height={155} width={195} />
                                                                                            </>
                                                                                        )}
                                                                                        {fileTypes[i]?.startsWith('video/') && (
                                                                                            <>
                                                                                                <video type={fileTypes[i]} controls src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt="Loaded content" height={155} width={195} />
                                                                                            </>
                                                                                        )}
                                                                                        <Button className='close_icon' onClick={(e) => indexDeleteService(e, i)}><Close /></Button>
                                                                                    </Box>
                                                                                </Grid>
                                                                            )) : ""}
                                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                                <label htmlFor="contained-button-file1" className="w-full">
                                                                                    <Box className='multiple_upload_btn' >
                                                                                        <Input
                                                                                            inputProps={{ accept: "image/png, image/jpg,image/jpeg,video/mp4" }}
                                                                                            onChange={serviceAditionalFiles}
                                                                                            style={{ display: "none", }}
                                                                                            id="contained-button-file1"
                                                                                            type="file"
                                                                                        />
                                                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                                                    </Box>
                                                                                </label>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                                                </Grid>
                                                                {buttonview1 == false ?
                                                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                                                        {aditnalValues > 2 && <Button onClick={Forservice} variant="contained" className='addbtn mr-10' >Submit</Button>}
                                                                    </Box>
                                                                    :
                                                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                                                        <Button variant="contained" className='addbtn mr-10' >Please Wait...</Button>
                                                                    </Box>}
                                                            </Grid>
                                                        </DialogContent>


                                                    </Dialog>


                                                    {/* <Box component="section" className="contentWraper"> */}


                                                    {/* <Box className='whiteContainer'>
                                                            <Box> */}
                                                    <TableContainer component={Paper} id='table'>
                                                        <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                                                            <TableHead className='DT-head'>
                                                                <TableRow className=''>
                                                                    <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px' }}>Image</TableCell>

                                                                    <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px' }}>Service name</TableCell>
                                                                    <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px' }}>Talent name</TableCell>
                                                                    <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px' }}>Price($)</TableCell>
                                                                    <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px' }}>Category</TableCell>

                                                                    <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 30, maxWidth: 30, padding: '6px 16px' }}>Status</TableCell>
                                                                    <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 10, maxWidth: 10, padding: '6px 16px' }}>Primary</TableCell>
                                                                </TableRow>
                                                            </TableHead>



                                                            <TableBody className="characters "  >
                                                                {serviceDetails && serviceDetails?.length > 0 ? serviceDetails.map((row, i) => (
                                                                    <>
                                                                        <TableRow className=''>
                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20, }}><img style={{ height: 50, width: 50, borderRadius: '50%' }} src={process.env.REACT_APP_BASE_URL + `${row.primaryimage}`} /></TableCell >
                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>{row && row.servicename}</TableCell >
                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 100 }}>{row && row.talent_id ? row.talent_id.name : "-"}  </TableCell>
                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>{row && row.pricing} </TableCell >
                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 30 }}> {row && row.Category_id?.length && row.Category_id.map((data) => data.category_name).join(', ')}</TableCell >

                                                                            <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                                                                                <FormControlLabel className='m-0' control={<IOSSwitch checked={row.isService} sx={{ m: 1 }} />} />
                                                                            </TableCell>

                                                                            <TableCell align="left" component="th" scope="row" padding="none" className='DT-row'  >
                                                                                <Checkbox checked={row.setService} />
                                                                            </TableCell>

                                                                        </TableRow>
                                                                    </>


                                                                )) : <TableRow className=''>
                                                                    <TableCell className='DT-row' align="center" colSpan={5}>
                                                                        No Data Found
                                                                    </TableCell >
                                                                </TableRow>}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>

                                            }
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>

                        <Box component="section" className="contentWrapper"> <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}> <Button variant="contained" className='addbtn' onClick={(e) => Back()} style={{color:'white', backgroundColor:"black"}}>Back</Button>

                            {viewpage == 1 ?
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    {buttonview == false ?
                                        <Box style={{ marginLeft: '10px' }} className='heading-btns'>
                                            <Button onClick={UpdateService} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Update</Button>
                                        </Box>
                                        :
                                        <Box style={{ marginLeft: '10px' }} className='heading-btns'>
                                            <Button variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
                                        </Box>

                                    }
                                    {buttonview12 == false ?
                                        <Box style={{ marginLeft: '10px' }} className='heading-btns'>
                                            <Button onClick={SubmitContinue} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Update and Continue</Button>
                                        </Box>
                                        :
                                        <Box style={{ marginLeft: '10px' }} className='heading-btns'>
                                            <Button variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
                                        </Box>

                                    }
                                </Box>
                                :

                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <Box style={{ marginLeft: '10px' }} className='heading-btns'>

                                        {aditnalValues > 1 && <Button onClick={previesTab} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Back</Button>}
                                        {aditnalValues < 2 && <Button onClick={TabChenge} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Next</Button>}
                                    </Box>


                                    {buttonview1 == false ?
                                        <Box style={{ marginLeft: '10px' }} className='heading-btns'>
                                            {aditnalValues > 1 && <Button onClick={handleUplode} variant="contained" className='addbtn mr-10' style={{color:'white', backgroundColor:"black"}}>Update</Button>}
                                        </Box>
                                        :
                                        <Box style={{ marginLeft: '10px' }} className='heading-btns'>
                                            <Button variant="contained" className='addbtn mr-10'  style={{color:'white', backgroundColor:"black"}}>Please Wait...</Button>
                                        </Box>}
                                </Box>
                            }
                        </Box>
                        </Box>
                    </Box>

                </Box>

            </Box>
        </Box>
    )
}