import React,{useState}from "react";
import { CardElement,useElements,useStripe}from"@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import axios from'axios';
import './checkout.css'

function Checkout(){
    let navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [cardDetails,setCardDetails]=useState({});
    const [newcard,setNewcard]=useState('')
    const [bookings, setBookings] = React.useState('')

    React.useEffect(()=>{
        fetchCardDetails()
    },[])

    
    const handleSubmit= async (cardDetails)=>{
        setNewcard(cardDetails)
        await fetchCardDetails();
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
            const validProperties = {
                number: cardDetails.card.card.last4 ? cardDetails.card.card.last4 :"",
                exp_month: cardDetails.card.card.exp_month ? cardDetails.card.card.exp_month : "",
                exp_year: cardDetails.card.card.exp_year ? cardDetails.card.card.exp_year :"",
                cvc: '',
            };
            cardElement.update(validProperties);
        }
    }

    const getBookings = async () => {
            await axios.get(process.env.REACT_APP_BASE_URL + `paymentsdata/getthebookings`)
            .then((response)=>{
                setBookings(response.data);
            }).catch((error) =>{
                console.log(error,"error in checkout")
            })

    };

    React.useEffect(()=>{
        getBookings()
    })

    const fetchCardDetails=async()=>{
        console.log('inside the get api');
        try{
            const response = await axios.get(process.env.REACT_APP_BASE_URL + 'paymentsdata/getcarddetails?user_id=66b5ba2b5cb55d378ef00ada');
            const data=response.data;
            setCardDetails(data);
        }catch(error){
            console.error(error);
            alert('Failed to fetch card details!');
        }
    };

    console.log(typeof cardDetails.card,'dddddddddddd')
    const pay= async () =>{
        try{
            const response = await fetch(process.env.REACT_APP_BASE_URL + 'paymentsdata/paytotalents',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                }
            });
            const data=await response.json();
            const clientSecret=data.clientSecret; 
            const result = await stripe.confirmCardPayment(clientSecret,{
                payment_method:{
                    card :elements.getElement(CardElement)
                }
            });
            if(result.error){
                alert(result.error.message);
            }else{
                if(result.paymentIntent.status==='succeeded'){
                    alert('Payment successful!');
                    navigate('/booking')
                }else{
                    alert('Payment failed!');
                }
            }
        }catch(error){
            console.error(error);
            alert('Payment failed!');
        }
    };

    console.log(newcard,'newcardnewcard');

    return(
        <div className="checkout">

            <p>Card Number:{cardDetails && cardDetails.card && cardDetails.card.card.last4}</p>
            <p>Expiry Date:{cardDetails && cardDetails.card && cardDetails.card.card.exp_month}/{cardDetails && cardDetails.card && cardDetails.card.card.exp_year}</p>

            <CardElement/>
            <button onClick={()=>handleSubmit(cardDetails)}>Fetch Card Details</button>
            <button onClick={pay}>Pay</button>
        </div>
    );
}

export default Checkout;