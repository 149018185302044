import './App.css';
import './assets/css/Custom.css';
import './assets/css/common.css';
import './assets/css/responsive.css';
import Routers from './routes';
import { SnackbarProvider } from 'notistack';
import Closebutton from "./components/closebutton"
import { register } from './serviceworker'
import { messaging, setupNotifications } from './firebase';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import firebase from 'firebase/compat/app';
import React from 'react'
import axios from 'axios';

function App() {
  React.useEffect(()=>{
    axios.get('http://192.168.1.79:90/api/getloginaccessdetails?hostname=ashwinip_405').then(res => {
    console.log(res.data,"console response")
    })
    .catch(err => {
      console.log(err,"error console")
    })  
  },[])
    onMessage(messaging, (payload) => {
        console.log(payload,'Foreground Message in app.js');
        const notificationTitle = payload.data.title;
        const notificationOptions = {
            body: payload.data.body,
            message: payload.data.message,
            icon: "/Noti.jpg",
        };
        if ('Notification' in window) {
        
            if (Notification.permission === 'granted') {
                new Notification(notificationTitle, notificationOptions);
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        new Notification(notificationTitle, notificationOptions);
                    }
                });
            }
        }
    });
    const firebaseConfig = {
        apiKey: "AIzaSyCZZtiypfAxh2fXDosLXK9Z92a_EJujX4s",
        authDomain: "chatapp-rn-79e4e.firebaseapp.com",
        databaseURL: "https://chatapp-rn-79e4e-default-rtdb.firebaseio.com",
        projectId: "chatapp-rn-79e4e",
        storageBucket: "chatapp-rn-79e4e.appspot.com",
        messagingSenderId: "512330630070",
        appId: "1:512330630070:web:7e12f53ca543f2142a7717",
        measurementId: "G-VHLEHZY7BJ"
    };
    firebase.initializeApp(firebaseConfig);
	return (
		<> 
		<SnackbarProvider maxSnack={3} anchorOrigin={{
		  vertical: 'top',
		  horizontal: 'right',}}  autoHideDuration={1000}  action={closeid => <Closebutton value={closeid}/>}>	  
			<Routers />
			</SnackbarProvider>		  
		  </>
	);
}
export default App;






