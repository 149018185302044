
import { Box, Button, Typography, TextField } from '@mui/material'
import React from 'react'
import { usePlacesWidget } from "react-google-autocomplete";

export default function AutoComplete({cityName, setCityName, lat, setLat,lng, setLng}) {
    
    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            console.log(place, 'placeplace');
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });

    return (
        <TextField
            id="filled-size-small"
            variant="outlined"
            size="small"
            name='specialization'
            className='textfield'
            placeholder='Address'
            inputRef={ref}
            defaultValue = {cityName}
        />
    );
}

