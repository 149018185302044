import React,{useState, useEffect} from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Modal = ({ isOpen, onClose, children }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    console.log(currentImageIndex,'currentImageIndexcurrentImageIndex');

    const handleNextImage = (images) => {
        // console.log(images,'images.lengthimages.lengthimages.length');
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Model Image</DialogTitle>
      <IconButton onClick={handleNextImage} size="small">
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
      <CloseIcon onClick={onClose} style={{marginLeft:'555px', marginTop:'-50px'}}>×</CloseIcon>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;