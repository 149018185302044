import * as React from 'react';
import { Box, Button, Divider, Grid, IconButton, TextField, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';

import axios from 'axios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function TimelineTab() {
    let location = useLocation()
    let comunityName = location.state
    const [Open, setOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [isActive2, setIsActive2] = React.useState(false);
    const [isActive3, setIsActive3] = React.useState(false);
    const [timeline, setTimeline] = React.useState([])
    const [community, setCommunity] = React.useState([])


    let navigate = useNavigate()

    React.useEffect(() => {
        getTimeline()
    }, [])

    const getTimeline = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `Post/getCommunityPost`)
            .then((res) => {
                setTimeline(res.data.posts);
            })
            .catch((error) => {
                console.log(error, "error in Timeline");
            })
       
    }

    const handleOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const like = () => {
        setIsActive(!isActive);
    };
    const comment = () => {
        setIsActive2(!isActive2);
    };
    const comment3 = () => {
        setIsActive3(!isActive3);
    };
    const handleNavigate = () => {
        navigate('/podcast-details');
    }

    return (
        <Box className='community_timeline'>
            <Grid container columnSpacing={3} rowSpacing={3}>
                {timeline && timeline.map((data) => (
                    
                    <Grid item xs={12} sm={12} md={8} lg={9}>
                        <Box>
                            <Box className='timeline_box'>
                               
                                    <Box className="d-flex mb-20 flex-wrap">
                                       
                                </Box>

                                <Divider />
                                <Box className='p-20'>
                                    <Box>
                                        <Button disableRipple className='likebtn btn text_black letter_sp mr-20' onClick={() => like(data._id)}>
                                            <i class="fa-solid fa-thumbs-up text_primary fz-18 mr-10"></i>
                                            {data.likes.length} Likes
                                        </Button>


                                        <Button disableRipple className='likebtn btn text_black letter_sp mr-20' onClick={() => comment(data._id)}>
                                            <i class="fa-regular fa-message text_primary fz-16 mr-10"></i>
                                            {data.comments.length} Comments
                                        </Button>

                                        <Box className={isActive3 ? 'comment-collapse' : 'comment-collapse comment-close'} >
                                            <Comment_section data={data} getTimeLine={getTimeline} />
                                        </Box>

                                    </Box>

                                </Box>
                            </Box>
                        </Box>
                        <Spacing lg="50" md="40" />
                        <Divider />
                        <Spacing lg="50" md="40" />
                    </Grid>
                ))}
            </Grid>

            <BootstrapDialog
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={Open}
                fullWidth
                maxWidth="xl"
                className='fullscreen_modal'
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box>
                    <iframe width="100%" height="600px" src="https://www.youtube.com/embed/v7AYKMP6rOE" frameborder="0" allowfullscreen></iframe>
                </Box>
            </BootstrapDialog>
        </Box>
    )
}